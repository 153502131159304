import { memo, useCallback, useEffect, useState, useRef, useContext } from "react"
import { usePrevious, useDeepCompareEffect } from "@fuse/hooks"
import { useSelector, useDispatch } from "react-redux"
import { selectTabs, updateUserTabs, replaceUserTabs, updateUserTabIndex, deleteUserTabs } from "app/store/fuse/tabsSlice"
import { selectNavigation } from "app/store/fuse/navigationSlice"
import { useNavigate, useLocation, matchRoutes } from "react-router-dom"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import useSilentSelector from "@fuse/hooks/useSilentSelector"
import AppContext from "app/AppContext"
import FuseUtils from "@fuse/utils"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import _ from "@lodash"

const debug = false
let rendering = -1

const TabsLayout5 = () => {
	console.log("<TabsLayout5>".padEnd(14) + "%cConstructor() -rendering:", "color: burlywood", ++rendering)
	const [hoveredTab, setHoveredTab] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { currentTabIndex, list } = useSelector(selectTabs)
	const navigation = useSilentSelector(selectNavigation)
	//const navigation = useSelector(selectNavigation)

	const previousCurrentTabIndex = usePrevious(currentTabIndex)
	const previousList = usePrevious(list)
	//const previousNavigation = usePrevious(navigation)

	//const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))
	//debug && console.log("<TabsLayout5>  -isMobile: ", isMobile) // FIXME gestire il bottone delete onHover

	const { pathname } = useLocation()
	//console.log("<TabsLayout5>  -pathname:", pathname)

	const appContext = useContext(AppContext)

	//if ((previousCurrentTabIndex === currentTabIndex || previousList === list) && previousNavigation !== navigation) return
	previousCurrentTabIndex !== currentTabIndex && console.debug("\t%ccurrentTabIndex has changed from", "color: grey", previousCurrentTabIndex, "to", currentTabIndex)
	!_.isEqual(previousList, list) && console.debug("\t%clist has changed from", "color: grey", previousList, "to", list)
	//!_.isEqual(previousNavigation, navigation) && console.debug("\t%cnavigation has changed from", "color: grey", previousNavigation, "to", navigation)

	useEffect(() => {
		//console.log("<TabsLayout5>".padEnd(14) + "%cuseEffect()", "color: orange", "-rendering:", rendering)
		const { routes } = appContext
		const matchingRoutes = matchRoutes(routes, pathname)
		//console.info("<TabsLayout5> -matchingRoutes:", matchingRoutes)

		if (matchingRoutes.length === 0) console.warn("<TabsLayout5> useEffect() -matchingRoutes is empty!")
		const matchedRoute = matchingRoutes ? matchingRoutes[0] : false
		//console.info("<TabsLayout5> -matchedRoute:", matchedRoute)
		//console.info("<TabsLayout5> -matchedRoute:", matchedRoute ? true : false)

		const currentRoute = FuseUtils.findMatchRoute(pathname, matchedRoute)
		const matchTabIndex = list.findIndex((tab) => tab.key === currentRoute.key)
		const matchTab = list[matchTabIndex]

		console.log("<TabsLayout5> useEffect() -matchTab:", matchTab ? true : false, "-index:", matchTabIndex, { list }, { currentRoute }, { matchTab })

		if (matchTabIndex === -1) {
			const link = FuseUtils.findObjecByPropertyValue(navigation, "url", currentRoute.basepath)
			console.log("<TabsLayout5> useEffect() -link:", link)
			if (link) {
				const newTab = {
					//index: list.length,
					id: link.id,
					title: link.title,
					icon: link.icon,
					basepath: currentRoute.basepath,
					url: currentRoute.url,
					key: currentRoute.key,
					self: currentRoute.self,
				}
				console.log("<TabsLayout5> useEffect() -adding new tab:", { newTab })
				dispatch(updateUserTabs(newTab))
			}
		} else {
			if (matchTabIndex !== currentTabIndex) {
				console.log("<TabsLayout5> useEffect() -updating currentTabIndex:", currentTabIndex, "->", matchTabIndex)
				dispatch(updateUserTabIndex(matchTabIndex))
			} else {
				//console.log("<TabsLayout5> useEffect() -same currentTabIndex:", currentTabIndex)
				//console.log("<TabsLayout5> useEffect() -currentRoute.url:", currentRoute.url, "-matchTab.url:", matchTab.url)
				if (currentRoute.url !== matchTab.url) {
					const replaceTab = { ...matchTab, ...{ url: currentRoute.url } }
					console.log("<TabsLayout5> useEffect() -updating tab:", { replaceTab })
					dispatch(replaceUserTabs(replaceTab))
				}
			}
		}
	})

	/*useEffect(() => {
	//	console.log("<TabsLayout5>".padEnd(14) + "%cuseEffect()", "color: orange", "-rendering:", rendering)
	if (matchTabIndex < 0) {
		// open new tab for a known navigation route
		const link = FuseUtils.findObjecByPropertyValue(navigation, "url", basepath)
		//console.log("<TabsLayout5> useEffect() -link:", link)
		if (link) {
			const newTab = {
				//index: list.length,
				id: link.id,
				title: link.title,
				icon: link.icon,
				basepath: basepath,
				url: urlpath,
			}
			console.log("<TabsLayout5> useEffect() -adding new tab:", newTab.title)
			//dispatch(updateUserTabs(newTab))
		} else {
			const newTab = {
				//index: list.length,
				id: FuseUtils.generateUUID(),
				title: "Selfpage",
				icon: "heroicons-outline:ticket", //matchedRoute.route.icon,
				basepath: basepath,
				url: urlpath, //ex key
			}
			console.log("<TabsLayout5> useEffect() -newTab ***FIXME***:", newTab.title)
			//dispatch(updateUserTabs(newTab))
		}
	} else {
		//console.log(matchTab.url, pathname)
		if (matchTabIndex !== currentTabIndex) {
			console.log("<TabsLayout5> useEffect() -updating currentTabIndex:", currentTabIndex, "->", matchTabIndex)
			//dispatch(updateUserTabIndex(matchTabIndex)) // ora è in handleTabChange()
		} else if (matchTab.url !== pathname) {
			const replaceTab = { ...matchTab, ...{ url: pathname } }
			console.log("<TabsLayout5> useEffect() -replaced tab:", { replaceTab }, "DEBUG")
			//dispatch(replaceUserTabs(replaceTab))
		} else {
			// nothing to do DEBUG
			console.log("<TabsLayout5> useEffect() -same currentTabIndex:", currentTabIndex)
		}
	}
	})*/

	const handleMouseHover = (index) => {
		//debug && console.log("<TabsLayout5> handleMouseHover() -hoveredTab:", hoveredTab, " -count:", list.length)
		rendering = -1
		setHoveredTab(index)
	}

	const handleMouseOut = () => {
		//debug && console.log("<TabsLayout5>: handleMouseOut() -hoveredTab: ", hoveredTab)
		rendering = -1
		setHoveredTab(null)
	}

	const handleDeleteTab = (event, index) => {
		rendering = -1
		debug && console.log(`<TabsLayout5> handleDeleteTab(${index}) -indexToDelete:`, index)
		event.stopPropagation()

		dispatch(deleteUserTabs(index))

		setHoveredTab(null)

		if (currentTabIndex == index) {
			// deleting current tab should land to last remained or next one
			debug && console.log(`<TabsLayout5>: handleDeleteTab(${index}) -currentTabIndex and index are the same`, currentTabIndex)
			const route = list[index == 0 ? ++index : --index].url
			debug && console.log(`<TabsLayout5> %chandleDeleteTab(${index}) -navigate to:" + ${route}`, "color: grey")
			navigate(route)
		}
	}

	const handleTabChange = (SyntheticEvent, index) => {
		rendering = -1
		dispatch(updateUserTabIndex(index))

		const route = list[index].url
		console.log(`<TabsLayout5> %chandleTabChange(${currentTabIndex} -> ${index}) -navigate to: ${route}`, "color: grey")
		navigate(route)
	}

	return (
		<Tabs
			indicatorColor="primary"
			value={currentTabIndex}
			onChange={handleTabChange}
			variant="scrollable"
			scrollButtons="auto"
			allowScrollButtonsMobile
			aria-label="secondary portal tabs"
			textColor="secondary"
			classes={{ root: "w-full border-b-1 min-h-32" }}
			//classes={{ root: "w-full h-2 border-b-1" }}
		>
			{list.map((tab, index) => (
				<Tab
					classes={{ root: "min-h-32 md:min-h-32" }}
					key={index}
					label={tab.title}
					iconPosition="start"
					icon={
						<div>
							{list.length === 1 ? (
								<FuseSvgIcon size={16} color="inherit">
									{tab.icon}
								</FuseSvgIcon>
							) : (
								<div
									//onMouseOver={() => handleMouseHover(index)}
									//onMouseOut={handleMouseOut}
									onClick={(event) => handleDeleteTab(event, index)}
									//style={{ cursor: "pointer" }}
								>
									{hoveredTab === index ? (
										<FuseSvgIcon size={16} color="error">
											heroicons-outline:x-circle
										</FuseSvgIcon>
									) : (
										<FuseSvgIcon size={16} color="inherit">
											{tab.icon}
										</FuseSvgIcon>
									)}
								</div>
							)}
						</div>
					}
				/>
			))}
		</Tabs>
	)
}

//TabsLayout5.whyDidYouRender = true

export default memo(TabsLayout5)
