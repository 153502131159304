//import AcademyAppConfig from "./academy/AcademyAppConfig"
//import HelpCenterAppConfig from "./help-center/HelpCenterAppConfig"
//import ScrumboardAppConfig from "./scrumboard/ScrumboardAppConfig"
//import CalendarAppConfig from "./calendar/CalendarAppConfig"
//import ECommerceAppConfig from "./e-commerce/ECommerceAppConfig"
//import ChatAppConfig from "./chat/ChatAppConfig"
//import MailboxAppConfig from "./mailbox/MailboxAppConfig"
//import ItemsAppConfig from "./portal/menu-items/ItemsAppConfig"
//import FileManagerAppConfig from "./file-manager/FileManagerAppConfig"
//import ContactsAppConfig from "./contacts/ContactsAppConfig"
//import NotesAppConfig from "./notes/NotesAppConfig"
//import TasksAppConfig from "./tasks/TasksAppConfig"
//import ProfileConfig from "./profile/profileConfig"
//import TicketsAppConfig from "./tickets/TicketsAppConfig"
//import DmsAppConfig from "./dms/DmsAppConfig"
import ELogisticAppConfig from "./e-logistic/ELogisticAppConfig"

// Variabile per abilitare o disabilitare le pagine di sviluppo
const isDevMode = process.env.REACT_APP_ENABLE_DEV_PAGES === "true"

let AcademyAppConfig = [],
	HelpCenterAppConfig = [],
	ScrumboardAppConfig = [],
	CalendarAppConfig = [],
	ECommerceAppConfig = [],
	ChatAppConfig = [],
	MailboxAppConfig = [],
	ItemsAppConfig = [],
	FileManagerAppConfig = [],
	ContactsAppConfig = [],
	NotesAppConfig = [],
	TasksAppConfig = [],
	ProfileConfig = [],
	TicketsAppConfig = [],
	DmsAppConfig = []

if (isDevMode) {
	AcademyAppConfig = require("./academy/AcademyAppConfig").default
	HelpCenterAppConfig = require("./help-center/HelpCenterAppConfig").default
	ScrumboardAppConfig = require("./scrumboard/ScrumboardAppConfig").default
	CalendarAppConfig = require("./calendar/CalendarAppConfig").default
	ECommerceAppConfig = require("./e-commerce/ECommerceAppConfig").default
	ChatAppConfig = require("./chat/ChatAppConfig").default
	MailboxAppConfig = require("./mailbox/MailboxAppConfig").default
	ItemsAppConfig = require("./portal/menu-items/ItemsAppConfig").default
	FileManagerAppConfig = require("./file-manager/FileManagerAppConfig").default
	ContactsAppConfig = require("./contacts/ContactsAppConfig").default
	NotesAppConfig = require("./notes/NotesAppConfig").default
	TasksAppConfig = require("./tasks/TasksAppConfig").default
	ProfileConfig = require("./profile/profileConfig").default
	TicketsAppConfig = require("./tickets/TicketsAppConfig").default
	DmsAppConfig = require("./dms/DmsAppConfig").default
}

const appsConfigs = [
	//AcademyAppConfig,
	...((isDevMode && [AcademyAppConfig]) || []),
	//HelpCenterAppConfig,
	...((isDevMode && [HelpCenterAppConfig]) || []),
	//ScrumboardAppConfig,
	...((isDevMode && [ScrumboardAppConfig]) || []),
	//CalendarAppConfig,
	...((isDevMode && [CalendarAppConfig]) || []),
	//ECommerceAppConfig,
	...((isDevMode && [ECommerceAppConfig]) || []),
	//ChatAppConfig,
	...((isDevMode && [ChatAppConfig]) || []),
	//MailboxAppConfig,
	...((isDevMode && [MailboxAppConfig]) || []),
	//ItemsAppConfig,
	...((isDevMode && [ItemsAppConfig]) || []),
	//FileManagerAppConfig,
	...((isDevMode && [FileManagerAppConfig]) || []),
	//ContactsAppConfig,
	...((isDevMode && [ContactsAppConfig]) || []),
	//NotesAppConfig,
	...((isDevMode && [NotesAppConfig]) || []),
	//TasksAppConfig,
	...((isDevMode && [TasksAppConfig]) || []),
	//ProfileConfig,
	...((isDevMode && [ProfileConfig]) || []),
	//TicketsAppConfig,
	...((isDevMode && [TicketsAppConfig]) || []),
	//DmsAppConfig,
	...((isDevMode && [DmsAppConfig]) || []),
	ELogisticAppConfig,
]

export default appsConfigs
