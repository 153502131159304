import { v4 as uuidv4 } from "uuid"
import _ from "@lodash"
import MobileDetect from "mobile-detect"
import * as colors from "@mui/material/colors"
import format from "date-fns/format"

class EventEmitter {
	constructor() {
		this.events = {}
	}

	_getEventListByName(eventName) {
		if (typeof this.events[eventName] === "undefined") {
			this.events[eventName] = new Set()
		}
		return this.events[eventName]
	}

	on(eventName, fn) {
		this._getEventListByName(eventName).add(fn)
	}

	once(eventName, fn) {
		const self = this

		const onceFn = (...args) => {
			self.removeListener(eventName, onceFn)
			fn.apply(self, args)
		}
		this.on(eventName, onceFn)
	}

	emit(eventName, ...args) {
		this._getEventListByName(eventName).forEach(
			// eslint-disable-next-line func-names
			function (fn) {
				fn.apply(this, args)
			}.bind(this)
		)
	}

	removeListener(eventName, fn) {
		this._getEventListByName(eventName).delete(fn)
	}
}

class FuseUtils {
	/**
	 * Helper per determinare se l'app è in modalità standalone (WPA)
	 * su iOS: window.navigator.standalone, su altri: matchMedia.
	 */
	static isWPA() {
		return window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true
	}

	/**
	 * Helper per determinare se l'app è in modalità mobile)
	 */
	static isMobile() {
		const md = new MobileDetect(window.navigator.userAgent)
		return md.mobile()
	}

	/**
	 * Determine if we are in localhost
	 */
	static isLocalhost = () =>
		Boolean(
			window.location.hostname === "localhost" ||
				// [::1] is the IPv6 localhost address.
				window.location.hostname === "[::1]" ||
				// 127.0.0.1/8 is considered localhost for IPv4.
				window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
		)

	/**
	 *
	 * @param {*} mainArr
	 * @param {*} searchText
	 * @returns
	 */
	static filterArrayByString(mainArr, searchText) {
		if (searchText === "") {
			return mainArr
		}

		searchText = searchText.toLowerCase()

		return mainArr.filter((itemObj) => this.searchInObj(itemObj, searchText))
	}

	/**
	 *
	 * @param {*} itemObj
	 * @param {*} searchText
	 * @returns
	 */
	static searchInObj(itemObj, searchText) {
		if (!itemObj) {
			return false
		}

		const propArray = Object.keys(itemObj)

		for (let i = 0; i < propArray.length; i += 1) {
			const prop = propArray[i]
			const value = itemObj[prop]

			if (typeof value === "string") {
				if (this.searchInString(value, searchText)) {
					return true
				}
			} else if (Array.isArray(value)) {
				if (this.searchInArray(value, searchText)) {
					return true
				}
			}

			if (typeof value === "object") {
				if (this.searchInObj(value, searchText)) {
					return true
				}
			}
		}
		return false
	}

	/**
	 *
	 * @param {*} arr
	 * @param {*} searchText
	 * @returns
	 */
	static searchInArray(arr, searchText) {
		arr.forEach((value) => {
			if (typeof value === "string") {
				if (this.searchInString(value, searchText)) {
					return true
				}
			}

			if (typeof value === "object") {
				if (this.searchInObj(value, searchText)) {
					return true
				}
			}
			return false
		})
		return false
	}

	/**
	 *
	 * @param {*} value
	 * @param {*} searchText
	 * @returns
	 */
	static searchInString(value, searchText) {
		return value.toLowerCase().includes(searchText)
	}

	static generateUUID() {
		return uuidv4()
	}

	static generateGUID() {
		function S4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1)
		}

		return S4() + S4()
	}

	static toggleInArray(item, array) {
		if (array.indexOf(item) === -1) {
			array.push(item)
		} else {
			array.splice(array.indexOf(item), 1)
		}
	}

	static handleize(text) {
		return text
			.toString()
			.toLowerCase()
			.replace(/\s+/g, "-") // Replace spaces with -
			.replace(/\W+/g, "") // Remove all non-word chars
			.replace(/--+/g, "-") // Replace multiple - with single -
			.replace(/^-+/, "") // Trim - from start of text
			.replace(/-+$/, "") // Trim - from end of text
	}

	static setRoutes(config, defaultAuth) {
		let routes = [...config.routes]

		routes = routes.map((route) => {
			let auth = config.auth || config.auth === null ? config.auth : defaultAuth || null
			auth = route.auth || route.auth === null ? route.auth : auth
			const settings = _.merge({}, config.settings, route.settings)

			return {
				...route,
				settings,
				auth,
			}
		})

		return [...routes]
	}

	static generateRoutesFromConfigs(configs, defaultAuth) {
		let allRoutes = []
		configs.forEach((config) => {
			allRoutes = [...allRoutes, ...this.setRoutes(config, defaultAuth)]
		})
		return allRoutes
	}

	/**
	 *
	 * @param {*} obj
	 * @param {*} id
	 * @returns
	 */
	static findById(obj, id) {
		let i
		let childObj
		let result

		if (id === obj.id) {
			return obj
		}

		for (i = 0; i < Object.keys(obj).length; i += 1) {
			childObj = obj[Object.keys(obj)[i]]

			if (typeof childObj === "object") {
				result = this.findById(childObj, id)
				if (result) {
					return result
				}
			}
		}
		return false
	}

	static getFlatNavigation(navigationItems, flatNavigation = []) {
		for (let i = 0; i < navigationItems.length; i += 1) {
			const navItem = navigationItems[i]

			if (navItem.type === "item") {
				flatNavigation.push({
					id: navItem.id,
					title: navItem.title,
					type: navItem.type,
					icon: navItem.icon || false,
					url: navItem.url,
					auth: navItem.auth || null,
				})
			}

			if (navItem.type === "collapse" || navItem.type === "group") {
				if (navItem.children) {
					this.getFlatNavigation(navItem.children, flatNavigation)
				}
			}
		}
		return flatNavigation
	}

	static randomMatColor(hue) {
		hue = hue || "400"
		const mainColors = ["red", "pink", "purple", "deepPurple", "indigo", "blue", "lightBlue", "cyan", "teal", "green", "lightGreen", "lime", "yellow", "amber", "orange", "deepOrange"]
		const randomColor = mainColors[Math.floor(Math.random() * mainColors.length)]
		return colors[randomColor][hue]
	}

	static difference(object, base) {
		function changes(_object, _base) {
			return _.transform(_object, (result, value, key) => {
				if (!_.isEqual(value, _base[key])) {
					result[key] = _.isObject(value) && _.isObject(_base[key]) ? changes(value, _base[key]) : value
				}
			})
		}

		return changes(object, base)
	}

	static EventEmitter = EventEmitter

	static updateNavItem(nav, id, item) {
		//console.log("nav:", nav, "id:", id, "item", item)
		return nav.map((_item) => {
			if (_item.id === id) {
				return _.merge({}, _item, item)
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.updateNavItem(_item.children, id, item),
				})
			}

			return _.merge({}, _item)
		})
	}

	static removeNavItem(nav, id) {
		return nav
			.map((_item) => {
				if (_item.id === id) {
					return null
				}

				if (_item.children) {
					return _.merge({}, _.omit(_item, ["children"]), {
						children: this.removeNavItem(_item.children, id),
					})
				}

				return _.merge({}, _item)
			})
			.filter((s) => s)
	}

	static prependNavItem(nav, item, parentId) {
		if (!parentId) {
			return [item, ...nav]
		}

		return nav.map((_item) => {
			if (_item.id === parentId && _item.children) {
				return {
					..._item,
					children: [item, ..._item.children],
				}
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.prependNavItem(_item.children, item, parentId),
				})
			}

			return _.merge({}, _item)
		})
	}

	static appendNavItem(nav, item, parentId) {
		if (!parentId) {
			return [...nav, item]
		}

		return nav.map((_item) => {
			if (_item.id === parentId && _item.children) {
				return {
					..._item,
					children: [..._item.children, item],
				}
			}

			if (_item.children) {
				return _.merge({}, _item, {
					children: this.appendNavItem(_item.children, item, parentId),
				})
			}

			return _.merge({}, _item)
		})
	}

	/**
	 *
	 * @param {*} authArr
	 * @param {*} userRole
	 * @returns
	 */
	static hasPermission(authArr, userRole) {
		/**
		 * If auth array is not defined
		 * Pass and allow
		 */
		if (authArr === null || authArr === undefined) {
			//console.info("auth is null || undefined: Pass and allow")
			return true
		}
		/**
		 * If auth is a single string, convert in array
		 */
		if (typeof authArr === "string") {
			authArr = [authArr]
		}
		if (authArr.length === 0) {
			/**
			 * if auth array is empty means,
			 * allow only user role is guest (null or empty[])
			 */
			//console.info("auth is empty[]: allow only user role is guest")
			return !userRole || userRole.length === 0
		}
		/**
		 * Check if user has grants
		 */

		/*
            Check if user role is array,
            */
		if (userRole && Array.isArray(userRole)) {
			//console.info("FuseUtils.hasPermission() -userRole:", userRole)
			return authArr.some((r) => userRole.indexOf(r) >= 0)
		}

		/*
            Check if user role is string,
            */
		return authArr.includes(userRole)
	}

	static filterRecursive(data, predicate) {
		// if no data is sent in, return null, otherwise transform the data
		return !data
			? null
			: data.reduce((list, entry) => {
					let clone = null
					if (predicate(entry)) {
						// if the object matches the filter, clone it as it is
						clone = { ...entry }
					}
					if (entry.children != null) {
						// if the object has childrens, filter the list of children
						const children = this.filterRecursive(entry.children, predicate)
						if (children.length > 0) {
							// if any of the children matches, clone the parent object, overwrite
							// the children list with the filtered list
							clone = { ...entry, children }
						}
					}

					// if there's a cloned object, push it to the output list
					if (clone) {
						list.push(clone)
					}
					return list
				}, [])
	}

	/**
	 * Function to iterate over a JSON tree and return the object that contains the property value
	 * @param {*} tree
	 * @param {*} key
	 * @param {*} value
	 * @returns
	 */
	static findObjecByPropertyValue(tree, key, value) {
		if (typeof tree === "object" && tree !== null) {
			if (tree[key] === value) return tree

			for (const prop in tree) {
				const result = this.findObjecByPropertyValue(tree[prop], key, value)
				if (result !== undefined) return result
			}
		}
		return undefined
	}

	static excludeObjectByIndex(mainArr, index) {
		const newArr = [...mainArr]
		newArr.splice(parseInt(index), 1)
		return newArr
	}

	static excludeObjectByKey(mainArr, key, value) {
		return mainArr.filter((obj) => obj[key] != value) //FIXME da rivedere index come uuid di testo
	}

	static getObjectByKey(mainArr, key, value) {
		return mainArr.filter((obj) => obj[key] == value) //FIXME da rivedere index come uuid di testo
	}

	static differCompare(obj1, obj2) {
		return _.differenceWith(obj1, obj2, function (o1, o2) {
			o1["key"] === o2["key"]
		})
	}

	/**
	 * se (basepath = urlpath) => same tab e salvo pathname come percorso interno
		altrimenti (urlpath = pathname) => new tab
		in both cases => uso basepath per il navigator object
	 * @param {*} pathname 
	 * @param {*} matchingRoutes 
	 * @returns {Object} matchingRoute or null
	 */
	static findMatchRoute(pathname, matchedRoute) {
		const currentRoute = {
			basepath: matchedRoute.pathnameBase,
			url: pathname,
		}
		return currentRoute
	}

	/**
	 * Utilizzata come comparatore nella funzione getComparator
	 * @param {*} a
	 * @param {*} b
	 * @param {*} orderBy
	 * @returns
	 */
	static descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
		return 0
	}

	/**
	 * Restituisce un comparatore per l'ordinamento, che viene utilizzato nella funzione visibleRows.
	 * Questo approccio ottimizza le prestazioni, eseguendo il calcolo solo quando le dipendenze cambiano.
	 * @param {*} order
	 * @param {*} orderBy
	 * @returns
	 */
	static getComparator(order, orderBy) {
		return order === "desc" ? (a, b) => this.descendingComparator(a, b, orderBy) : (a, b) => -this.descendingComparator(a, b, orderBy)
	}

	static formatDate(dateString, language, mm = "2-digit") {
		if (!dateString || dateString.length !== 8) return "Invalid date"
		const year = dateString.slice(0, 4)
		const month = dateString.slice(4, 6)
		const day = dateString.slice(6, 8)
		const date = new Date(`${year}-${month}-${day}T00:00:00`)

		return new Intl.DateTimeFormat(language, {
			year: "numeric",
			month: mm, // or "short" for abbreviated month names
			day: "2-digit",
		}).format(date)
	}

	static formatDateTime(dateString, language) {
		// Validate input
		if (!dateString || (dateString.length !== 8 && dateString.length !== 12)) {
			return "Invalid date"
		}

		// Extract date parts
		const year = dateString.slice(0, 4)
		const month = dateString.slice(4, 6)
		const day = dateString.slice(6, 8)

		// Default time parts
		let hours = "00"
		let minutes = "00"

		// If the input includes time (yyyyMMddHHmm)
		if (dateString.length === 12) {
			hours = dateString.slice(8, 10)
			minutes = dateString.slice(10, 12)
		}

		// Create a Date object
		const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`)

		// Format the date and time
		return new Intl.DateTimeFormat(language, {
			year: "numeric",
			month: "short", // or "short" for abbreviated month names
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
		}).format(date)
	}

	/*static findMatchRoute(pathname, matchedRoute) {
		const basepath = "/" + matchedRoute.route.path.split("/:", 1)[0]
		const currentRoute = {
			basepath,
			url: pathname,
			key: basepath,
			self: false,
		}

		if (basepath === matchedRoute.pathname && basepath === pathname) {
		} else if (basepath === matchedRoute.pathname && basepath != pathname) {
		} else {
			currentRoute.key = pathname
			currentRoute.self = true
		}
		return currentRoute
	}*/
}

export default FuseUtils
