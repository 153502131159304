import FuseUtils from "@fuse/utils"
import FuseLoading from "@fuse/core/FuseLoading"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import settingsConfig from "app/configs/settingsConfig"
import dashboardsConfigs from "../main/dashboards/dashboardsConfigs"
import appsConfigs from "../main/apps/appsConfigs"
import HomeConfig from "../main/home/HomeConfig"
import SignInConfig from "../main/sign-in/SignInConfig"
import SignOnConfig from "../main/sign-on/SignOnConfig"
import SignUpConfig from "../main/sign-up/SignUpConfig"
import SignOutConfig from "../main/sign-out/SignOutConfig"
import errorPagesConfig from "../main/error/errorPagesConfig"

// Variabile per abilitare o disabilitare le pagine di sviluppo
const isDevMode = process.env.REACT_APP_ENABLE_DEV_PAGES === "true"

// Dynamic imports for development-only routes
let pagesConfigs = []
let userInterfaceConfigs = []
let authRoleExamplesConfigs = []
let DocumentationConfig = {}

if (isDevMode) {
	pagesConfigs = require("../main/pages/pagesConfigs").default
	userInterfaceConfigs = require("../main/user-interface/UserInterfaceConfigs").default
	authRoleExamplesConfigs = require("../main/auth/authRoleExamplesConfigs").default
	DocumentationConfig = require("../main/documentation/DocumentationConfig").default
}

const routeConfigs = [...appsConfigs, ...dashboardsConfigs, ...((isDevMode && pagesConfigs) || []), ...((isDevMode && userInterfaceConfigs) || []), ...((isDevMode && authRoleExamplesConfigs) || []), ...((isDevMode && [DocumentationConfig]) || []), HomeConfig, SignOutConfig, SignInConfig, SignOnConfig, SignUpConfig, errorPagesConfig]

const DynamicRedirect = () => {
	const user = useSelector((state) => state.user)
	const redirectUrl = user?.loginRedirectUrl || settingsConfig.loginRedirectUrl
	return <Navigate to={redirectUrl} />
}

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
	{
		path: "/",
		//element: <ProjectDashboardApp />, // test SPA without route path. no buono per tabs
		//element: <Navigate to="dashboards/project" />, //this should be public home page
		//element: <Navigate to={settingsConfig.loginRedirectUrl} />, //dynamic non funge
		element: <DynamicRedirect />, //dynamic from user store
		auth: settingsConfig.defaultAuth,
	},
	{
		path: "loading",
		element: <FuseLoading />,
	},
	{
		path: "*",
		element: <Navigate to="error/404" />,
	},
]

export default routes
