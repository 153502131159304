import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import FuseUtils from "@fuse/utils"
import axios from "@fuse/axios"
const debug = false

/* proto with reject
export const getPosts = createAsyncThunk(
	'posts/getPosts',

	async (arg, { dispatch, getState, extra, requestId, signal, rejectWithValue }) => {
	  
		 return fetch(
			  `https://jsonplaceholder.typicode.com/posts?_limit=${arg.limit}`
		 ).then((res) => {
			  if (!res.ok) {
					
					return rejectWithValue([], "api url not found from");
			  }
			  return res.json()
		 }).catch(error => {
			 
			  return rejectWithValue([], error);
			 
		 })
	}

)*/

/* proto without createAsyncThunk
export const changeFuseTheme = (theme) => (dispatch, getState) => {
	debug && console.log("settingsSlice.js changeFuseTheme")
	const { fuse } = getState()
	const { settings } = fuse

	const newSettings = {
		...settings.current,
		theme: {
			main: theme,
			navbar: theme,
			toolbar: theme,
			footer: theme,
		},
	}

	dispatch(setDefaultSettings(newSettings))
}
*/

export const setTabs = createAsyncThunk("tabs/setTabs", async (user, { getState }) => {
	//debug && console.log("tabsSlice.setTabs(user):", user.data.displayName, user.role)
	debug && console.log("tabsSlice.setTabs(initialState) -currentTabIndex:", initialState.currentTabIndex)

	if (!user.role || user.role.length === 0) {
		return // is guest
	}
	if (user.data.displayName == "Anonymous") {
		return // is guest for sure
	}
	if (user.data.settings.layout.style != "layout5") {
		return // tabbed layout only
	}

	if (user.data.tabs) {
		//debug && console.log("tabsSlice.setTabs -pathname:", window.location.pathname)
		let index = user.data.tabs.findIndex((tab) => tab.url === window.location.pathname)
		debug && console.log("tabsSlice.setTabs -tabIndex:", index)
		if (index === -1) index = 0

		return {
			currentTabIndex: index,
			list: user.data.tabs,
		}
	} /*else {
		return initialState
	}*/
})

export const getInitialState = createAsyncThunk("tabs/getInitialState", async (_, { getState }) => {
	debug && console.log("tabsSlice.getInitialState")
	return initialState
})

export const updateUserTabs = (tab) => async (dispatch, getState) => {
	debug && console.log("tabsSlice.updateUserTabs -tab:", tab)
	const { user } = getState()

	if (!user.role || user.role.length === 0) return

	await dispatch(addTab(tab))

	const { fuse } = getState()
	const { tabs } = fuse
	debug && console.log("tabsSlice.updateUserTabs -tabs:", tabs)

	axios.patch(
		"/api/v1/users/:id/tabs",
		{
			tabs,
		},
		{ bind: { id: user.id } }
	)

	//console.log(tabs)
	//console.log(response.data)
	//const data = await response.data
	return tabs
}

export const updateUserTabIndex = (index) => async (dispatch, getState) => {
	debug && console.log("tabsSlice.updateUserTabIndex -index: ", index)
	const { user } = getState()
	if (!user.role || user.role.length === 0) {
		// is guest
		return
	}

	await dispatch(changeTab(index))

	// no need to save currentTabIndex
	/*const response = await axios.patch(
		"/api/v1/users/:id/tabs/index",
		{
			index,
		},
		{ bind: { id: user.id } }
	)

	const data = await response.data
	console.log(data)
	return data
		*/

	return { message: "User Tab updated" }
}

export const replaceUserTabs = (tab) => async (dispatch, getState) => {
	debug && console.log("tabsSlice.replaceUserTabs -tab:", tab)
	const { user } = getState()
	if (!user.role || user.role.length === 0) return

	const { fuse } = getState()
	const { tabs } = fuse
	debug && console.log("tabsSlice.replaceUserTabs -tabs:", tabs)
	const { currentTabIndex, list } = tabs

	let newList = list.map((element, index) => (index === currentTabIndex ? tab : element))

	const newTabs = {
		currentTabIndex: currentTabIndex,
		list: newList,
	}
	debug && console.log("tabsSlice.replaceUserTabs -newTabs:  ", newTabs)
	await dispatch(updateTab(newTabs))

	const response = await axios.patch(
		"/api/v1/users/:id/tabs",
		{
			tabs: newTabs,
		},
		{ bind: { id: user.id } }
	)

	return newTabs
	//const data = await response.data
	//return data
}

export const deleteUserTabs = (index) => async (dispatch, getState) => {
	debug && console.log("tabsSlice.deleteUserTabs arg = index to delete: ", index)
	const { user } = getState()
	if (!user.role || user.role.length === 0) return

	const { fuse } = getState()
	const { tabs } = fuse
	debug && console.log("tabsSlice.deleteUserTabs -current: ", tabs)
	const { currentTabIndex, list } = tabs

	let newIndex = currentTabIndex
	const newList = FuseUtils.excludeObjectByIndex(list, index)
	if (newIndex >= index) newIndex--
	if (newIndex < 0) newIndex = 0
	debug && console.log("tabsSlice.deleteUserTabs -newIndex:  ", newIndex)

	const newTabs = {
		currentTabIndex: newIndex,
		list: newList,
	}
	debug && console.log("tabsSlice.deleteUserTabs -newTabs:  ", newTabs)
	await dispatch(updateTab(newTabs))

	const response = await axios.patch(
		"/api/v1/users/:id/tabs",
		{
			tabs: newTabs,
		},
		{ bind: { id: user.id } }
	)

	return newTabs
	const data = await response.data
	return data
}

const initialState = {
	currentTabIndex: 0,
	list: [
		{
			id: "dashboards.project",
			title: "Project",
			icon: "heroicons-outline:cash",
			basepath: "/dashboards/project",
			url: "/dashboards/project",
			key: "/dashboards/project",
			self: false,
		},
	],
}

const tabsSlice = createSlice({
	name: "tabs",
	initialState,
	reducers: {
		changeTab: (state, action) => {
			state.currentTabIndex = action.payload
		},
		addTab: (state, action) => {
			state.list.push(action.payload)
			state.currentTabIndex = state.list.length - 1
		},
		updateTab: (state, action) => action.payload,
	},
	extraReducers: (builder) => {
		builder
			.addCase(setTabs.pending, () => initialState)
			.addCase(setTabs.fulfilled, (state, action) => action.payload)
			.addCase(getInitialState.fulfilled, (state, action) => action.payload)
	},
})

export const selectTabs = (state) => state.fuse.tabs

export const { changeTab, addTab, updateTab } = tabsSlice.actions

export default tabsSlice.reducer
