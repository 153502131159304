const locale = {
	APPLICATIONS: "Applicazioni",
	DASHBOARDS: "Pannelli",
	CALENDAR: "Calendario",
	ECOMMERCE: "Commerce",
	ELOGISTIC: "Logistica Canon",
	ACADEMY: "Accademia",
	MAIL: "Posta",
	TASKS: "Attività",
	DMS: "Gestione Documenti",
	FILE_MANAGER: "Gestione File",
	CONTACTS: "Contatti",
	TICKETS: "Chiamate",
	CHAT: "Conversazioni",
	SCRUMBOARD: "Scrum Board",
	NOTES: "Note",
}

export default locale
