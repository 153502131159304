import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import history from "@history"
import _ from "@lodash"
import { setInitialSettings } from "app/store/fuse/settingsSlice"
import { setTabs, getInitialState } from "app/store/fuse/tabsSlice"
import { showMessage } from "app/store/fuse/messageSlice"
import settingsConfig from "app/configs/settingsConfig"
import jwtService from "../auth/services/jwtService"
const debug = false

// extraReducer
export const setUser = createAsyncThunk("user/setUser", async (user, { dispatch, getState }) => {
	debug && console.log("userSlice.setUser  -user: ", { user })
	/*
    You can redirect the logged-in user to a specific route depending on his role
    */
	if (user.loginRedirectUrl) {
		settingsConfig.loginRedirectUrl = user.loginRedirectUrl // or for example '/apps/academy'
	}
	debug && console.log("userSlice.setUser  -default homepage: ", settingsConfig.loginRedirectUrl)

	if (user.data.settings.layout.style === "layout5") dispatch(setTabs(user)) // added *FG*

	return user
})

// extraReducer
export const updateUserSettings = createAsyncThunk("user/updateSettings", async (settings, { dispatch, getState }) => {
	debug && console.log("userSlice.updateUserSettings  -settings: ", { settings })
	const { user } = getState()
	const newUser = _.merge({}, user, { data: { settings } })

	if (settings.layout.style != "layout5") {
		debug && console.log("userSlice.updateUserSettings  -layout: ", settings.layout.style)
		delete newUser.data.tabs
		dispatch(getInitialState())
	}
	dispatch(updateUserData(newUser))

	return newUser
})

// extraReducer
export const updateUserShortcuts = createAsyncThunk("user/updateShortucts", async (shortcuts, { dispatch, getState }) => {
	debug && console.log("userSlice.updateUserShortcuts  -shortcuts: ", { shortcuts })
	const { user } = getState()
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts,
		},
	}

	dispatch(updateUserData(newUser))

	return newUser
})

export const updateUserData = (user) => (dispatch, getState) => {
	debug && console.log("userSlice.updateUserData  -user: ", { user })
	if (!user.role || user.role.length === 0) {
		// is guest
		return
	}
	jwtService
		.updateUserData(user)
		.then(() => {
			dispatch(showMessage({ message: "User data saved with api" }))
		})
		.catch((error) => {
			dispatch(showMessage({ message: error.message }))
		})
}

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState()

	if (!user.role || user.role.length === 0) {
		// is guest
		return null
	}

	history.push({
		pathname: "/",
	})

	dispatch(setInitialSettings())

	return dispatch(userLoggedOut())
}

const initialState = {
	role: [], // guest
	data: {
		displayName: "Anonymous",
		photoURL: "assets/images/avatars/profile.jpg",
		email: "anonymous@crowdservices.it",
		shortcuts: ["apps.calendar", "apps.mailbox", "apps.contacts", "apps.tasks"],
	},
}

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		userLoggedOut: (state, action) => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateUserSettings.fulfilled, (state, action) => action.payload)
			.addCase(updateUserShortcuts.fulfilled, (state, action) => action.payload)
			.addCase(setUser.fulfilled, (state, action) => action.payload)
	},
})

// export reducer actions
export const { userLoggedOut } = userSlice.actions

// export current state
export const selectUser = ({ user }) => user

export const selectUserShortcuts = ({ user }) => user.data.shortcuts

export default userSlice.reducer
