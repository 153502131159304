import { lazy } from "react"
import authRoles from "../../../auth/authRoles"
const CryptoDashboardApp = lazy(() => import("./CryptoDashboardApp"))

const CryptoDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.staff,
	routes: [
		{
			path: "dashboards/crypto",
			element: <CryptoDashboardApp />,
		},
	],
}

export default CryptoDashboardAppConfig
