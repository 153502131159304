import { lazy } from "react"
import authRoles from "../../../auth/authRoles"
const AnalyticsDashboardApp = lazy(() => import("./AnalyticsDashboardApp"))

const AnalyticsDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.staff,
	routes: [
		{
			path: "dashboards/analytics",
			element: <AnalyticsDashboardApp />,
		},
	],
}

export default AnalyticsDashboardAppConfig
