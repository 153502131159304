import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import * as yup from "yup"
import _ from "@lodash"
import Paper from "@mui/material/Paper"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { selectUser } from "app/store/userSlice"
import { useSelector } from "react-redux"
import jwtService from "../../auth/services/jwtService"
import SignCommon from "../SignCommon/SignCommon"

import i18next from "i18next"
import it from "../SignCommon/i18n/it"
import en from "../SignCommon/i18n/en"
import { useTranslation } from "react-i18next"

i18next.addResourceBundle("en", "signCommon", en)
i18next.addResourceBundle("it", "signCommon", it)
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	//displayName: yup.string().required("You must enter display name"),
	//email: yup.string().email("You must enter a valid email").required("You must enter a email"),
	//password: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
	//passwordConfirm: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
	//acceptTermsConditions: yup.boolean().oneOf([true], "The terms and conditions must be accepted."),
})

const defaultValues = {
	displayName: "",
	email: "",
	password: "",
	passwordConfirm: "",
	acceptTermsConditions: false,
}

const SignOnPage = () => {
	console.log("<SignOnPage>.SignOnPage()")
	const { t } = useTranslation("signCommon")
	const user = useSelector(selectUser)

	const { control, formState, handleSubmit, setError, setValue, reset } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState

	useEffect(() => {
		console.log("<SignOnPage>.useEffect()")
		//setTimeout(() => jwtService.expireCookie(), 0)
		jwtService.expireSessionCookie()
	}, [])

	function onSubmit({ displayName, password, email }) {
		console.log("<SignOnPage>.onSubmit(email,password)")
		jwtService
			.createUser({
				displayName,
				password,
				email,
			})
			.then((user) => {
				// No need to do anything, registered user data will be set at app/auth/AuthContext
			})
			.catch((_errors) => {
				_errors.forEach((error) => {
					setError(error.type, {
						type: "manual",
						message: error.message,
					})
				})
			})
	}

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1)
	}

	const handleRefreshPage = () => {
		window.location.replace("/sign-on")
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
			<Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
					<img className="w-96 mx-auto" src="assets/images/logo/crowdc.svg" alt="logo" />

					<Typography className="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{`${t("SIGNON_MESSAGE")}`}</Typography>
					<br />
					<Typography className="mx-6 leading-6" color="text.secondary">
						{`${t("WELCOME")} ${user.google.given_name}!`}
						<br />
						{`${t("SIGNON_1")} ${t("SIGNON_2")}`}
					</Typography>
					<Typography className="mx-6 leading-6" color="text.secondary">
						{`${t("SIGNON_3")}`}
						<br />
						<br />
						{`${t("SIGNON_4")}`}
						<br />
						<br />
						{`${t("SIGNON_5")}`}
						<br />
						{`${t("SIGNON_6")}`}
					</Typography>
					<Typography className="mt-32 text-md font-medium text-center" color="text.secondary">
						<Button className="ml-4" variant="outlined" onClick={handleRefreshPage}>
							Login di nuovo
						</Button>
					</Typography>
				</div>
			</Paper>

			<SignCommon />
		</div>
	)
}

export default SignOnPage
