import { memo, Suspense, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectTabs } from "app/store/fuse/tabsSlice"
import { ignoredPaths } from "app/configs/ignoredPaths"
import FuseLoading from "@fuse/core/FuseLoading"
import PropTypes from "prop-types"

const debug = false

const FuseSuspenseTabbed = (props) => {
	debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cConstructor()", "color: deepskyblue") //, props.children.props)
	const cachedViews = useRef([])

	const { currentTabIndex, list } = useSelector(selectTabs)
	debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%ccurrentTabIndex: " + currentTabIndex + " list:", "color: deepskyblue", list)
	const { route, params, pathname, pathnameBase } = props.children.props.match
	//debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cpathnameBase:", "color: deepskyblue", pathnameBase)

	/* debug 
	const previousCurrentTabIndex = usePrevious(currentTabIndex)
	const previousList = usePrevious(list)
	const previousCachedViews = usePrevious(cachedViews.current)
	debug && previousCurrentTabIndex !== currentTabIndex && console.debug("\t%ccurrentTabIndex has changed from", "color: grey", previousCurrentTabIndex, "to", currentTabIndex)
	debug && !_.isEqual(previousList, list) && console.debug("\t%clist has changed from", "color: grey", previousList, "to", list)
	debug && !_.isEqual(previousCachedViews, cachedViews.current) && console.debug("\t%ccachedViews has changed from", "color: grey", previousCachedViews, "to", cachedViews.current)
	*/ //debug

	// Ignore paths that shouldn't be handled by the tab system
	if (ignoredPaths.includes(pathnameBase)) {
		debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cignored?", "color: deepskyblue", ignoredPaths.includes(pathnameBase))
		return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>
	}

	// Find the matching tab in the Redux store
	const matchTab = list.find((tab) => tab.basepath === pathnameBase)
	if (matchTab) {
		debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cmatchTab?", "color: deepskyblue", true)
		const component = { basepath: matchTab.basepath, value: props.children }
		debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%ccachedViews.length:", "color: deepskyblue", cachedViews.current.length, cachedViews.current)

		// Clean up cachedViews by removing components that are no longer in the current tab list
		cachedViews.current = cachedViews.current.filter((view) => list.some((tab) => tab.basepath === view.basepath))

		// Check if this component is already cached
		if (!cachedViews.current.some((obj) => obj.basepath === component.basepath)) {
			cachedViews.current.push(component)
		}
	} else {
		debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cmatchTab?", "color: deepskyblue", false)
	}

	if (cachedViews.current.length === 0) {
		debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cWARNING: Nothing to render", "color: deepskyblue")
		return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>
	}

	return cachedViews.current.map((component) => (
		<div key={component.basepath} className={component.basepath == pathname ? "flex flex-col flex-auto min-h-0 relative z-10" : "flex flex-col flex-auto min-h-0 relative z-10 hidden"}>
			{debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%ccomponent: " + component.basepath + " is " + (component.basepath == pathname ? "visible" : "hidden"), "color: deepskyblue")}
			{<Suspense fallback={<FuseLoading {...props.loadingProps} />}>{component.value}</Suspense>}
		</div>
	))
}

FuseSuspenseTabbed.propTypes = {
	loadingProps: PropTypes.object,
}

FuseSuspenseTabbed.defaultProps = {
	loadingProps: {
		delay: 0,
	},
}

export default FuseSuspenseTabbed
