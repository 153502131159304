import i18next from "i18next"
import { lazy } from "react"
import it from "./i18n/it"
import en from "./i18n/en"
import authRoles from "../../../auth/authRoles"
const SystemDashboardApp = lazy(() => import("./SystemDashboardApp"))

i18next.addResourceBundle("en", "systemDashboardApp", en)
i18next.addResourceBundle("it", "systemDashboardApp", it)

const SystemDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.supervisor, // ['admin','staff']
	routes: [
		{
			path: "dashboards/system",
			element: <SystemDashboardApp />,
		},
	],
}

export default SystemDashboardAppConfig
