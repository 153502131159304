import FuseUtils from "@fuse/utils/FuseUtils"
//import axios from "axios"
import axios from "@fuse/axios"
import jwtDecode from "jwt-decode"
import jwtServiceConfig from "./jwtServiceConfig"
import Cookies from "universal-cookie"
import { AES, enc } from "crypto-js"
//https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
//https://vivekkrishnavk.medium.com/using-jwts-as-http-only-cookies-with-react-js-a301991fdfa6
const debug = false

class JwtService extends FuseUtils.EventEmitter {
	init() {
		debug && console.log("<JwtService>.init()")
		this.setInterceptors()
		this.handleAuthentication()
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			(response) => {
				return response
			},
			(err) => {
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						debug && console.warn("\t-onAutoLogout emit: Invalid access_token")
						this.emit("onAutoLogout", "Invalid access_token")
						this.setSessionTokens(null)
					}
					throw err
				})
			}
		)
	}

	handleAuthentication = () => {
		//debug && console.log("<JwtService>.handleAuthentication()")

		const request_token = this.getRequestToken()
		//debug && console.warn("\t-request_token: " + request_token)
		if (request_token) {
			const { isValid, data } = this.isAuthTokenValid(request_token)
			if (isValid) {
				debug && console.warn("\t-request_token is valid")
				/* set Session without refresh_token. 
					Will be generated later in access_token = this.getAccessToken() */
				this.setSessionTokens({ access_token: request_token, refresh_token: "null", info: data.info })
			} else {
				debug && console.warn("\t-isAuthTokenValid() = false")
				this.setSessionTokens(null)
				debug && console.warn("\t-onAutoLogout emitted: request_token invalid")
				this.emit("onAutoLogout", "request_token invalid")

				return
			}
		}
		/*
			if (request_token) {
				if (this.isAuthTokenValid(request_token)) {
					debug && console.warn("\t-isAuthTokenValid(request_token) = true")
					// devo controllare se attraverso il token l'utente è attivo oppuer no
					/
					this.signUpWithToken(request_token)
						.then((res) => {
							debug && console.warn("\t-signUpWithToken().then()")
							//debug && console.warn("\t-onAutoLogin emitted: true")
							// set Session without refresh_token. Will be generated later...
							//this.setSessionTokens({ access_token: request_token, refresh_token: "null" })
							//this.emit("onAutoLogin", true) // => call signInWithToken() in jwtService using new Token
						})
						.catch((error) => {
							debug && console.warn("\t-signUpWithToken().catch()", error)
							//this.setSessionTokens(null)
							debug && console.warn("\t-onNoRequestToken emitted: request_token expired")
							this.emit("onNoRequestToken", "request_token expired")
						})
				}

				//return
			}*/

		const access_token = this.getAccessToken()
		//debug && console.warn("\t-access_token: " + access_token)
		if (!access_token) {
			// User has logged out or never logged in. No access at all. Redirect to Sign-in again
			debug && console.warn("\t-emit onNoAccessToken")
			this.emit("onNoAccessToken")

			return
		}

		let { isValid, data } = this.isAuthTokenValid(access_token)
		//if (this.isAuthTokenValid(access_token)) {
		if (isValid) {
			if (data.info) {
				//FIXME Serve?
				debug && console.warn(data.info)
				const error = []
				error.push({
					type: "email",
					message: "Warning. " + data.info,
				})
				//this.emit("onAutoLogin", false)
			}
			debug && console.warn("\t-isAuthTokenValid(access_token) = true")
			debug && console.warn("\t-emit onAutoLogin: true")
			this.emit("onAutoLogin", true)

			return
		} else {
			debug && console.warn("\t-isAuthTokenValid() = false")
			//this.setSession(null)
			debug && console.warn("\t-emit onAutoLogin: access_token expired")
			//this.emit("onAutoLogout", "access_token expired")
		}

		const refresh_token = this.getRefreshToken()
		//debug && console.warn("\t-refresh_token: " + refresh_token)
		if (!refresh_token) {
			// No refresh token usefull to generate access token...  Redirect to Sign-in again
			debug && console.warn("\t-onNoRefreshToken emitted")
			this.emit("onNoRefreshToken")

			return
		}

		;({ isValid } = this.isAuthTokenValid(refresh_token))
		if (isValid) {
			//FIXME non ritorna boolean ma obj se è valido!
			debug && console.warn("\t-isAuthTokenValid(refresh_token) = true")
			this.signInWithRefreshToken(refresh_token)
				.then((res) => {
					debug && console.warn("\t-signInWithRefreshToken().then()")
					debug && console.warn("\t-onAutoLogin emitted: true")
					this.emit("onAutoLogin", true) // => call signInWithToken() in jwtService using new Token
				})
				.catch((error) => {
					debug && console.warn("\t-signInWithRefreshToken().catch()", error)
					this.setSessionTokens(null)
					debug && console.warn("\t-onAutoLogout emitted: access_token expired")
					this.emit("onAutoLogout", "access_token expired")
				})
		} else {
			debug && console.warn("\t-isAuthTokenValid() = false")
			this.setSessionTokens(null)
			debug && console.warn("\t-onAutoLogout emitted: access_token expired")
			this.emit("onAutoLogout", "access_token expired")
		}
	}

	isLocalhost = () =>
		Boolean(
			window.location.hostname === "localhost" ||
				// [::1] is the IPv6 localhost address.
				window.location.hostname === "[::1]" ||
				// 127.0.0.1/8 is considered localhost for IPv4.
				window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
		)

	getCookie = () => {
		debug && console.log("<JwtService>.getCookie()")
		const cookie = new Cookies()

		const cipherContent = cookie.get(process.env.REACT_APP_COOKIE_NAME)
		if (!cipherContent) {
			debug && console.warn("\t-cookie not found")
			return null
		}
		//debug && console.log("\t-cookie found")

		const bytes = AES.decrypt(cipherContent, process.env.REACT_APP_COOKIE_SECRET)
		const cookieContent = bytes.toString(enc.Utf8)

		const result = {}
		const elements = cookieContent.split(",")
		elements.forEach((element) => {
			const v = element.split("=")
			result[v[0]] = v[1]
		})

		return result
	}

	expireCookie = () => {
		debug && console.log("<JwtService>.expireCookie(" + process.env.REACT_APP_COOKIE_NAME + ")")
		const cookie = new Cookies()
		const isLocalhost = this.isLocalhost()

		const flags = {
			path: "/",
			expires: new Date(Date.now()),
			domain: !isLocalhost && ".crowdservices.it", // SSO!
			secure: !isLocalhost, // solo se HTTPS,
			httpOnly: false, //solo se session cookie in HTTPS (no)
			sameSite: "Strict", // None solo se httpOnly=true oppure Lax o Strict
		}
		cookie.set(process.env.REACT_APP_COOKIE_NAME, null, flags)
	}

	setCookie = (data) => {
		debug && console.log("<JwtService>.setCookie()")
		const cookie = new Cookies()
		const isLocalhost = this.isLocalhost()

		const flags = {
			path: "/",
			maxAge: 60 * 60 * 24 * 120, // last=number of days
			domain: !isLocalhost && ".crowdservices.it", // SSO!
			secure: !isLocalhost, // solo se HTTPS,
			httpOnly: false, //solo se session cookie in HTTPS (no)
			sameSite: "Strict", // None solo se httpOnly=true oppure Lax o Strict
		}

		if (typeof data === "object") {
			let content
			if (data.info) {
				content = `authToken=${data.access_token},refreshToken=${data.refresh_token},warning=${data.info}`
			} else {
				content = `authToken=${data.access_token},refreshToken=${data.refresh_token}`
			}
			const cipherContent = AES.encrypt(content, process.env.REACT_APP_COOKIE_SECRET).toString()
			cookie.set(process.env.REACT_APP_COOKIE_NAME, cipherContent, flags)
		} else {
			cookie.set(process.env.REACT_APP_COOKIE_NAME, data, flags)
		}
	}

	setSessionTokens = (data) => {
		debug && console.log("<JwtService>.setSessionTokens()")
		if (data && data.access_token && data.refresh_token) {
			axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`
			this.setCookie(data)
		} else {
			delete axios.defaults.headers.common.Authorization
			//this.removeCookie()
			this.expireCookie()
			debug && console.warn("\t-expire cookie and delete jwt_access_token from Bearer")
		}
	}

	/*
	REM_setRefreshToken = (refresh_token) => {
		debug && console.log("<JwtService>.setRefreshToken()")
		if (refresh_token) {
			localStorage.setItem("jwt_refresh_token", refresh_token)
			debug && console.warn("\t-jwt_refresh_token set to localStorage ")
		}
	}*/

	/*
	REM_setSession = (access_token) => {
		debug && console.log("<JwtService>.setSession()")
		if (access_token) {
			//localStorage.setItem("jwt_access_token", access_token)
			//debug && console.warn("\t-jwt_access_token set to localStorage ")
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
			debug && console.warn("\t-axios Authorization set to Bearer")
		} else {
			//localStorage.removeItem("jwt_access_token")
			delete axios.defaults.headers.common.Authorization
			debug && console.warn("\t-jwt_access_token removed from Bearer")
		}
	}*/

	signInWithRefreshToken = (refresh_token) => {
		debug && console.log("<JwtService>.signInWithRefreshToken() Promise()")
		return new Promise((resolve, reject) => {
			axios
				.post(jwtServiceConfig.refreshToken, {
					data: {
						refresh_token,
					},
				})
				.then((response) => {
					debug && console.log("<JwtService>.signInWithRefreshToken() -> then():  ", response)
					if (response.data.user) {
						this.setSessionTokens(response.data)
						resolve(response.data.user)
					} else {
						reject(new Error("Failed to refresh token."))
					}
				})
				.catch((error) => {
					reject(new Error("Failed to login with refresh_token."))
				})
		})
	}

	signInWithToken = () => {
		debug && console.log("<JwtService>.signInWithToken() Promise()")
		return new Promise((resolve, reject) => {
			axios
				//.get(jwtServiceConfig.accessTokenMock, {
				.post(jwtServiceConfig.accessToken, {
					data: {
						access_token: this.getAccessToken(),
					},
				})
				.then((response) => {
					debug && console.debug("<JwtService>.signInWithToken() -> then(): ", response)
					if (response.data.user) {
						this.setSessionTokens(response.data)
						resolve(response.data.user)
					} else {
						this.logout()
						reject(new Error("Failed to login with token."))
					}
				})
				.catch((error) => {
					this.logout()
					reject(new Error("Failed to login with token. " + error))
				})
		})
	}

	getErrorCode = (code) => {
		debug && console.log("<JwtService>.getErrorCode() Promise()")
		return new Promise((resolve, reject) => {
			try {
				const bytes = AES.decrypt(code, process.env.REACT_APP_COOKIE_SECRET)
				const decripted = bytes.toString(enc.Utf8)
				resolve(decripted)
			} catch (err) {
				debug && console.log("<JwtService>.getErrorCode() -> catch: ", err.message)
				const error = []
				error.push({
					type: "social",
					message: "Something went wrong. Please try later.",
					//message: err.message, //do not show real error to the user
				})
				reject(error)
			}
		})
	}

	signInWithEmailAndPassword = (email, password) => {
		debug && console.log("<JwtService>.signInWithEmailAndPassword(" + email + ", " + password + ") Promise()")
		return new Promise((resolve, reject) => {
			debug && console.log("Calling api route: " + jwtServiceConfig.signIn)
			axios
				//.get(jwtServiceConfig.signInMock, {
				.post(jwtServiceConfig.signIn, {
					data: {
						email,
						password,
					},
				})
				.then((response) => {
					debug && console.log("<JwtService>.signInWithEmailAndPassword() -> then(): ", response)
					if (response.data.user) {
						this.setSessionTokens(response.data)
						resolve(response.data.user)
						this.emit("onLogin", response.data.user)
					} else {
						debug && console.log(response.data.error)
						reject(response.data.error)
					}
				})
				.catch((err) => {
					debug && console.log("<JwtService>.signInWithEmailAndPassword() -> catch: ", err.message)
					const error = []
					error.push({
						type: "email",
						message: "Something went wrong. Please try later.",
						//message: err.message, //do not show real error to the user
					})
					reject(error)
				})
		})
	}

	signUpWithToken = (request_token) => {
		debug && console.log("<JwtService>.signUpWithToken(request_token) Promise()")
		return new Promise((resolve, reject) => {
			debug && console.log("Calling api route: " + jwtServiceConfig.signUp)
			axios
				.post(jwtServiceConfig.signUp, {
					data: {
						access_token: request_token,
					},
				})
				.then((response) => {
					debug && console.log("<JwtService>.signUpWithToken() -> then(): ", response)
					if (response.data.user) {
						debug && console.log(response.data.user)
						//this.setSessionTokens(response.data)
						debug && console.log("resolve")
						resolve(response.data.user)
						//this.emit("onLogin", response.data.user)
					} else {
						debug && console.log("reject")
						reject(response.data.error)
					}
				})
				.catch((err) => {
					debug && console.log("<JwtService>.signUpWithToken() -> catch: ", err.message)
					const error = []
					error.push({
						type: "social",
						message: err.message + ". Test signUpWithToken.",
					})
					reject(error)
				})
		})
	}

	createUser = (data) => {
		return new Promise((resolve, reject) => {
			axios.post(jwtServiceConfig.signUp, data).then((response) => {
				if (response.data.user) {
					this.setSessionTokens(response.data)
					resolve(response.data.user)
					this.emit("onLogin", response.data.user)
				} else {
					reject(response.data.error)
				}
			})
		})
	}

	updateUserData = (user) => {
		debug && console.log("<JwtService> updateUserData(user) Promise()", { user })
		return new Promise((resolve, reject) => {
			axios
				.patch(
					jwtServiceConfig.updateUserData, // updateUserMock
					{
						data: user.data, //.settings
					},
					{ bind: { id: user.id } }
				)
				.then((response) => {
					debug && console.log("<JwtService>.updateUserData() -> then(): ", response)
					resolve()
				})
				.catch((err) => {
					debug && console.log("<JwtService>.updateUserData() -> catch", err)
					reject(new Error(err))
				})
		})
	}

	logout = () => {
		debug && console.log("<JwtService>.logout()")
		this.setSessionTokens(null)
		this.emit("onLogout", "Logged out")
	}

	isAuthTokenValid = (token) => {
		debug && console.log("<JwtService>.isAuthTokenValid()")
		if (!token) return { isValid: false, data: null }
		try {
			const data = jwtDecode(token)
			//debug && console.debug("\t-data: ", data)
			const currentTime = Date.now() / 1000
			debug && console.debug("\t-expire in: " + Number.parseFloat(data.exp - currentTime).toFixed(0) + "(s)")
			if (data.exp < currentTime) {
				debug && console.debug("\t-token expired")
				return { isValid: false, data }
			}

			return { isValid: true, data }
		} catch (err) {
			return { isValid: false, data: null }
		}
	}

	/** getRequestToken
	 *
	 * @returns
	 */
	getRequestToken = () => {
		debug && console.log("<JwtService>.getRequestToken()")
		const query = new URLSearchParams(window.location.search)

		const access_token = query.get("code")
		return access_token
	}

	/**
		getAccessToken = () => {
	 	debug && console.log("<JwtService>.getAccessToken()")
	 	return window.localStorage.getItem("jwt_access_token")
	} */
	getAccessToken = () => {
		debug && console.log("<JwtService>.getAccessToken()")
		// try getting authtoken from cookie if exists
		const cookie = this.getCookie()
		if (!cookie) return null
		return cookie.authToken
	}

	/** 
		getRefreshToken = () => {
	 	debug && console.log("<JwtService>.getRefreshToken()")
	 	return window.localStorage.getItem("jwt_refresh_token")
	 } */
	getRefreshToken = () => {
		debug && console.log("<JwtService>.getRefreshToken()")
		// try getting refreshToken from cookie is exists
		const cookie = this.getCookie()
		if (!cookie) return null
		return cookie.refreshToken
	}

	// DEBUG FIXME REMOVE
	testcall = () => {
		debug && console.log("<JwtService>.testcall() -> get /api/auth/test")
		//const AUTH_TOKEN = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MGU0NzYwNzcxZDA0NjNmMWExOGFhNzMiLCJlbWFpbCI6ImNhbm9uLmV1cm9wZUBtYWlsLmNvbSIsInBlcm1pc3Npb25MZXZlbCI6NCwicHJvdmlkZXIiOiJlbWFpbCIsIm5hbWUiOiJDYW5vbiBFdXJvcGUiLCJyZWZyZXNoS2V5IjoiTFBISEJCWm0yaTgxWkhIS0VlS2JQZz09IiwiaWF0IjoxNjI1NjU3MDQ1fQ.KgXWIsjSwyznCl_WOAKaIF2zj4hxGKgvvTTe5kRXVPs"

		//axios.defaults.headers.common["Authorization"] = AUTH_TOKEN
		//return axios.get('/api/auth/test')
		axios
			//.get('/api/auth/test')
			.get("api/v1/users/62696c6a50bea7120d77b982")
			.then((response) => {
				// `data` is the response that was provided by the server
				debug && console.log("<JwtService>.testcall() -> then(): ", response)
			})
			.catch((err) => {
				//handle the error
				debug && console.log("<JwtService>.testcall() -> catch", err)
			})
	}
}

const instance = new JwtService()

export default instance
