import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import history from "@history"
import ContainerModel from "../model/ContainerModel"
import SectionModel from "../model/SectionModel"
import ItemModel from "../model/ItemModel"

export const getItem = createAsyncThunk("itemsApp/item/getItem", async (id, { dispatch, getState }) => {
	try {
		const response = await axios.get(`/api/items/${id}`)

		const data = await response.data

		return data
	} catch (error) {
		history.push({ pathname: `/apps/items` })

		return null
	}
})

export const addItem = createAsyncThunk("itemsApp/items/addItem", async (item, { dispatch, getState }) => {
	const response = await axios.post("/api/items", item)

	const data = await response.data

	return data
})

export const updateItem = createAsyncThunk("itemsApp/items/updateItem", async (item, { dispatch, getState }) => {
	const response = await axios.put(`/api/items/${item.id}`, item)

	const data = await response.data

	return data
})

export const removeItem = createAsyncThunk("itemsApp/items/removeItem", async (id, { dispatch, getState }) => {
	const response = await axios.delete(`/api/items/${id}`)

	await response.data

	return id
})

export const selectItem = ({ itemsApp }) => itemsApp.item

const itemSlice = createSlice({
	name: "itemsApp/item",
	initialState: null,
	reducers: {
		newItem: (state, action) => {
			const type = action.payload
			if (type === "container") {
				return ContainerModel()
			}
			if (type === "section") {
				return SectionModel()
			}
			if (type === "item") {
				return ItemModel()
			}
			return null
		},
		resetItem: () => null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getItem.pending, () => null)
			.addCase(getItem.fulfilled, (state, action) => action.payload)
			.addCase(updateItem.fulfilled, (state, action) => action.payload)
			.addCase(removeItem.fulfilled, () => null)
	},
	/*extraReducers: {
		[getItem.pending]: (state, action) => null,
		[getItem.fulfilled]: (state, action) => action.payload,
		[updateItem.fulfilled]: (state, action) => action.payload,
		[removeItem.fulfilled]: (state, action) => null,
	},*/
})

export const { resetItem, newItem } = itemSlice.actions

export default itemSlice.reducer
