import * as React from "react"
import FuseSplashScreen from "@fuse/core/FuseSplashScreen"
import MobileDetect from "mobile-detect"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
//import { showMessage } from "app/store/fuse/messageSlice"
import { logoutUser, setUser } from "app/store/userSlice"
import { getNavigation } from "app/store/fuse/navigationSlice"
import jwtService from "./services/jwtService"

const debug = process.env.NODE_ENV === "development" && false

// mobile detection
const md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.mobile()

const AuthContext = React.createContext()

function AuthProvider({ children }) {
	//debug && console.log("<AuthProvider> Constructor()")
	const [isAuthenticated, setIsAuthenticated] = useState(undefined)
	const [waitAuthCheck, setWaitAuthCheck] = useState(true)
	const [showSplash, setShowSplash] = useState(isMobile) // Ritarda lo splash solo su mobile
	const dispatch = useDispatch()

	useEffect(() => {
		debug && console.log("<AuthProvider> -useEffect()")

		jwtService.on("onAutoLogin", () => {
			debug && console.log("<AuthProvider> onAutoLogin event called")
			//dispatch(showMessage({ message: "Signing in with JWT" }))
			/**
			 * Sign in and retrieve user data with stored token */
			jwtService
				.signInWithToken()
				.then((user) => {
					success(user, "Signed in with JWT")
				})
				.catch((error) => {
					pass(error.message)
				})
		})

		jwtService.on("onLogin", (user) => {
			debug && console.log("<AuthProvider> onLogin event called")
			success(user, "Signed in")
		})

		jwtService.on("onLogout", (message) => {
			debug && console.log("<AuthProvider> onLogout event called", { message })
			pass() //"Signed out"

			dispatch(logoutUser())
		})

		jwtService.on("onAutoLogout", (message) => {
			debug && console.log("<AuthProvider> onAutoLogout event called", { message })
			pass(message)

			dispatch(logoutUser())
		})

		jwtService.on("onNoAccessToken", (message) => {
			debug && console.log("<AuthProvider> onNoAccessToken event called", { message })
			//dispatch(showMessage(message))

			pass(message)
		})

		jwtService.on("onNoRefreshToken", (message) => {
			debug && console.log("<AuthProvider> onNoRefreshToken event called", { message })
			//dispatch(showMessage(message))

			pass(message)
		})

		jwtService.init()

		function success(user, message) {
			debug && console.log("<AuthProvider> %c-success(): ", "color:green;", { user }, { message })
			//console.warn("success", user, message)
			/*if (message) {
				dispatch(showMessage({ message }))s
			}*/
			Promise.all([
				dispatch(getNavigation()), // added *FG*
				dispatch(setUser(user)),
				// You can receive data in here before app initialization
			]).then((values) => {
				setWaitAuthCheck(false)
				setIsAuthenticated(true)

				// Se è mobile, ritarda la rimozione dello Splash Screen di 2.5 secondi
				if (isMobile) {
					setTimeout(() => {
						setShowSplash(false)
					}, 1500)
				} else {
					setShowSplash(false)
				}
			})
		}

		function pass(message) {
			debug && console.log("<AuthProvider> pass(): ", { message })
			/*if (message) {
				dispatch(showMessage({ message }))
			}*/

			setWaitAuthCheck(false)
			setIsAuthenticated(false)

			//GPT
			if (isMobile) {
				setTimeout(() => {
					setShowSplash(false)
				}, 1500)
			} else {
				setShowSplash(false)
			}
		}
	}, [dispatch])

	return waitAuthCheck || showSplash ? <FuseSplashScreen /> : <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider")
	}
	return context
}

export { AuthProvider, useAuth }
