import { lazy } from "react"
import authRoles from "../../../auth/authRoles"

const MaintenancePage = lazy(() => import("./MaintenancePage"))

const maintenancePageConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.guest, // ['admin','staff']
	routes: [
		{
			path: "pages/maintenance",
			element: <MaintenancePage />,
		},
	],
}

export default maintenancePageConfig
