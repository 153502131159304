import { combineReducers } from "@reduxjs/toolkit"
import tags from "./tagsSlice"
import tickets from "./ticketsSlice"
import countries from "./countriesSlice"
import ticket from "./ticketSlice"

const reducer = combineReducers({
	tags,
	countries,
	tickets,
	ticket,
})

export default reducer
