import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
//import axios from "@fuse/axios"
import history from "@history"
import DocumentModel from "../model/DocumentModel"
const debug = process.env.NODE_ENV === "development" && false

export const getDocument = createAsyncThunk("dmsApp/documents/getDocument", async (id, { dispatch, getState }) => {
	debug && console.log("getDocument(dmsApp/documents/getDocument) -id:", id)
	try {
		const response = await axios.get(`/api/v1/dms/documents/${id}`)

		const data = await response.data

		//console.log("getDocument:", data)
		//check for not consistent values coming from API... FG
		if (!data.account) {
			data.account = {
				"id": "",
				"userName": "",
				"email": "",
				"notes": "",
			}
		} else if (!data.account.company) {
			data.account.company = ""
		}
		if (!data.description) data.description = ""

		return data
	} catch (error) {
		history.push({ pathname: `/apps/dms` })

		return null
	}
})

export const addDocument = createAsyncThunk("dmsApp/documents/addDocument", async (document, { dispatch, getState }) => {
	const response = await axios.post("/api/v1/dms/documents", document)

	const data = await response.data

	return data
})

export const updateDocument = createAsyncThunk("dmsApp/documents/updateDocument", async (document, { dispatch, getState }) => {
	const response = await axios.put(`/api/v1/dms/documents/${document.id}`, document)

	const data = await response.data

	return data
})

export const removeDocument = createAsyncThunk("dmsApp/documents/removeDocument", async (id, { dispatch, getState }) => {
	const response = await axios.delete(`/api/v1/dms/documents/${id}`)

	await response.data

	return id
})

export const selectDocument = ({ dmsApp }) => dmsApp.document

const documentSlice = createSlice({
	name: "dmsApp/document",
	initialState: null,
	reducers: {
		newDocument: (state, action) => DocumentModel(),
		//setDocument: (satet, action) => action.payload,
		resetDocument: () => null,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDocument.pending, (state, action) => null)
			.addCase(getDocument.fulfilled, (state, action) => action.payload)
			/*.addCase(getDocument.fulfilled, (state, action) => {
				const { id, ...data } = action.payload
				state.documents[id] = data // Store the document by its ID
				//state.status = 'succeeded';
			})*/
			.addCase(updateDocument.fulfilled, (state, action) => action.payload)
			.addCase(removeDocument.fulfilled, (state, action) => null)
	},
})

//export const selectDocumentById = (state, documentId) => state.documents.documents[documentId]

export const { resetDocument, setDocument, newDocument } = documentSlice.actions // setDocument non c'è!

export default documentSlice.reducer
