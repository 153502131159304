import { supportsPassiveEvents } from "detect-it"
import { passiveSupport } from "passive-events-support/src/utils"

const defaultEvents = ["touchstart", "touchmove", "touchcenter", "touchend", "touchleave", "wheel", "mousewheel"]

// Se il browser supporta gli eventi passivi, richiama la funzione passiveSupport
if (supportsPassiveEvents) {
	// Specifica l'elenco di eventi per cui vuoi applicare il patch
	// L'elenco qui include 'touchstart', 'touchmove', 'wheel' e 'mousewheel'
	passiveSupport({
		events: defaultEvents,
	})
} else {
	// In alternativa, se vuoi avere un fallback manuale (ma di solito non serve)
	const originalAddEventListener = EventTarget.prototype.addEventListener
	EventTarget.prototype.addEventListener = function (type, listener, options) {
		let modifiedOptions = options
		if (defaultEvents.includes(type) && (options === undefined || options === false || (typeof options === "object" && options.passive === undefined))) {
			modifiedOptions = Object.assign({}, options, { passive: true })
		}
		return originalAddEventListener.call(this, type, listener, modifiedOptions)
	}
}
