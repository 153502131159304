import i18next from "i18next"
import { lazy } from "react"
import { Navigate } from "react-router-dom"
import authRoles from "src/app/auth/authRoles"

import it from "./i18n/it"
import en from "./i18n/en"

i18next.addResourceBundle("en", "e-logisticApp", en)
i18next.addResourceBundle("it", "e-logisticApp", it)

const Manifest = lazy(() => import("./manifest/Manifest"))
const Manifests = lazy(() => import("./manifests/Manifests"))
const Project = lazy(() => import("./project/Project"))
const Projects = lazy(() => import("./projects/Projects"))
const Delivery = lazy(() => import("./delivery/Delivery"))
const Deliveries = lazy(() => import("./deliveries/Deliveries"))
// to remove
const Product = lazy(() => import("./product/Product"))
const Products = lazy(() => import("./products/Products"))
const Order = lazy(() => import("./order/Order"))
const Orders = lazy(() => import("./orders/Orders"))

const ELogisticAppConfig = {
	settings: {
		layout: {},
	},
	routes: [
		{
			path: "apps/e-logistic/manifests",
			auth: authRoles.user,
			element: <Manifests />,
		},
		{
			path: "apps/e-logistic/manifests/:manifestId/*",
			auth: authRoles.user,
			element: <Manifest />,
		},
		{
			path: "apps/e-logistic/projects",
			auth: authRoles.user,
			element: <Projects />,
		},
		{
			path: "apps/e-logistic/projects/:projectId/*",
			auth: authRoles.user,
			element: <Project />,
		},

		{
			path: "apps/e-logistic/deliveries",
			auth: authRoles.user,
			element: <Deliveries />,
		},
		{
			path: "apps/e-logistic/deliveries/:deliveryId/*",
			auth: authRoles.user,
			element: <Delivery />,
		},

		{
			path: "apps/e-logistic/products",
			auth: authRoles.user,
			element: <Products />,
		},
		{
			path: "apps/e-logistic/products/:productId/*",
			auth: authRoles.user,
			element: <Product />,
		},
		{
			path: "apps/e-logistic/orders",
			auth: authRoles.user,
			element: <Orders />,
		},
		{
			path: "apps/e-logistic/orders/:orderId",
			auth: authRoles.user,
			element: <Order />,
		},
		{
			path: "apps/e-logistic",
			element: <Navigate to="products" />,
		},
	],
}

export default ELogisticAppConfig
