import { motion } from "framer-motion"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { addDocument, getDocument, newDocument, removeDocument, selectDocument, updateDocument } from "../store/documentSlice"
import { selectCountries } from "../store/countriesSlice"
import { selectTags } from "../store/tagsSlice"
import { selectCategories } from "../store/categoriesSlice"
import { Controller, useForm } from "react-hook-form"
import { FormControl, Avatar, Select, MenuItem, InputLabel, TextField, InputAdornment, Button, Checkbox, IconButton, FormHelperText } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import * as yup from "yup"
import _ from "@lodash"
import Box from "@mui/system/Box"
import Chip from "@mui/material/Chip"
import FuseLoading from "@fuse/core/FuseLoading"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
//import DocumentEmailSelector from "./email-selector/DocumentEmailSelector"
//import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector"
import DocumentAttachments from "./DocumentAttachments"
import Pdf from "src/13187001.pdf"

const debug = false

import { save, close } from "../DmsAppButtons"

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup.string().required("You must enter a name"),
	CategoryId: yup.string().required("Category is required"),
})

function DocumentFormFull(props) {
	console.log("DocumentFormFull")
	const document = useSelector(selectDocument)
	const countries = useSelector(selectCountries)
	const tags = useSelector(selectTags)
	const categories = useSelector(selectCategories)

	const routeParams = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [age, setAge] = useState("10")

	const { control, register, watch, reset, handleSubmit, getFieldState, formState, getValues, setValue } = useForm({
		mode: "onChange",
		defaultValues: {},
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState

	const form = watch()

	useEffect(() => {
		//console.log("1.useEffect()", document)
		if (routeParams.id === "new") {
			dispatch(newDocument())
		} else {
			dispatch(getDocument(routeParams.id))
		}
	}, [dispatch, routeParams])

	useEffect(() => {
		//console.log("2.useEffect()", { document })
		if (document) {
			reset({ ...document })
			//reset({ ...document, email: document.account ? document.account.email : "" })
			//reset({ ...document, category: document.category.id, tags: document.tags.map((tag) => tag.id) })
			//reset({ ...document, category: document.category.id || "", email: document.account.email || "" })
			//reset({ ...document, category: document.category ? document.category.id : "" })
		}
		//console.log({ document })
	}, [document, reset])

	function getCountryByIso(iso) {
		return countries.find((country) => country.iso === iso)
	}

	function onSubmit(data) {
		//console.log("onSubmit:", { data })
		if (routeParams.id === "new") {
			dispatch(addDocument(data)).then(({ payload }) => {
				//navigate(`/apps/documents/${payload.id}`)
				navigate(save(payload.id))
			})
		} else {
			dispatch(updateDocument(data))
		}
	}

	function handleRemoveDocument() {
		dispatch(removeDocument(document.id)).then(() => {
			//navigate("/apps/documents")
			navigate(close())
		})
	}

	if (_.isEmpty(form) || !document) {
		return <FuseLoading />
	}

	{
		/*if (_.isEmpty(form) || !document) {
		return (
			<motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.5 } }} className="flex flex-1 items-center justify-center h-full">
				<FuseLoading />
			</motion.div>
		)
	}*/
	}

	const handleChange = (event) => {
		setAge(event.target.value)
		//setValue("category", event.target.value) from gpt. not needed!?!
	}

	const options = [
		{ label: "Option 1", value: "option1" },
		{ label: "Option 2", value: "option2" },
	]

	return (
		<motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }} className="flex flex-col flex-auto w-full max-h-full">
			<Box
				className="relative w-full h-64 px-32 sm:px-48 flex items-center justify-between"
				sx={{
					backgroundColor: "background.default",
				}}>
				<Chip color="primary" label={_.find(categories, { id: document.CategoryId }).name || ""} />
			</Box>

			<div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Name"
							placeholder="Name"
							id="name"
							error={!!errors.name}
							helperText={errors?.name?.message}
							variant="outlined"
							required
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="CategoryId"
					render={({ field: { onChange, value } }) => (
						<FormControl fullWidth className="mt-32" error={!!errors.category}>
							<InputLabel id="category-label">Category</InputLabel>
							<Select labelId="category-label" id="CategoryId" label="category" value={value || ""} onChange={(event) => onChange(event.target.value)}>
								{/* console.log({ event.target.value })}
								{/* onChange={(event, newValue) => newValue} */}
								{/* onChange={handleChange} */}
								{/* value={value ? value.map((id) => _.find(tags, id)) : []} */}
								{categories.map((option) => (
									<MenuItem key={option.name} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
							{errors.category && <FormHelperText>{errors.category.message}</FormHelperText>}
						</FormControl>
					)}
				/>

				<Controller
					control={control}
					name="age"
					render={({ field: { onChange, value } }) => (
						<FormControl fullWidth className="mt-32">
							<InputLabel id="demo-simple-select-label">Aging</InputLabel>
							<Select labelId="demo-simple-select-label" id="age" value={age} label="Age" onChange={handleChange}>
								<MenuItem value={10}>Ten</MenuItem>
								<MenuItem value={20}>Twenty</MenuItem>
								<MenuItem value={30}>Thirty</MenuItem>
							</Select>
						</FormControl>
					)}
				/>

				<Controller
					control={control}
					name="tags"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple
							id="tags"
							className="mt-32"
							options={tags}
							disableCloseOnSelect
							getOptionLabel={(option) => option.name}
							renderOption={(_props, option, { selected }) => (
								<li {..._props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.name}
								</li>
							)}
							//value={value ? value.map((id) => _.find(tags, id)) : []}
							value={value ? value.map((id) => _.find(tags, { id })) : []}
							//onChange={(event, newValue) => onChange(newValue)}
							onChange={(event, newValue) => onChange(newValue.map((item) => item.id))}
							fullWidth
							renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
						/>
					)}
				/>

				{/* 
				<Controller
					control={control}
					name="title"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Title"
							placeholder="Job title"
							id="title"
							error={!!errors.title}
							helperText={errors?.title?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:briefcase</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
*/}
				{/* 
				<Controller
					control={control}
					name="account.company"
					render={({ field }) => (
						<TextField
							{...field}
							className="mt-32"
							label="Company"
							placeholder="Company"
							id="company"
							error={!!errors.company}
							helperText={errors?.company?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:office-building</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
*/}
				<Controller
					control={control}
					name="email"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Email"
							placeholder="Email"
							id="email"
							error={!!errors.email}
							helperText={errors?.email?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				<p>{getFieldState("email").isDirty && "dirty"}</p>
				<p>{getFieldState("email").isTouched && "touched"}</p>
				<button type="button" onClick={() => console.log(getFieldState("email"))}>
					field state
				</button>

				{/* 
				<Controller
					control={control}
					name="address"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Address"
							placeholder="Address"
							id="address"
							error={!!errors.address}
							helperText={errors?.address?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:location-marker</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				*/}

				<Controller
					control={control}
					name="expiryDate"
					render={({ field: { value, onChange } }) => (
						<DateTimePicker
							value={new Date(value)}
							onChange={onChange}
							className="mt-32 mb-16 w-full"
							clearable
							slotProps={{
								textField: {
									id: "expiryDate",
									label: "Expiry Date",
									InputLabelProps: {
										shrink: true,
									},
									fullWidth: true,
									variant: "outlined",
								},
								actionBar: {
									actions: ["clear", "today"],
								},
							}}
							slots={{
								openPickerIcon: () => <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>,
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="description"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Description"
							placeholder="Description"
							id="description"
							error={!!errors.description}
							helperText={errors?.description?.message}
							variant="outlined"
							fullWidth
							multiline
							minRows={5}
							maxRows={10}
							InputProps={{
								className: "max-h-min h-min items-start",
								startAdornment: (
									<InputAdornment className="mt-16" position="start">
										<FuseSvgIcon size={20}>heroicons-solid:menu-alt-2</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<DocumentAttachments className="mt-32" />

				<a href={Pdf} rel="noopener noreferrer" target="_blank">
					<button variant="contained" color="secondary" label="Resume">
						PDF
					</button>
				</a>
			</div>

			<Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t" sx={{ backgroundColor: "background.default" }}>
				{routeParams.id !== "new" && (
					<Button color="error" onClick={handleRemoveDocument}>
						Delete
					</Button>
				)}
				<Button className="ml-auto" component={NavLinkAdapter} to={-1}>
					Cancel
				</Button>
				<Button className="ml-8" variant="contained" color="secondary" disabled={_.isEmpty(dirtyFields) || !isValid} onClick={handleSubmit(onSubmit)}>
					Save
				</Button>
			</Box>
		</motion.div>
	)
}

export default DocumentFormFull
