import _ from "@lodash"

const DocumentModel = (data) =>
	_.defaults(data || {}, {
		background: null,
		account: {
			"id": "",
			"userName": "",
			"email": "",
			"notes": "",
		},
		category: {},
		classification: {},
		counterpart: {},
		description: "",
		direction: {},
		filePath: "",
		frequency: {},
		mandatory: {},
		name: "",
		tags: [],
		userName: "",
		expiryDate: "",
	})

export default DocumentModel
