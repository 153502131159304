/**
 * Authorization Roles
 *  =>  role: ['who can see']
 */
const authRoles = {
	admin: ["admin"],
	supervisor: ["admin", "supervisor"],
	staff: ["admin", "supervisor", "staff"],
	user: ["admin", "supervisor", "staff", "user"],
	vendor: ["admin", "supervisor", "staff", "vendor"],
	guest: ["admin", "supervisor", "staff", "user", "guest"],
	prospect: ["admin", "prospect"],
	canon: ["admin", "supervisor", "staff", "user", "canon"],
	onlyGuest: [],
}

export default authRoles
