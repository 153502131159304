import { lazy } from "react"
import TicketView from "./ticket/TicketView"
import TicketForm from "./ticket/TicketForm"
import TicketFullForm from "./ticket/TicketFullForm"

const TicketsApp = lazy(() => import("./TicketsApp"))

const TicketsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: "apps/tickets",
			element: <TicketsApp />,
			children: [
				{
					path: ":id",
					element: <TicketView />,
				},
				{
					path: ":id/edit",
					element: <TicketForm />,
				},
			],
		},
		{
			path: "apps/tickets/:id/full",
			element: <TicketFullForm />,
			//icon: "heroicons-outline:ticket",
		},
	],
}

export default TicketsAppConfig
