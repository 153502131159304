/**
 * Open document in read mode
 * @param {*} DocumentId
 * @returns
 */
export const quickview = (DocumentId) => {
	return `/apps/dms/${DocumentId}`
}

/**
 * Open document in edit mode
 * @returns
 */
export const quickedit = () => {
	return `edit`
}

export const fulledit = () => {
	return `full`
}

/**
 * Create a new document in quickmode
 * @returns
 */
export const quicknew = () => {
	return `new/edit`
}

/**
 * Create a new document in fullmode
 * @returns
 */
export const fullnew = () => {
	return `new/full`
}

/**
 * Save document
 * @param {*} payloadId
 * @returns
 */
export const save = (payloadId) => {
	return `/apps/dms/${payloadId}`
}

/**
 * Back to document list
 * @returns
 */
export const close = () => {
	return "/apps/dms"
}
