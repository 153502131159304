const debug = process.env.NODE_ENV === "development" && true

export function register(config) {
	if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
		// The URL constructor is available in all browsers that support SW.
		if (publicUrl.origin !== window.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return
		}

		self.addEventListener("install", (event) => {
			self.skipWaiting() // Forza il nuovo SW ad attivarsi subito
		})

		self.addEventListener("activate", (event) => {
			event.waitUntil(
				caches.keys().then((cacheNames) => {
					return Promise.all(
						cacheNames.map((cache) => {
							if (cache !== "static-resources" && cache !== "pages-cache") {
								return caches.delete(cache) // Elimina cache obsolete
							}
						})
					)
				})
			)
		})

		window.addEventListener("load", () => {
			const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

			if (isLocalhost()) {
				// Verifica se esiste già un service worker valido in localhost
				checkValidServiceWorker(swUrl, config)

				// Add some additional logging to localhost, pointing developers to the
				// service worker/PWA documentation.
				navigator.serviceWorker.ready.then(() => {
					debug && console.log("Service worker is active.")
				})
			} else {
				// In produzione. Registra normalmente il service worker
				registerValidSW(swUrl, config)
			}
		})
	}
}

function registerValidSW(swUrl, config) {
	navigator.serviceWorker
		.register(swUrl)
		.then((registration) => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing
				if (!installingWorker) {
					return
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === "installed") {
						if (navigator.serviceWorker.controller) {
							// Nuovo contenuto disponibile.
							debug && console.log("Nuova versione disponibile.")
							// Se è stato fornito il callback onUpdate, chiamalo.
							if (config && config.onUpdate) {
								config.onUpdate(registration)
							}
						} else {
							// Contenuto memorizzato nella cache per l'uso offline.
							debug && console.log("Contenuto memorizzato per l'uso offline.")
							if (config && config.onSuccess) {
								config.onSuccess(registration)
							}
						}
					}
				}
			}
		})
		.catch((error) => {
			console.error("Error during service worker registration:", error)
		})
}

function checkValidServiceWorker(swUrl, config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then((response) => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get("content-type")
			if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then((registration) => {
					registration.unregister().then(() => {
						window.location.reload()
					})
				})
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config)
			}
		})
		.catch(() => {
			debug && console.log("No internet connection found. App is running in offline mode.")
		})
}

function isLocalhost() {
	return Boolean(window.location.hostname === "localhost" || window.location.hostname === "[::1]" || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/))
}

export function unregister() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready
			.then((registration) => {
				registration.unregister()
			})
			.catch((error) => {
				console.error(error.message)
			})
	}
}
