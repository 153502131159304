import FusePageCarded from "@fuse/core/FusePageCarded"
import FusePageSimple from "@fuse/core/FusePageSimple"
import Button from "@mui/material/Button"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import FuseLoading from "@fuse/core/FuseLoading"
import withReducer from "app/store/withReducer"
import reducer from "../store"
import _ from "@lodash"
import * as yup from "yup"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import { FormProvider, Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import Box from "@mui/system/Box"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import Checkbox from "@mui/material/Checkbox/Checkbox"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { addTicket, getTicket, newTicket, removeTicket, selectTicket, updateTicket } from "../store/ticketSlice"
import { selectCountries, getCountries } from "../store/countriesSlice"
import { selectTags, getTags } from "../store/tagsSlice"
import TicketEmailSelector from "./email-selector/TicketEmailSelector"
import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector"
const debug = false
let rendering = -1
import { close } from "../TicketsAppButtons"

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup.string().required("You must enter a name"),
})

function TicketFullForm(props) {
	//debug && console.log("<TicketFullForm>".padEnd(21) + "%cConstructor() -rendering:", "color: orange", ++rendering, { props })

	const ticket = useSelector(selectTicket)
	debug && console.log("<TicketFullForm> ticket:", { ticket })

	const countries = useSelector(selectCountries)
	debug && console.log("<TicketFullForm> countries:", { countries })

	const tags = useSelector(selectTags)
	debug && console.log("<TicketFullForm> tags:", { tags })

	const routeParams = useParams()
	debug && console.log("<TicketFullForm> routeParams:", { routeParams })

	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState
	const form = watch()

	useEffect(() => {
		debug && console.log("<TicketFullForm> %cuseEffect(1) -rendering:", "color: yellow", rendering, routeParams.id)
		countries.length == 0 && dispatch(getCountries())
		tags.length == 0 && dispatch(getTags())
		if (routeParams.id === "new") {
			dispatch(newTicket())
		} else {
			debug && console.log("<TicketFullForm> useEffect()", routeParams.id)
			!ticket && dispatch(getTicket(routeParams.id))
		}
	}, [dispatch, routeParams])

	useEffect(() => {
		debug && console.log("<TicketFullForm> %cuseEffect(2) -rendering:", "color: yellow", rendering)
		reset({ ...ticket })
	}, [ticket, reset])

	function getCountryByIso(iso) {
		return countries.find((country) => country.iso === iso)
	}

	function onSubmit(data) {
		if (routeParams.id === "new") {
			dispatch(addTicket(data)).then(({ payload }) => {
				navigate(`/apps/tickets/${payload.id}`)
			})
		} else {
			dispatch(updateTicket(data))
		}
	}

	function handleRemoveTicket() {
		dispatch(removeTicket(ticket.id)).then(() => {
			navigate("/apps/tickets")
		})
	}

	if (_.isEmpty(form) || !ticket) {
		return <FuseLoading />
	}

	return (
		<FormProvider>
			<FusePageCarded
				//header={<ProductHeader />}
				content={
					<>
						<Box
							className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
							sx={{
								backgroundColor: "background.default",
							}}>
							{/* <IconButton className="absolute top-0 right-0 my-16 mx-32 z-10" sx={{ color: "white" }} component={NavLinkAdapter} to="/apps/tickets" size="large"> */}
							<IconButton className="absolute top-0 right-0 my-16 mx-32 z-10" sx={{ color: "white" }} component={NavLinkAdapter} to={close()} size="large">
								<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon> Close
							</IconButton>
							{ticket.background && <img className="absolute inset-0 object-cover w-full h-full" src={ticket.background} alt="user background" />}
						</Box>

						<div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
							<div className="w-full">
								<div className="flex flex-auto items-end -mt-64">
									<Controller
										control={control}
										name="avatar"
										render={({ field: { onChange, value } }) => (
											<Box
												sx={{
													borderWidth: 4,
													borderStyle: "solid",
													borderColor: "background.paper",
												}}
												className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden">
												<div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
												<div className="absolute inset-0 flex items-center justify-center z-20">
													<div>
														<label htmlFor="button-avatar" className="flex p-8 cursor-pointer">
															<input
																accept="image/*"
																className="hidden"
																id="button-avatar"
																type="file"
																onChange={async (e) => {
																	function readFileAsync() {
																		return new Promise((resolve, reject) => {
																			const file = e.target.files[0]
																			if (!file) {
																				return
																			}
																			const reader = new FileReader()

																			reader.onload = () => {
																				resolve(`data:${file.type};base64,${btoa(reader.result)}`)
																			}

																			reader.onerror = reject

																			reader.readAsBinaryString(file)
																		})
																	}

																	const newImage = await readFileAsync()

																	onChange(newImage)
																}}
															/>
															<FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
														</label>
													</div>
													<div>
														<IconButton
															onClick={() => {
																onChange("")
															}}>
															<FuseSvgIcon className="text-white">heroicons-solid:trash</FuseSvgIcon>
														</IconButton>
													</div>
												</div>
												<Avatar
													sx={{
														backgroundColor: "background.default",
														color: "text.secondary",
													}}
													className="object-cover w-full h-full text-64 font-bold"
													src={value}
													alt={ticket.name}>
													{ticket.name.charAt(0)}
												</Avatar>
											</Box>
										)}
									/>
								</div>
							</div>

							<Controller
								control={control}
								name="name"
								render={({ field }) => (
									<TextField
										className="mt-32"
										{...field}
										label="Name"
										placeholder="Name"
										id="name"
										error={!!errors.name}
										helperText={errors?.name?.message}
										variant="outlined"
										required
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>

							<Controller
								control={control}
								name="tags"
								render={({ field: { onChange, value } }) => (
									<Autocomplete
										multiple
										id="tags"
										className="mt-32"
										options={tags}
										disableCloseOnSelect
										getOptionLabel={(option) => option.title}
										renderOption={(_props, option, { selected }) => (
											<li {..._props}>
												<Checkbox style={{ marginRight: 8 }} checked={selected} />
												{option.title}
											</li>
										)}
										value={value ? value.map((id) => _.find(tags, { id })) : []}
										onChange={(event, newValue) => {
											onChange(newValue.map((item) => item.id))
										}}
										fullWidth
										renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
									/>
								)}
							/>

							<Controller
								control={control}
								name="title"
								render={({ field }) => (
									<TextField
										className="mt-32"
										{...field}
										label="Title"
										placeholder="Job title"
										id="title"
										error={!!errors.title}
										helperText={errors?.title?.message}
										variant="outlined"
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<FuseSvgIcon size={20}>heroicons-solid:briefcase</FuseSvgIcon>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>

							<Controller
								control={control}
								name="company"
								render={({ field }) => (
									<TextField
										className="mt-32"
										{...field}
										label="Company"
										placeholder="Company"
										id="company"
										error={!!errors.company}
										helperText={errors?.company?.message}
										variant="outlined"
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<FuseSvgIcon size={20}>heroicons-solid:office-building</FuseSvgIcon>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
							<Controller control={control} name="emails" render={({ field }) => <TicketEmailSelector className="mt-32" {...field} />} />

							<Controller control={control} name="phoneNumbers" render={({ field }) => <PhoneNumberSelector className="mt-32" {...field} />} />

							<Controller
								control={control}
								name="address"
								render={({ field }) => (
									<TextField
										className="mt-32"
										{...field}
										label="Address"
										placeholder="Address"
										id="address"
										error={!!errors.address}
										helperText={errors?.address?.message}
										variant="outlined"
										fullWidth
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<FuseSvgIcon size={20}>heroicons-solid:location-marker</FuseSvgIcon>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name="birthday"
								render={({ field: { value, onChange } }) => (
									<DateTimePicker
										value={new Date(value)}
										onChange={onChange}
										className="mt-32 mb-16 w-full"
										clearable
										slotProps={{
											textField: {
												id: "birthday",
												label: "Birthday",
												InputLabelProps: {
													shrink: true,
												},
												fullWidth: true,
												variant: "outlined",
											},
											actionBar: {
												actions: ["clear", "today"],
											},
										}}
										slots={{
											openPickerIcon: () => <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>,
										}}
									/>
								)}
							/>
							<Controller
								control={control}
								name="notes"
								render={({ field }) => (
									<TextField
										className="mt-32"
										{...field}
										label="Notes"
										placeholder="Notes"
										id="notes"
										error={!!errors.notes}
										helperText={errors?.notes?.message}
										variant="outlined"
										fullWidth
										multiline
										minRows={5}
										maxRows={10}
										InputProps={{
											className: "max-h-min h-min items-start",
											startAdornment: (
												<InputAdornment className="mt-16" position="start">
													<FuseSvgIcon size={20}>heroicons-solid:menu-alt-2</FuseSvgIcon>
												</InputAdornment>
											),
										}}
									/>
								)}
							/>
						</div>

						<Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t" sx={{ backgroundColor: "background.default" }}>
							{routeParams.id !== "new" && (
								<Button color="error" onClick={handleRemoveTicket}>
									Delete
								</Button>
							)}
							<Button className="ml-auto" component={NavLinkAdapter} to={-1}>
								Cancel
							</Button>
							<Button className="ml-8" variant="contained" color="secondary" disabled={_.isEmpty(dirtyFields) || !isValid} onClick={handleSubmit(onSubmit)}>
								Save
							</Button>
						</Box>
					</>
				}
				//ref={pageLayout}
				//rightSidebarContent={<TicketsSidebarContent />}
				//rightSidebarOpen={false}
				//rightSidebarOnClose={() => setRightSidebarOpen(false)}
				//rightSidebarWidth={640}
				scroll={isMobile ? "normal" : "content"}
			/>
		</FormProvider>
	)
}

export default withReducer("ticketsApp", reducer)(TicketFullForm)
