import React, { Component } from "react"
//import { Link } from "react-router-dom"

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		}
	}

	// Aggiorna lo stato in caso di errore, in modo da mostrare l'interfaccia di fallback
	static getDerivedStateFromError(error) {
		return { hasError: true, error }
	}

	// Metodo per loggare gli errori
	componentDidCatch(error, errorInfo) {
		this.setState({ errorInfo })
		// Puoi anche inviare l'errore a un servizio di log esterno
		console.error("ErrorBoundary caught an error", error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			// Interfaccia di fallback in caso di errore
			return (
				<div style={{ padding: "20px", textAlign: "center" }}>
					<h2>Something went wrong.</h2>
					<details style={{ whiteSpace: "pre-wrap" }}>
						Sorry about that. We've logged the error for review. Try refreshing the page or return to the previous page.
						<br />
						<br />
						{this.state.error && this.state.error.toString()}
						<br />
						{/*{this.state.errorInfo && this.state.errorInfo.componentStack}
						<br />
						<Link className="block font-normal mt-48" to="/">
							Back to Home
						</Link>*/}
					</details>
				</div>
			)
		}

		// Se non ci sono errori, renderizza i figli normalmente
		return this.props.children
	}
}

export default ErrorBoundary
