import i18next from "i18next"
import { lazy } from "react"
import authRoles from "src/app/auth/authRoles"

import it from "./i18n/it"
import en from "./i18n/en"
i18next.addResourceBundle("en", "e-logisticApp", en)
i18next.addResourceBundle("it", "e-logisticApp", it)

const Manifest = lazy(() => import("./manifest/Manifest"))
const Manifests = lazy(() => import("./manifests/Manifests"))
const Project = lazy(() => import("./project/Project"))
const Projects = lazy(() => import("./projects/Projects"))
const Delivery = lazy(() => import("./delivery/Delivery"))
const Deliveries = lazy(() => import("./deliveries/Deliveries"))
const Debriefs = lazy(() => import("./debriefs/Debriefs"))
const ELogisticsDashboardApp = lazy(() => import("../../dashboards/e-logistic/ELogisticsDashboardApp"))

const ELogisticAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.canon,
	routes: [
		{
			path: "dashboards/e-logistics-general",
			//auth: authRoles.user,
			element: <ELogisticsDashboardApp />,
		},
		{
			path: "apps/e-logistic/manifests",
			//auth: authRoles.user,
			element: <Manifests />,
		},
		{
			path: "apps/e-logistic/manifests/:manifestId/*",
			//auth: authRoles.user,
			element: <Manifest />,
		},
		{
			path: "apps/e-logistic/projects",
			//auth: authRoles.user,
			element: <Projects />,
		},
		{
			path: "apps/e-logistic/projects/:projectId/*",
			//auth: authRoles.user,
			element: <Project />,
		},

		{
			path: "apps/e-logistic/deliveries",
			//auth: authRoles.user,
			element: <Deliveries />,
		},
		{
			path: "apps/e-logistic/deliveries/:deliveryId/*",
			//auth: authRoles.user,
			element: <Delivery />,
		},
		{
			path: "apps/e-logistic/debriefs",
			//auth: authRoles.user,
			element: <Debriefs />,
		},
	],
}

export default ELogisticAppConfig
