import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import { useTheme } from "@mui/material/styles"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { selectUser } from "app/store/userSlice"
import JwtService from "../../auth/services/jwtService"

const debug = process.env.NODE_ENV === "development" && false

const isAuthenticated = (user) => user.role && user.role.length && user.data.displayName !== "Anonymous"

const SignOutPage = () => {
	const theme = useTheme()
	const user = useSelector(selectUser)

	useEffect(() => {
		if (isAuthenticated(user)) {
			setTimeout(() => {
				debug && console.log("<SignOutPage>.useEffect().setTimeout()")
				JwtService.signoff()
			}, 1000)
		}
	}, [])

	return (
		<div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
			<Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
				<div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
					<img className="w-96 mx-auto" src={`assets/images/logo/logo-${theme.palette.mode === "light" ? "positive.svg" : "negative.svg"}`} alt="logo" />
					<Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">You have signed out!</Typography>
				</div>
			</Paper>
		</div>
	)
}

export default SignOutPage
