function format(value, format, lng) {
	if (format === 'dateTime') {
		return formatDateTime(value, format, lng)
	}
	return value
}

const options = { dateStyle: 'short', timeStyle: 'short' }

function formatDateTime(value, format, lng) {
	return new Intl.DateTimeFormat(lng, options).format(new Date(value))
}

export default format
