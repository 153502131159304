import i18next from "i18next"
import { lazy } from "react"
import it from "./i18n/it"
import en from "./i18n/en"
import authRoles from "../../../auth/authRoles"
const ELogisticsDashboardApp = lazy(() => import("./ELogisticsDashboardApp"))

i18next.addResourceBundle("en", "e-logisticApp", en)
i18next.addResourceBundle("it", "e-logisticApp", it)

const ELogisticsDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.canon,
	routes: [
		{
			path: "dashboards/e-logistics-general",
			element: <ELogisticsDashboardApp />,
		},
	],
}

export default ELogisticsDashboardAppConfig
