/**
 * Open ticket in read mode
 * @param {*} ticketId
 * @returns
 */
export const quickview = (ticketId) => {
	return `/apps/tickets/${ticketId}`
}

/**
 * Open ticket in edit mode
 * @returns
 */
export const quickedit = () => {
	return `edit`
}

export const fulledit = () => {
	return `full`
}

/**
 * Create a new ticket in quickmode
 * @returns
 */
export const quicknew = () => {
	return `new/edit`
}

/**
 * Create a new ticket in fullmode
 * @returns
 */
export const fullnew = () => {
	return `new/full`
}

/**
 * Save ticket
 * @param {*} payloadId
 * @returns
 */
export const save = (payloadId) => {
	return `/apps/tickets/${payloadId}`
}

/**
 * Back to ticket list
 * @returns
 */
export const close = () => {
	return "/apps/tickets"
}
