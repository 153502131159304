import { memo, Suspense, useState, useRef, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, matchRoutes } from "react-router-dom"
import { matchPath } from "react-router"
import { selectNavigation } from "app/store/fuse/navigationSlice"
import { selectTabs } from "app/store/fuse/tabsSlice"
import { ignoredPaths } from "app/configs/ignoredPaths"
import FuseUtils from "@fuse/utils"
import FuseLoading from "@fuse/core/FuseLoading"
import PropTypes from "prop-types"

const debug = false
let rendering = -1

const FuseSuspenseTabbed = (props) => {
	debug && console.log("<FuseSuspenseTabbed>".padEnd(21) + "%cConstructor() -rendering:", "color: yellow", ++rendering, props.children.props)

	//DEGUG
	return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>

	//const dispatch = useDispatch()
	//const [cachedComponents, setCachedComponents] = useState([])
	//let cachedComponents = []
	//const [cachedComponents] = useState([])
	const { currentTabIndex, list } = useSelector(selectTabs)
	const cachedComponents = useRef([])

	debug && console.log("<FuseSuspenseTabbed> -currentTabIndex:", currentTabIndex, "-list:", list.length, list)

	const location = useLocation()
	debug && console.log("<FuseSuspenseTabbed> -location.pathname:", location.pathname)

	const { route, params, pathname, pathnameBase } = props.children.props.match
	debug && console.log("<FuseSuspenseTabbed> -match.route:", route)
	const routePath = route.path.split("/:", 1)[0]
	debug && console.log("<FuseSuspenseTabbed> -routepath:", routePath)
	debug && console.log("<FuseSuspenseTabbed> -match.pathnameBase:", pathnameBase)
	debug && console.log("<FuseSuspenseTabbed> -match.params:", params)
	debug && console.log("<FuseSuspenseTabbed> -match.pathname:", pathname, "-ignored?", ignoredPaths.includes(pathname))

	if (ignoredPaths.includes(pathname)) {
		return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>
	}

	//const navigation = useRef(useSelector(selectNavigation))
	//debug && console.log("<FuseSuspenseTabbed> -navigation: ", navigation.current)

	const matchTab = list.find((tab) => tab.key === pathname)
	debug && console.log("<FuseSuspenseTabbed> -matchTab:", matchTab)

	if (matchTab) {
		const component = { key: matchTab.key, location: routePath, value: props.children }

		debug && console.log("<FuseSuspenseTabbed> -cachedComponents.length:", cachedComponents.current.length)
		if (cachedComponents.current.length === 0) {
			//setCachedComponents([...cachedComponents, component])
			cachedComponents.current.push(component)
			debug && console.log("<FuseSuspenseTabbed> " + component.key + "(" + component.location + ") %cCached", "color: red")
		} else {
			//if (cachedComponents.current.some((obj) => obj.key === component.key)) {
			if (cachedComponents.current.some((obj) => obj.location === component.location)) {
				debug && console.log("<FuseSuspenseTabbed> " + component.key + "(" + component.location + ") %cAlready Cached", "color: red")
			} else {
				debug && console.log("<FuseSuspenseTabbed> " + component.key + "(" + component.location + ") %cCached", "color: red")
				cachedComponents.current.push(component)
			}

			//let keyExists = cachedComponents.current.some((obj) => obj.key === component.key) && location.pathname === pathname
			//const self = props.children.props.match.route.self

			//debug && console.log("<FuseSuspenseTabbed> -keyExists for:", matchTab.key, "cached component:", keyExists, "params:", Object.keys(params).length)

			//if (Object.keys(params).length !== 0 && self) {
			//debug && console.log("<FuseSuspenseTabbed> -keyExists for:", matchTab.key, keyExists)
			//}

			//if (!keyExists) {
			//debug && console.log("<FuseSuspenseTabbed> " + matchTab.key + " %cNot Cached", "color: violet")
			//setCachedComponents([...cachedComponents, component])
			//cachedComponents.current.push(component)
			//} else {
			//debug && console.log("<FuseSuspenseTabbed> " + matchTab.key + " %cAlready Cached", "color: red")
			//if (self) {
			//matchTab.key != location.pathname
			//debug && console.log("<FuseSuspenseTabbed> -self:", self, component)
			//const c = { key: location.pathname, value: props.children }
			//cachedComponents.current.push(c)
			//FIXME devi probabilmente sostituire l'attuale key /apps/ticket content con quello nuovo
			//}
			//}
		}
	} else {
		debug && console.log("<FuseSuspenseTabbed> -pathname: " + pathname + " not found as key in list", list)
	}

	//cachedComponents.current.filter((obj1) => list.some((obj2) => obj1.key === obj2.key)).map((obj) => ({ ...obj }))
	debug && console.log("<FuseSuspenseTabbed> -cachedComponents:", cachedComponents.current.length, cachedComponents.current)

	if (cachedComponents.current.length === 0) {
		debug && console.log("<FuseSuspenseTabbed> %cWARNING: Nothing to render", "color: red")

		return <Suspense fallback={<FuseLoading {...props.loadingProps} />}>{props.children}</Suspense>
		//return <Navigate to="pages/error/404" />
	}

	return cachedComponents.current.map((component) => (
		<div key={component.key} className={component.key == pathname ? "flex flex-col flex-auto min-h-0 relative z-10" : "flex flex-col flex-auto min-h-0 relative z-10 hidden"}>
			{debug && console.log("<FuseSuspenseTabbed> -component: %c" + component.key + " is " + (component.key == pathname ? "visible" : "hidden"), "color: violet")}
			{<Suspense fallback={<FuseLoading {...props.loadingProps} />}>{component.value}</Suspense>}
		</div>
	))
}

FuseSuspenseTabbed.propTypes = {
	loadingProps: PropTypes.object,
}

FuseSuspenseTabbed.defaultProps = {
	loadingProps: {
		delay: 0,
	},
}

export default FuseSuspenseTabbed
