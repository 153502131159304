/**
 * Authorization Roles
 *  =>  role: ['who can see']
 */
const authRoles = {
	admin: ["admin"],
	staff: ["admin", "staff"],
	user: ["admin", "staff", "user"],
	vendor: ["admin", "staff", "vendor"],
	guest: ["admin", "staff", "user", "guest"],
	prospect: ["prospect", ""],
	onlyGuest: [],
}

export default authRoles
