import _ from '@lodash'

const ItemModel = (data) =>
	_.defaults(data || {}, {
		type: 'item',
		title: '',
		notes: '',
		completed: true,
		dueDate: null,
		priority: 0,
		tags: [],
		assignedTo: null,
		subItems: [],
		order: 1,
	})

export default ItemModel
