import React from "react"
import "./AnimatedCircuitBackground.css"

function FuseAnimatedCircuitBackground() {
	return (
		<svg className="animated-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="500" height="500" preserveAspectRatio="xMidYMid meet">
			<defs>
				<clipPath id="animationMask_bxTCNtUxtj">
					<rect width="500" height="500" x="0" y="0" />
				</clipPath>
			</defs>
			<g clipPath="url(#animationMask_bxTCNtUxtj)">
				<g transform="matrix(1.28,0,0,1.28,26.72,101.12)" opacity="1" style={{ userSelect: "none" }}>
					<g opacity="1" transform="matrix(1,0,0,1,210.579,295.8)"></g>
					<g opacity="1" transform="matrix(1,0,0,1,210.63,295.851)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M-92.899,0.657 C-65.993,0.657 12.975,0.657 12.975,0.657 C12.975,0.657 31.426,-17.795 31.426,-17.795 C31.426,-17.795 66.164,-17.795 66.164,-17.795 C66.164,-17.795 101.754,17.795 101.754,17.795"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,201.626,283.59)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M124.753,22.042 C117.862,15.151 109.255,6.544 109.255,6.544 C109.255,6.544 109.255,-4.645 109.255,-4.645 C109.255,-4.645 83.419,-30.48 83.419,-30.48 C83.419,-30.48 25.055,-30.48 25.055,-30.48 C25.055,-30.48 10.161,-15.584 10.161,-15.584 C10.161,-15.584 -133.191,-15.584 -133.191,-15.584"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,284.438,257.869)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M80.546,46.132 C72.986,46.132 63.566,46.132 63.566,46.132 C63.566,46.132 42.371,24.936 42.371,24.936 C42.371,24.936 42.371,-7.936 42.371,-7.936 C42.371,-7.936 4.177,-46.132 4.177,-46.132 C4.177,-46.132 -89.844,-46.132 -89.844,-46.132"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,184.043,245.123)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M89.604,-10.135 C73.793,-10.135 40.001,-10.135 40.001,-10.135 C40.001,-10.135 19.731,10.135 19.731,10.135 C19.731,10.135 -97.585,10.135 -97.585,10.135"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,142.064,272.956)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M-131.448,3.116 C-128.229,6.335 -125.278,9.286 -125.278,9.286 C-125.278,9.286 70.991,9.286 70.991,9.286 C70.991,9.286 89.563,-9.286 89.563,-9.286 C89.563,-9.286 139.564,-9.286 139.564,-9.286"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,224.264,239.574)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M86.819,9.973 C77.773,0.927 61.162,-15.684 61.162,-15.684 C61.162,-15.684 -10.33,-15.684 -10.33,-15.684 C-10.33,-15.684 -29.506,3.492 -29.506,3.492 C-29.506,3.492 -92.53,3.492 -92.53,3.492"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,269.932,235.349)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M93.61,55.904 C90.84,55.904 88.622,55.904 88.622,55.904 C88.622,55.904 74.77,42.053 74.77,42.053 C74.77,42.053 74.77,9.774 74.77,9.774 C74.77,9.774 23,-41.995 23,-41.995 C23,-41.995 -40.613,-41.995 -40.613,-41.995 C-40.613,-41.995 -54.521,-55.904 -54.521,-55.904 C-54.521,-55.904 -104.351,-55.904 -104.351,-55.904"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,221.847,157.404)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M-52.575,-21.146 C-44.581,-13.152 -26.374,5.055 -26.374,5.055 C-26.374,5.055 0,5.055 0,5.055 C0,5.055 19.781,24.836 19.781,24.836 C19.781,24.836 56.265,24.836 56.265,24.836"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,233.056,141.469)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M-41.9,-25.526 C-29.887,-13.513 12.308,28.682 12.308,28.682 C12.308,28.682 45.056,28.682 45.056,28.682"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,319.247,191.383)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M47.772,83.27 C45.301,80.799 42.389,77.887 42.389,77.887 C42.389,77.887 42.389,50.199 42.389,50.199 C42.389,50.199 -18.275,-10.465 -18.275,-10.465 C-18.275,-10.465 -18.275,-49.914 -18.275,-49.914 C-18.275,-49.914 -55.035,-86.674 -55.035,-86.674"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,259.43,107.293)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M-39.616,-21.32 C-28.529,-21.32 1.098,-21.32 1.098,-21.32 C1.098,-21.32 43.737,21.32 43.737,21.32"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,320.863,132.039)">
						<path
							strokeLinecap="butt"
							strokeLinejoin="miter"
							fillOpacity="0"
							strokeMiterlimit="10"
							stroke="rgb(77,77,77)"
							strokeOpacity="1"
							strokeWidth="1"
							d="M0 0 M25.672,74.275 C16.423,65.026 -1.406,47.197 -1.406,47.197 C-1.406,47.197 -1.406,6.683 -1.406,6.683 C-1.406,6.683 11.329,-6.052 11.329,-6.052 C11.329,-6.052 11.329,-37.274 11.329,-37.274 C11.329,-37.274 -31.096,-79.699 -31.096,-79.699"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,254.314,53.342)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M61.787,41.935 C48.894,29.042 14.269,-5.583 14.269,-5.583 C14.269,-5.583 -25.435,-5.583 -25.435,-5.583 C-25.435,-5.583 -66.549,-46.697 -66.549,-46.697"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,341.593,80.247)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M-28.305,-61.518 C-18.173,-51.386 5.919,-27.293 5.919,-27.293 C5.919,-27.293 5.919,39.133 5.919,39.133 C5.919,39.133 32.711,65.924 32.711,65.924"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,365.954,90.977)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M6.05,35.356 C1.49,30.796 -8.328,20.979 -8.328,20.979 C-8.328,20.979 -8.328,-37.634 -8.328,-37.634"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,370.765,91.248)">
						<path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="10" stroke="rgb(77,77,77)" strokeOpacity="1" strokeWidth="1" d="M0 0 M2.264,20.264 C0.225,18.225 -3.54,14.46 -3.54,14.46 C-3.54,14.46 -3.54,-21.539 -3.54,-21.539"></path>
					</g>
				</g>
				<g transform="matrix(1.27,0,0,1.27,27.125,97.555)" opacity="1" style={{ userSelect: "none" }}>
					<g opacity="1" transform="matrix(1,0,0,1,71.021,272.507)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,89.044,259.843)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,134.32,247.782)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.835,-2.67 4.835,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,197.179,216.215)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.835,2.67 -4.835,0 C-4.835,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,168.167,184.029)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.835,-2.67 4.835,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,280.697,186.824)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,280.697,174.736)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,266.797,109.293)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,305.753,133.196)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,292.352,56.924)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,190.351,11.23)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.835,2.67 -4.835,0 C-4.835,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,360.212,57.927)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,284.214,268.255)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,369.811,74.294)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z"></path>
					</g>
				</g>
				<g transform="matrix(1.27,0,0,1.27,27.125,97.555)" opacity="0" style={{ userSelect: "none", display: "block" }}>
					<g opacity="1" transform="matrix(1,0,0,1,5.085,272.541)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.835,2.67 -4.835,0 C-4.835,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.835,-2.67 4.835,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,111.462,301.087)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.671,4.835 0.001,4.835 C-2.67,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.67,-4.835 0.001,-4.835 C2.671,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,168.167,137.152)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.835,-2.67 4.835,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,191.464,117.372)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.835,-2.67 4.835,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,218.278,90.667)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.835,0 C4.835,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.835,-2.67 4.835,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,323.449,104.458)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,311.467,18.908)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,354.546,216.215)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,319.38,259.843)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
					<g opacity="1" transform="matrix(1,0,0,1,284.214,239.934)">
						<path fill="rgb(77,77,77)" fillOpacity="1" d="M0 0 M4.836,0 C4.836,2.67 2.67,4.835 0,4.835 C-2.671,4.835 -4.836,2.67 -4.836,0 C-4.836,-2.67 -2.671,-4.835 0,-4.835 C2.67,-4.835 4.836,-2.67 4.836,0z" style={{ display: "block" }}></path>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default FuseAnimatedCircuitBackground
