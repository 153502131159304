import { lazy } from "react"
import DocumentView from "./document/DocumentView"
import DocumentForm from "./document/DocumentForm"
import DocumentViewFull from "./document/DocumentViewFull"
import DocumentFormFull from "./document/DocumentFormFull"

const DmsApp = lazy(() => import("./DmsApp"))

const dmsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: "apps/dms",
			element: <DmsApp />,
			children: [
				{
					path: ":id",
					element: <DocumentView />,
				},
				{
					path: ":id/edit",
					element: <DocumentForm />,
				},
			],
		},
	],
}

export default dmsAppConfig
