import { styled } from "@mui/material/styles"
import FusePageSimple from "@fuse/core/FusePageSimple"
import { useEffect, useState } from "react"
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery"
import DemoHeader from "../../shared-components/DemoHeader"
import DemoContent from "../../shared-components/DemoContent"
import DemoSidebar from "../../shared-components/DemoSidebar"

const Root = styled(FusePageSimple)(({ theme }) => ({
	"& .FusePageSimple-header": {
		backgroundColor: theme.palette.background.paper,
		borderBottomWidth: 1,
		borderStyle: "solid",
		borderColor: theme.palette.divider,
	},
	"& .FusePageSimple-toolbar": {},
	"& .FusePageSimple-content": {},
	"& .FusePageSimple-sidebarHeader": {},
	"& .FusePageSimple-sidebarContent": {},
}))

function SimpleWithSidebarsNormalScrollComponent() {
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"))

	console.log({ isMobile })
	const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile)
	const [rightSidebarOpen, setRightSidebarOpen] = useState(!isMobile)

	useEffect(() => {
		setLeftSidebarOpen(!isMobile)
		setRightSidebarOpen(!isMobile)
	}, [isMobile])

	return (
		<Root
			header={
				<DemoHeader
					leftSidebarToggle={(ev) => {
						setLeftSidebarOpen(!leftSidebarOpen)
					}}
					rightSidebarToggle={(ev) => {
						setRightSidebarOpen(!rightSidebarOpen)
					}}
				/>
			}
			content={<DemoContent />}
			leftSidebarOpen={leftSidebarOpen}
			leftSidebarOnClose={() => {
				setLeftSidebarOpen(false)
			}}
			leftSidebarContent={<DemoSidebar />}
			rightSidebarOpen={rightSidebarOpen}
			rightSidebarOnClose={() => {
				setRightSidebarOpen(false)
			}}
			rightSidebarContent={<DemoSidebar />}
			scroll="normal"
		/>
	)
}

export default SimpleWithSidebarsNormalScrollComponent
