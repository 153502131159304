import { lazy } from "react"

//import ProfileAppEdit from "./ProfileAppEdit"

const ProfileView = lazy(() => import("./ProfileView"))
const ProfileEdit = lazy(() => import("./ProfileEdit"))

const profileConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: "apps/profile",
			element: <ProfileView />,
		},
		{
			path: "apps/profile/edit",
			element: <ProfileEdit />,
		},
	],
}

export default profileConfig
