import SignOnPage from "./SignOnPage"
import authRoles from "../../auth/authRoles"

const SignOnConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false,
				},
				toolbar: {
					display: false,
				},
				footer: {
					display: false,
				},
				leftSidePanel: {
					display: false,
				},
				rightSidePanel: {
					display: false,
				},
			},
		},
	},
	auth: authRoles.prospect,
	routes: [
		{
			path: "sign-on",
			element: <SignOnPage />,
		},
	],
}

export default SignOnConfig
