const locale = {
	Manifests: "Manifesti",
	Manifest: "Manifesto",
	Projects: "Progetti",
	Project: "Progetto",
	Deliveries: "Consegne",
	Delivery: "Consegna",
	NoDeliveries: "Non ci sono consegne!",
	Debriefs: "Debriefs pianificati alle ore 9:00 di tutti i giorni feriali",
	NoDebriefs: "Non ci sono debriefs pianificati per domani!",
	FOLDERS: "Cartelle",
	FILTERS: "Filtri",
	LABELS: "Etichette",
	NO_MESSAGES: "Non ci sono messaggi!",
	SEARCH_PLACEHOLDER: "Cerca una email o attività",
	GENERAL_DASHBOARD: "Canon Consip3 - Logistica",
}

export default locale
