import { useSnackbar } from "notistack"
import React from "react"

let useSnackbarRef
export const SnackbarUtilsConfigurator = () => {
	useSnackbarRef = useSnackbar()
	return null
}

const SnackbarUtils = {
	enqueueSnackbar(message, options = {}) {
		if (useSnackbarRef) {
			useSnackbarRef.enqueueSnackbar(message, options)
		}
	},
}

export default SnackbarUtils
