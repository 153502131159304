import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import history from "@history"
import ContactModel from "../model/ContactModel"
const debug = process.env.NODE_ENV === "development" && false

export const getContact = createAsyncThunk("contactsApp/task/getContact", async (id, { dispatch, getState }) => {
	debug && console.log("getContact(contactsApp/task/getContact) -id:", id)
	try {
		//const response = await axios.get(`/api/contacts/${id}`)
		const response = await axios.get(`/api/v1/users/${id}`)

		const data = await response.data
		console.log(data)

		return data
	} catch (error) {
		history.push({ pathname: `/apps/contacts` })

		return null
	}
})

export const addContact = createAsyncThunk("contactsApp/contacts/addContact", async (contact, { dispatch, getState }) => {
	const response = await axios.post("/api/contacts", contact)

	const data = await response.data

	return data
})

export const updateContact = createAsyncThunk("contactsApp/contacts/updateContact", async (contact, { dispatch, getState }) => {
	const response = await axios.put(`/api/contacts/${contact.id}`, contact)

	const data = await response.data

	return data
})

export const removeContact = createAsyncThunk("contactsApp/contacts/removeContact", async (id, { dispatch, getState }) => {
	const response = await axios.delete(`/api/contacts/${id}`)

	await response.data

	return id
})

export const selectContact = ({ contactsApp }) => contactsApp.contact

const contactSlice = createSlice({
	name: "contactsApp/contact",
	initialState: null,
	reducers: {
		newContact: (state, action) => ContactModel(),
		resetContact: () => null,
	},
	/*extraReducers: {
		[getContact.pending]: (state, action) => null,
		[getContact.fulfilled]: (state, action) => action.payload,
		[updateContact.fulfilled]: (state, action) => action.payload,
		[removeContact.fulfilled]: (state, action) => null,
	},*/
	extraReducers: (builder) => {
		builder
			.addCase(getContact.pending, (state, action) => null)
			.addCase(getContact.fulfilled, (state, action) => action.payload)
			.addCase(updateContact.fulfilled, (state, action) => action.payload)
			.addCase(removeContact.fulfilled, (state, action) => null)
	},
})

export const { resetContact, newContact } = contactSlice.actions

export default contactSlice.reducer
