import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "@fuse/axios"
import FuseUtils from "@fuse/utils"
const debug = false

const isGuestUser = (user) => !user.role || user.role.length === 0 || user.data.displayName === "Anonymous" || user.data.settings.layout.style != "layout5"

export const setTabs = createAsyncThunk("tabs/setTabs", async (user, { rejectWithValue }) => {
	debug && console.log("tabsSlice.setTabs(initialState) -currentTabIndex:", initialState.currentTabIndex)
	//if (isGuestUser(user)) return
	if (isGuestUser(user)) {
		return rejectWithValue("Guest user or no tablayout style")
	}

	if (user.data.tabs) {
		let index = user.data.tabs.findIndex((tab) => tab.url === window.location.pathname)
		if (index === -1) index = 0

		return {
			currentTabIndex: index,
			list: user.data.tabs,
		}
	}
	// return { currentTabIndex: 0, list: [] }; // Return empty list if no tabs are present
	return initialState
})
// Restore initialState when user change to Layout without tabs management (called bu User slice)
export const getInitialState = createAsyncThunk("tabs/getInitialState", async (_, { getState }) => {
	debug && console.log("tabsSlice.getInitialState")
	return initialState
})

// Async Thunk to update user tabs in the backend
export const updateUserTabs = createAsyncThunk("tabs/updateUserTabs", async (tab, { getState, rejectWithValue }) => {
	debug && console.log("tabsSlice.updateUserTabs -tab:", tab)
	const { user, fuse } = getState()
	if (isGuestUser(user)) return rejectWithValue("Guest user or no tablayout style")

	const { tabs } = fuse

	const newList = [...tabs.list, tab]
	const newTabs = {
		currentTabIndex: newList.length - 1,
		list: newList,
	}

	try {
		await axios.patch("/api/v1/users/:id/tabs", { tabs: newTabs }, { bind: { id: user.id } })
		return newTabs
	} catch (error) {
		return rejectWithValue("Failed to update user tabs")
	}
})

// Async Thunk to update the current tab index
export const updateUserTabIndex = createAsyncThunk("tabs/updateUserTabIndex", async (index, { getState, rejectWithValue }) => {
	debug && console.log("tabsSlice.updateUserTabIndex -index: ", index)
	const { user } = getState()
	if (isGuestUser(user)) return rejectWithValue("Guest user or no tablayout style")

	return { currentTabIndex: index }
})

// Async Thunk to delete a user tab
export const deleteUserTabs = createAsyncThunk("tabs/deleteUserTabs", async (index, { getState, rejectWithValue }) => {
	debug && console.log("tabsSlice.deleteUserTabs arg = index to delete: ", index)
	const { user, fuse } = getState()
	if (isGuestUser(user)) return rejectWithValue("Guest user or no tablayout style")

	const { tabs } = fuse
	const { currentTabIndex, list } = tabs

	let newIndex = currentTabIndex
	const newList = FuseUtils.excludeObjectByIndex(list, index)
	if (newIndex >= index) newIndex--
	if (newIndex < 0) newIndex = 0

	const newTabs = {
		currentTabIndex: newIndex,
		list: newList,
	}

	try {
		await axios.patch("/api/v1/users/:id/tabs", { tabs: newTabs }, { bind: { id: user.id } })
		return newTabs
	} catch (error) {
		return rejectWithValue("Failed to delete tab")
	}
})

// Initial state with status tracking
const initialState = {
	currentTabIndex: 0,
	list: [
		{
			id: "dashboards.project",
			title: "Project",
			icon: "heroicons-outline:cash",
			basepath: "/dashboards/project",
			url: "/dashboards/project",
		},
	],
	status: "idle",
	error: null,
}

const tabsSlice = createSlice({
	name: "tabs",
	initialState,
	reducers: {
		updateTabUrl: (state, action) => {
			const { basepath, url } = action.payload
			const tab = state.list.find((tab) => tab.basepath === basepath)
			if (tab) {
				tab.url = url // Update the saved URL for this portal tab
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getInitialState.fulfilled, (state, action) => action.payload)
			.addCase(setTabs.pending, (state) => {
				state.status = "loading"
				state.error = null
			})
			.addCase(setTabs.fulfilled, (state, action) => {
				state.list = action.payload.list
				state.currentTabIndex = action.payload.currentTabIndex
				state.status = "succeeded"
			})
			.addCase(setTabs.rejected, (state, action) => {
				state.status = "failed"
				state.error = action.payload || "Failed to load tabs"
			})
			.addCase(updateUserTabs.pending, (state) => {
				state.status = "loading"
			})
			.addCase(updateUserTabs.fulfilled, (state, action) => {
				state.list = action.payload.list
				state.currentTabIndex = action.payload.currentTabIndex
				state.status = "succeeded"
			})
			.addCase(updateUserTabs.rejected, (state, action) => {
				state.status = "failed"
				state.error = action.payload || "Failed to update user tabs"
			})
			.addCase(updateUserTabIndex.fulfilled, (state, action) => {
				state.currentTabIndex = action.payload.currentTabIndex
				state.status = "succeeded"
			})
			.addCase(deleteUserTabs.pending, (state) => {
				state.status = "loading"
			})
			.addCase(deleteUserTabs.fulfilled, (state, action) => {
				state.list = action.payload.list
				state.currentTabIndex = action.payload.currentTabIndex
				state.status = "succeeded"
			})
			.addCase(deleteUserTabs.rejected, (state, action) => {
				state.status = "failed"
				state.error = action.payload || "Failed to delete tab"
			})
	},
})

export const selectTabs = (state) => state.fuse.tabs

export const { updateTabUrl } = tabsSlice.actions

export default tabsSlice.reducer
