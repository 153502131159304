const locale = {
	MANIFESTS: "Manifest",
	MANIFEST: "Manifest",
	MANIFEST_COMPOSE: "Aggiungi nuovo manifets",
	PROJECTS: "Progetti",
	PROJECT: "Progetto",
	PROJECT_COMPOSE: "Aggiungi nuovo progetto",
	FOLDERS: "Cartelle",
	FILTERS: "Filtri",
	LABELS: "Etichette",
	NO_MESSAGES: "Non ci sono messaggi!",
	SEARCH_PLACEHOLDER: "Cerca una email o attività",
	INBOX: "In Arrivo",
	SENT: "Spediti",
	DRAFTS: "Bozze",
	TRASH: "Cestino",
	STARRED: "Preferiti",
	IMPORTANT: "Importanti",
}

export default locale
