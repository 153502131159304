const jwtServiceConfig = {
	signIn: "api/v1/oauth2/sign-in", // loginWithEmailAndPassword
	signUp: "api/v1/oauth2/sign-up",
	signOff: "api/v1/oauth2/sign-off",
	//signUp: "api/auth/sign-up",
	accessToken: "api/v1/oauth2/access-token",
	refreshToken: "api/v1/oauth2/refresh-token",
	updateUserData: "api/v1/users/:id/data",
	signInMock: "api/auth/sign-in",
	accessTokenMock: "api/auth/access-token",
	updateUserMock: "api/auth/user/update",
}

export default jwtServiceConfig
