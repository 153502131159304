import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "@fuse/axios"

export const loadPlaceholders = createAsyncThunk("map/loadPlaceholders", async () => {
	const response = await axios.get("/api/v1/maps/placeholders")
	//console.log(response.data)
	return response.data
})

const mapSlice = createSlice({
	name: "map",
	initialState: {
		geoData: { type: "FeatureCollection", features: [] },
		loading: false,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(loadPlaceholders.pending, (state) => {
				state.loading = true
			})
			.addCase(loadPlaceholders.fulfilled, (state, action) => {
				state.geoData = action.payload
				state.loading = false
			})
	},
})

export const selectGeoData = (state) => state.fuse.map.geoData // or state.map.geoData

export default mapSlice.reducer
