import React from "react"
//import * as ReactRedux from "react-redux"
// https://www.npmjs.com/package/@welldone-software/why-did-you-render
if (process.env.NODE_ENV === "development") {
	const whyDidYouRender = require("@welldone-software/why-did-you-render")
	//const ReactRedux = require("react-redux")
	whyDidYouRender(React, {
		trackAllPureComponents: false,
		trackHooks: true,
		//trackExtraHooks: [[require("react-redux/dist/react-redux.js"), "useSelector"]],
	})
}
