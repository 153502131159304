import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
const debug = false

export const getCategories = createAsyncThunk("dmsApp/categories/getCategories", async (params, { getState }) => {
	debug && console.log("getCategories(dmsApp/categories/getCategories) -params: ", params)
	const response = await axios.get("/api/v1/categories")

	const data = await response.data
	return data
})

const categoriesAdapter = createEntityAdapter({})

export const { selectAll: selectCategories, selectById: selectCategoriesById } = categoriesAdapter.getSelectors((state) => state.dmsApp.categories)

const categoriesSlice = createSlice({
	name: "dmsApp/categories",
	initialState: categoriesAdapter.getInitialState([]),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCategories.fulfilled, categoriesAdapter.setAll)
	},
})

export default categoriesSlice.reducer
