import "./passiveEventsPatch" // Applica il monkey-patch per i listener passivi
import "./wdyr" // https://www.npmjs.com/package/@welldone-software/why-did-you-render
import "./i18n"
import "./styles/app-base.css"
import "./styles/app-components.css"
import "./styles/app-utilities.css"
import { createRoot } from "react-dom/client"
import ErrorBoundary from "./app/ErrorBoundary"
import App from "./app/App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import SnackbarUtils from "./snackbarUtils" // importa la utility
import Slide from "@mui/material/Slide"

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>
)

reportWebVitals()

// Funzione di transizione per lo Snackbar che scende dall'alto
function SlideTransition(props) {
	return <Slide {...props} direction="down" />
}

// Registra il service worker con il callback onUpdate
serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		//if (FuseUtils.isMobile()) {
		// Mostra lo Snackbar (senza attendere conferma)
		SnackbarUtils.enqueueSnackbar("Aggiornamento in corso...", {
			variant: "info",
			autoHideDuration: 5000,
			TransitionComponent: SlideTransition,
			//TransitionComponent: (props) => <Slide {...props} direction="down" />,
		})

		if (registration.waiting) {
			// Invia il messaggio al service worker in attesa per attivarlo subito
			registration.waiting.postMessage({ type: "SKIP_WAITING" })
			// Quando il nuovo service worker prende il controllo, ricarica la pagina.
			navigator.serviceWorker.addEventListener("controllerchange", () => {
				window.location.reload()
			})
		}
		//} else {
		//	// Se non è mobile, aggiorna automaticamente senza mostrare lo Snackbar
		//	if (registration.waiting) {
		//		registration.waiting.postMessage({ type: "SKIP_WAITING" })
		//		navigator.serviceWorker.addEventListener("controllerchange", () => {
		//			window.location.reload()
		//		})
		//}
		//}
	},
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister()

/*serviceWorkerRegistration.register({
	onUpdate: (registration) => {
		// Mostra una finestra di conferma all'utente
		if (window.confirm("Nuova versione disponibile. Vuoi aggiornare l'app?")) {
			//console.log("Nuova versione disponibile. Aggiornamento in corso...")
			if (registration.waiting) {
				// Invia il messaggio al service worker in attesa per attivarlo subito.
				registration.waiting.postMessage({ type: "SKIP_WAITING" })

				// Quando il nuovo service worker prende il controllo, ricarica la pagina.
				navigator.serviceWorker.addEventListener("controllerchange", () => {
					window.location.reload()
				})
			}
		}
	},
})*/
