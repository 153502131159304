import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import * as yup from "yup"
import _ from "@lodash"
import AvatarGroup from "@mui/material/AvatarGroup"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import FormHelperText from "@mui/material/FormHelperText"
import { selectUser } from "app/store/userSlice"
import { useSelector } from "react-redux"
import { motion } from "framer-motion"
import jwtService from "../../auth/services/jwtService"
import SignCommon from "../SignCommon/SignCommon"
import i18next from "i18next"
import it from "../SignCommon/i18n/it"
import en from "../SignCommon/i18n/en"
import { useTranslation } from "react-i18next"

i18next.addResourceBundle("en", "signCommon", en)
i18next.addResourceBundle("it", "signCommon", it)

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	//displayName: yup.string().required("You must enter display name"),
	email: yup.string().email("You must enter a valid email").required("You must enter a email"),
	password: yup.string().required("Please enter your password.").min(8, "Password is too short - should be 8 chars minimum."),
	passwordConfirm: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
	acceptTermsConditions: yup.boolean().oneOf([true], "The terms and conditions must be accepted."),
})

const defaultValues = {
	displayName: "",
	email: "",
	password: "",
	passwordConfirm: "",
	acceptTermsConditions: false,
}

const capitalize = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

function SignUpPage() {
	console.log("<SignUpPage>.SignUpPage()")
	const { t } = useTranslation("signCommon")
	const user = useSelector(selectUser)
	const { control, formState, handleSubmit, reset } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors, setError } = formState

	function onSubmit({ displayName, password, email }) {
		console.log("<SignUpPage>.onSubmit(email,password)")
		jwtService
			.createUser({
				displayName,
				password,
				email,
			})
			.then((user) => {
				// No need to do anything, registered user data will be set at app/auth/AuthContext
			})
			.catch((_errors) => {
				_errors.forEach((error) => {
					setError(error.type, {
						type: "manual",
						message: error.message,
					})
				})
			})
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
			<Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
					<img className="w-96 mx-auto" src="assets/images/logo/crowdc.svg" alt="logo" />

					<Typography className="text-2xl md:text-5xl font-semibold tracking-tight leading-7 md:leading-snug truncate">{`${t("WELCOME")}`}</Typography>
					<br />

					<Typography className="mx-6 leading-6" color="text.secondary">
						{`${t("WELCOME")} ${user.google.given_name}!`}
						<br />
						{`${t("SIGNON_1")} ${capitalize(user.from)}. ${t("SIGNON_2")}`}
					</Typography>

					<form name="registerForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
						<Controller name="email" control={control} render={({ field }) => <TextField {...field} className="mb-24" label="Email" type="email" error={!!errors.email} helperText={errors?.email?.message} variant="outlined" required fullWidth />} />

						<Controller name="password" control={control} render={({ field }) => <TextField {...field} className="mb-24" label="Password" type="password" error={!!errors.password} helperText={errors?.password?.message} variant="outlined" required fullWidth />} />

						<Controller
							name="acceptTermsConditions"
							control={control}
							render={({ field }) => (
								<FormControl className="items-center" error={!!errors.acceptTermsConditions}>
									<FormControlLabel label="I agree to the Terms of Service and Privacy Policy" control={<Checkbox size="small" {...field} />} />
									<FormHelperText>{errors?.acceptTermsConditions?.message}</FormHelperText>
								</FormControl>
							)}
						/>

						<Button variant="contained" color="secondary" className="w-full mt-24" aria-label="Register" disabled={_.isEmpty(dirtyFields) || !isValid} type="submit" size="large">
							Confirm your account
						</Button>
					</form>
				</div>
			</Paper>

			<SignCommon />
		</div>
	)
}

export default SignUpPage
