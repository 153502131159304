import { lazy } from "react"
import authRoles from "../../../auth/authRoles"
const FinanceDashboardApp = lazy(() => import("./FinanceDashboardApp"))

const FinanceDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.staff,
	routes: [
		{
			path: "dashboards/finance",
			element: <FinanceDashboardApp />,
		},
	],
}

export default FinanceDashboardAppConfig
