import { yupResolver } from "@hookform/resolvers/yup"
import { Controller, useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
//import { Link } from "react-router-dom"
import * as yup from "yup"
import _ from "@lodash"
import Paper from "@mui/material/Paper"
import { useEffect } from "react"
import { motion } from "framer-motion"
import SignCommon from "../SignCommon/SignCommon"
import jwtService from "../../auth/services/jwtService"
import i18next from "i18next"
import it from "../SignCommon/i18n/it"
import en from "../SignCommon/i18n/en"
import { useTranslation } from "react-i18next"

i18next.addResourceBundle("en", "signCommon", en)
i18next.addResourceBundle("it", "signCommon", it)

const debug = process.env.NODE_ENV === "development" && false

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	email: yup.string().email("You must enter a valid email").required("You must enter a email"),
	password: yup.string().required("Please enter your password.").min(8, "Password too short - must be at least 8 chars."),
})

const defaultValues = {
	email: "",
	password: "",
	remember: true,
}

const SignInPage = () => {
	debug && console.log("<SignInPage> Constructor()")
	const { t } = useTranslation("signCommon")

	const { control, formState, handleSubmit, setError, setValue, reset } = useForm({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState

	useEffect(() => {
		debug && console.log("<SignInPage>.useEffect()")
		// let the browser remeber last login user (commented out)
		//setValue("email", "admin@fusetheme.com", { shouldDirty: true, shouldValidate: true })
		//setValue("password", "admin", { shouldDirty: true, shouldValidate: true })
		const query = new URLSearchParams(window.location.search)
		const code = query.get("code")
		debug && console.log("<SignInPage>.useEffect()", { code })
		if (code) {
			jwtService
				.getErrorCode(code)
				.then((message) => {
					debug && console.log("<SignInPage>.getErrorCode -> then()")
					setError("social", {
						type: "manual",
						message: message,
					})
				})
				.catch((_errors) => {
					debug && console.log("<SignInPage>.getErrorCode -> catch()", { _errors })
					_errors.forEach((error) => {
						setError(error.type, {
							type: "manual",
							message: error.message,
						})
					})
				})
		}
	}, [setValue])

	const onSubmit = ({ email, password }) => {
		debug && console.log("<SignInPage>.onSubmit(email,password)")
		debug && console.log("\t-call signInWithEmailAndPassword(email, password)")
		jwtService
			.signInWithEmailAndPassword(email, password)
			.then((user) => {
				debug && console.log("<SignInPage>.signInWithEmailAndPassword -> then() nothing to do")
				// No need to do anything, user data will be set at app/auth/AuthContext
			})
			.catch((_errors) => {
				debug && console.log("<SignInPage>.signInWithEmailAndPassword -> catch()", { _errors })
				_errors.forEach((error) => {
					setError(error.type, {
						type: "manual",
						message: error.message,
					})
				})
			})
	}

	//signInWithGoogle
	const handleGoogleAuthentication = async () => {
		debug && console.log("<SignInPage> handleGoogleAuthentication()")
		const deviceId = await jwtService.getDeviceId()
		return window.open(`/api/v1/oauth2/federated/google?deviceId=${deviceId}`, "_self")
	}

	return (
		<div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
			<Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
				<div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
					<img className="w-96 mx-auto" src="assets/images/logo/crowdc.svg" alt="logo" />

					<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
						<div>{t("SIGNIN_MESSAGE")}</div>
					</motion.div>

					<motion.div initial={{ opacity: 0, y: 40 }} animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}>
						<form name="loginForm" noValidate className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
							<Controller
								name="email"
								control={control}
								render={({ field }) => (
									<TextField
										//
										{...field}
										className="mb-24"
										label="Email"
										autoFocus
										type="email"
										autoComplete="email"
										error={!!errors.email}
										helperText={errors?.email?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>
							<Controller
								name="password"
								control={control}
								render={({ field }) => (
									<TextField
										//
										{...field}
										className="mb-24"
										label="Password"
										type="password"
										autoComplete="current-password"
										error={!!errors.password}
										helperText={errors?.password?.message}
										variant="outlined"
										required
										fullWidth
									/>
								)}
							/>

							<Button variant="contained" color="primary" className="w-full mt-16" aria-label="Sign in" type="submit" size="large">
								Sign in
							</Button>

							<div className="flex items-center mt-32">
								<div className="flex-auto mt-px border-t" />
								<Typography className="mx-8" color="text.secondary">
									{t("EMAIL_OR_SOCIAL")}
								</Typography>
								<div className="flex-auto mt-px border-t" />
							</div>
							<Button variant="contained" color="secondary" className="w-full mt-16" aria-label="Sign in" onClick={handleGoogleAuthentication} size="large">
								Sign in with Google
							</Button>
						</form>
						<div className="mt-20 flex justify-center text-red-600">{errors?.social?.message}</div>
					</motion.div>
				</div>
			</Paper>
			<SignCommon />
		</div>
	)
}

export default SignInPage
