import { lazy } from "react"
import authRoles from "src/app/auth/authRoles"
import DocumentView from "./document/DocumentView"
import DocumentForm from "./document/DocumentForm"
import DocumentViewFull from "./document/DocumentViewFull"
import DocumentFormFull from "./document/DocumentFormFull"

const DmsApp = lazy(() => import("./DmsApp"))

const dmsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.staff,
	routes: [
		{
			path: "apps/dms",
			element: <DmsApp />,
			children: [
				{
					path: ":id",
					element: <DocumentView />,
				},
				{
					path: ":id/edit",
					element: <DocumentForm />,
				},
			],
		},
	],
}

const dmsAppIcons = {
	home: "heroicons-outline:home",
	documentView: "heroicons-outline:document",
	deleteTab: "heroicons-outline:x-circle",
}

export default dmsAppConfig
