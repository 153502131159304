import _ from '@lodash'
import FuseUtils from '@fuse/utils'
import mockApi from '../mock-api.json'
import mock from '../mock'

let itemsDB = mockApi.components.examples.items.value
const tagsDB = mockApi.components.examples.items_tags.value

mock.onGet('/api/items').reply((config) => {
	return [200, itemsDB]
})

mock.onPost('/api/items').reply(({ data }) => {
	const newitem = { id: FuseUtils.generateGUID(), ...JSON.parse(data) }

	itemsDB.push(newitem)

	return [200, newitem]
})

mock.onPost('/api/items/reorder').reply(({ data }) => {
	const { startIndex, endIndex } = JSON.parse(data)
	const ordered = _.merge([], itemsDB)

	const [removed] = ordered.splice(startIndex, 1)

	ordered.splice(endIndex, 0, removed)

	itemsDB = ordered

	return [200, itemsDB]
})

mock.onGet(/\/api\/items\/(?!tags)[^/]+/).reply((config) => {
	const { id } = config.url.match(/\/api\/items\/(?<id>[^/]+)/).groups
	const item = _.find(itemsDB, { id })

	if (item) {
		return [200, item]
	}

	return [404, 'Requested item do not exist.']
})

mock.onPut(/\/api\/items\/[^/]+/).reply(({ url, data }) => {
	const { id } = url.match(/\/api\/items\/(?<id>[^/]+)/).groups

	_.assign(_.find(itemsDB, { id }), JSON.parse(data))

	return [200, _.find(itemsDB, { id })]
})

mock.onDelete(/\/api\/items\/[^/]+/).reply((config) => {
	const { id } = config.url.match(/\/api\/items\/(?<id>[^/]+)/).groups

	_.remove(itemsDB, { id })

	return [200, id]
})

mock.onGet('/api/items/tags').reply((config) => {
	return [200, tagsDB]
})
