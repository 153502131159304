import AcademyAppConfig from "./academy/AcademyAppConfig"
import CalendarAppConfig from "./calendar/CalendarAppConfig"
import ChatAppConfig from "./chat/ChatAppConfig"
import ContactsAppConfig from "./contacts/ContactsAppConfig"
import ECommerceAppConfig from "./e-commerce/ECommerceAppConfig"
import ELogisticAppConfig from "./e-logistic/ELogisticAppConfig"
import FileManagerAppConfig from "./file-manager/FileManagerAppConfig"
import MailboxAppConfig from "./mailbox/MailboxAppConfig"
import NotesAppConfig from "./notes/NotesAppConfig"
import ScrumboardAppConfig from "./scrumboard/ScrumboardAppConfig"
import TasksAppConfig from "./tasks/TasksAppConfig"
import ItemsAppConfig from "./portal/menu-items/ItemsAppConfig"
import HelpCenterAppConfig from "./help-center/HelpCenterAppConfig"
import ProfileConfig from "./profile/profileConfig"
import TicketsAppConfig from "./tickets/TicketsAppConfig"
import DmsAppConfig from "./dms/DmsAppConfig"

const appsConfigs = [MailboxAppConfig, FileManagerAppConfig, ContactsAppConfig, DmsAppConfig, TicketsAppConfig, CalendarAppConfig, ChatAppConfig, ECommerceAppConfig, ELogisticAppConfig, ScrumboardAppConfig, AcademyAppConfig, NotesAppConfig, TasksAppConfig, ItemsAppConfig, HelpCenterAppConfig, ProfileConfig]

export default appsConfigs
