import { styled, useTheme } from "@mui/material/styles"
import { Link } from "react-router-dom"
//import { useSelector } from "react-redux"
//import { selectMainTheme } from "app/store/fuse/settingsSlice"

const Root = styled("div")(({ theme }) => ({
	"& > .logo-icon": {
		transition: theme.transitions.create(["width", "height"], {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
	"& > .badge": {
		transition: theme.transitions.create("opacity", {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
}))

function NewLogo() {
	const theme = useTheme()
	//const theme = useSelector(selectMainTheme)
	return (
		<Root className="flex items-center">
			{/*<img className="logo-icon w-32 h-32" src={`assets/images/logo/${theme.palette.mode === "light" ? "logoc.svg" : "logoSmall.svg"}`} alt="logo" />*/}
			{/*<div className="badge flex items-center py-4 px-8 mx-4 rounded" style={{ backgroundColor: "#121212", color: "#61DAFB" }}>*/}
			{/*<div className="flex items-center py-4 px-8 mx-4">*/}
			<Link to={"/"} role="button">
				<div className="flex items-center">
					{
						<img
							className="react-badge"
							//src="assets/images/logo/logo-negative.svg"
							src={`assets/images/logo/logo-${theme.palette.mode === "light" ? "positive.svg" : "negative.svg"}`}
							//src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
							alt="Crowd Services"
							width="64"
						/>
					}
				</div>
			</Link>
		</Root>
	)
}

export default Logo

function Logo() {
	const theme = useTheme()
	return (
		<Root className="flex items-center">
			{/*<img className="logo-icon w-32 h-32" src={`assets/images/logo/${theme.palette.mode === "light" ? "crowdc.svg" : "logoSmall.svg"}`} alt="logo" />*/}
			<Link to={"/"} role="button">
				{/* <div className="badge flex items-center py-4 px-8 mx-4 rounded" style={{ backgroundColor: "#121212", color: "#61DAFB" }}> */}
				<div className="flex items-center py-4">
					{
						<img
							className="react-badge"
							//src="assets/images/logo/crowdw.svg"
							src={`assets/images/logo/logo-${theme.palette.mode === "light" ? "positive.svg" : "negative.svg"}`}
							//src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K"
							alt="Crowd Services"
							width="32"
						/>
					}
					<span className="crowd-text text-13 mx-6">Crowd</span>
					<span className="services-text text-13">Services</span>
					<span className="portal-text text-13 mx-6">Portal</span>
				</div>
			</Link>
		</Root>
	)
}
