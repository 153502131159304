const locale = {
	Manifests: "Manifests",
	Manifest: "Manifest",
	Projects: "Projects",
	Project: "Project",
	Deliveries: "Deliveries",
	Delivery: "Delivery",
	NoDeliveries: "There are no deliveries!",
	Debriefs: "Debriefs scheduled at 9:00 AM every weekday",
	NoDebriefs: "There are no debriefs fro tomorrow!",
	FOLDERS: "Mailboxes",
	FILTERS: "Filters",
	LABELS: "Labels",
	NO_MESSAGES: "There are no messages!",
	SEARCH_PLACEHOLDER: "Search for an e-mail or task",
	GENERAL_DASHBOARD: "Canon Consip3 - Logistics",
}

export default locale
