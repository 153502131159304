import { lazy } from "react"
import authRoles from "../../auth/authRoles"

const Home = lazy(() => import("./Home"))

const HomeConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	//auth: authRoles.guest,
	routes: [
		{
			path: "home",
			element: <Home />,
		},
	],
}

export default HomeConfig
