import Dialog from "@mui/material/Dialog"
import { useDispatch, useSelector } from "react-redux"
import { closeDialog, selectFuseDialogOptions, selectFuseDialogState } from "app/store/fuse/dialogSlice"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

function FuseDialog(props) {
	const dispatch = useDispatch()
	const state = useSelector(selectFuseDialogState)
	const options = useSelector(selectFuseDialogOptions)

	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

	return (
		<Dialog
			fullScreen={fullScreen}
			maxWidth={"lg"}
			open={state}
			onClose={(ev) => dispatch(closeDialog())}
			aria-labelledby="fuse-dialog-title"
			classes={{
				paper: "rounded-8",
			}}
			{...options}
		/>
	)
}

export default FuseDialog
