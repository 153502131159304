import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import history from "@history"
import TicketModel from "../model/TicketModel"
const debug = false

export const getTicket = createAsyncThunk("ticketsApp/task/getTicket", async (id, { dispatch, getState }) => {
	debug && console.log("getTicket(ticketsApp/task/getTicket) -id:", id)
	try {
		const response = await axios.get(`/api/tickets/${id}`)

		const data = await response.data
		console.log({ data })
		return data
	} catch (error) {
		history.push({ pathname: `/apps/tickets` })

		return null
	}
})

export const addTicket = createAsyncThunk("ticketsApp/tickets/addTicket", async (ticket, { dispatch, getState }) => {
	const response = await axios.post("/api/tickets", ticket)

	const data = await response.data

	return data
})

export const updateTicket = createAsyncThunk("ticketsApp/tickets/updateTicket", async (ticket, { dispatch, getState }) => {
	const response = await axios.put(`/api/tickets/${ticket.id}`, ticket)

	const data = await response.data

	return data
})

export const removeTicket = createAsyncThunk("ticketsApp/tickets/removeTicket", async (id, { dispatch, getState }) => {
	const response = await axios.delete(`/api/tickets/${id}`)

	await response.data

	return id
})

export const selectTicket = ({ ticketsApp }) => ticketsApp.ticket

const ticketSlice = createSlice({
	name: "ticketsApp/ticket",
	initialState: null,
	reducers: {
		newTicket: (state, action) => TicketModel(),
		resetTicket: () => null,
	},
	/*extraReducers: {
		[getTicket.pending]: (state, action) => null,
		[getTicket.fulfilled]: (state, action) => action.payload,
		[updateTicket.fulfilled]: (state, action) => action.payload,
		[removeTicket.fulfilled]: (state, action) => null,
	},*/
	extraReducers: (builder) => {
		builder
			.addCase(getTicket.pending, (state, action) => null)
			.addCase(getTicket.fulfilled, (state, action) => action.payload)
			.addCase(updateTicket.fulfilled, (state, action) => action.payload)
			.addCase(removeTicket.fulfilled, (state, action) => null)
	},
})

export const { resetTicket, newTicket } = ticketSlice.actions

export default ticketSlice.reducer
