import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
const debug = process.env.NODE_ENV === "development" && false

export const getTags = createAsyncThunk("contactsApp/tags/getTags", async (params, { getState }) => {
	debug && console.log("getTags(contactsApp/tags/getTags) -params: ", params)
	const response = await axios.get("/api/contacts/tags")

	const data = await response.data

	return data
})

const tagsAdapter = createEntityAdapter({})

export const { selectAll: selectTags, selectById: selectTagsById } = tagsAdapter.getSelectors((state) => state.contactsApp.tags)

const tagsSlice = createSlice({
	name: "contactsApp/tags",
	initialState: tagsAdapter.getInitialState([]),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getTags.fulfilled, tagsAdapter.setAll)
	},
	/*extraReducers: {
		[getTags.fulfilled]: tagsAdapter.setAll,
	},*/
})

export default tagsSlice.reducer
