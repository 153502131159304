const debug = process.env.NODE_ENV === "development" && false

export const getSessionCookie = () => {
	debug && console.log("sessionCookie -getSessionCookie: " + localStorage.getItem(process.env.REACT_APP_COOKIE_NAME))
	return localStorage.getItem(process.env.REACT_APP_COOKIE_NAME) || null
}

export const setSessionCookie = (cookie) => {
	localStorage.setItem(process.env.REACT_APP_COOKIE_NAME, cookie)
	debug && console.log("sessionCookie -setSessionCookie: " + localStorage.getItem(process.env.REACT_APP_COOKIE_NAME))
}

export const resetSessionCookie = () => {
	localStorage.removeItem(process.env.REACT_APP_COOKIE_NAME)
	debug && console.log("sessionCookie -resetSessionCookie: " + localStorage.getItem(process.env.REACT_APP_COOKIE_NAME))
}

export const getSessionDeviceId = () => {
	debug && console.log("sessionCookie -getSessionDeviceId: " + localStorage.getItem(process.env.REACT_APP_DEVICE_ID))
	return localStorage.getItem(process.env.REACT_APP_DEVICE_ID) || null
}

export const setSessionDeviceId = (deviceId) => {
	localStorage.setItem(process.env.REACT_APP_DEVICE_ID, deviceId)
	debug && console.log("sessionCookie -setSessionDeviceId: " + localStorage.getItem(process.env.REACT_APP_DEVICE_ID))
}

// Probabilmente non serve
export const resetSessionDeviceId = () => {
	localStorage.removeItem(process.env.REACT_APP_DEVICE_ID)
	debug && console.log("sessionCookie -resetSessionDeviceId: " + localStorage.getItem(process.env.REACT_APP_DEVICE_ID))
}
