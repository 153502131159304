const locale = {
	APP_TITLE: 'Posta',
	COMPOSE: 'Nuovo messaggio',
	FOLDERS: 'Cartelle',
	FILTERS: 'Filtri',
	LABELS: 'Etichette',
	NO_MESSAGES: 'Non ci sono messaggi!',
	SEARCH_PLACEHOLDER: 'Cerca una email o attività',
	INBOX: 'In Arrivo',
	SENT: 'Spediti',
	DRAFTS: 'Bozze',
	SPAM: 'Indesiderata',
	TRASH: 'Cestino',
	STARRED: 'Preferiti',
	IMPORTANT: 'Importanti',
	UNREAD: 'Marca come non letta',
}

export default locale
