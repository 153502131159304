import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { addTicket, getTicket, newTicket, removeTicket, selectTicket, updateTicket } from "../store/ticketSlice"
import { selectCountries } from "../store/countriesSlice"
import { selectTags } from "../store/tagsSlice"
import { Controller, useForm } from "react-hook-form"
import { FormControl, Avatar, Select, MenuItem, InputLabel, TextField, InputAdornment, Button, Checkbox, IconButton, FormHelperText } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import * as yup from "yup"
import _ from "@lodash"
import Box from "@mui/system/Box"
import FuseLoading from "@fuse/core/FuseLoading"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import TicketEmailSelector from "./email-selector/TicketEmailSelector"
import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector"
import TicketAttachments from "./TicketAttachments"
import Pdf from "src/13187001.pdf"

const debug = false
let rendering = -1
import { save, close } from "../TicketsAppButtons"

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
	name: yup.string().required("You must enter a name"),
	category: yup.string().required("Category is required"),
})

function TicketForm(props) {
	debug && console.log("<TicketForm>".padEnd(21) + "%cConstructor() -rendering:", "color: pink", ++rendering, { props })
	const ticket = useSelector(selectTicket)
	const countries = useSelector(selectCountries)
	const tags = useSelector(selectTags)
	console.log({ tags })
	const routeParams = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	console.log({ ticket })

	const [age, setAge] = useState("10")

	const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState

	const form = watch()

	useEffect(() => {
		debug && console.log("<TicketForm> %cuseEffect(1) -rendering:", "color: yellow", rendering, routeParams.id)
		if (routeParams.id === "new") {
			dispatch(newTicket())
		} else {
			dispatch(getTicket(routeParams.id))
		}
	}, [dispatch, routeParams])

	useEffect(() => {
		debug && console.log("<TicketForm> %cuseEffect(2) -rendering:", "color: yellow", rendering)
		reset({ ...ticket })
	}, [ticket, reset])

	function getCountryByIso(iso) {
		return countries.find((country) => country.iso === iso)
	}

	/**
	 * Form Submit
	 */
	function onSubmit(data) {
		console.log("onSubmit:", { data })
		if (routeParams.id === "new") {
			dispatch(addTicket(data)).then(({ payload }) => {
				//navigate(`/apps/tickets/${payload.id}`)
				navigate(save(payload.id))
			})
		} else {
			dispatch(updateTicket(data))
		}
	}

	function handleRemoveTicket() {
		dispatch(removeTicket(ticket.id)).then(() => {
			//navigate("/apps/tickets")
			navigate(close())
		})
	}

	if (_.isEmpty(form) || !ticket) {
		return <FuseLoading />
	}

	const handleChange = (event) => {
		setAge(event.target.value)
	}

	const options = [
		{ label: "Option 1", value: "option1" },
		{ label: "Option 2", value: "option2" },
	]

	return (
		<>
			<Box
				className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
				sx={{
					backgroundColor: "background.default",
				}}>
				{ticket.background && <img className="absolute inset-0 object-cover w-full h-full" src={ticket.background} alt="user background" />}
			</Box>

			<div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
				<div className="w-full">
					<div className="flex flex-auto items-end -mt-64">
						<Controller
							control={control}
							name="avatar"
							render={({ field: { onChange, value } }) => (
								<Box
									sx={{
										borderWidth: 4,
										borderStyle: "solid",
										borderColor: "background.paper",
									}}
									className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden">
									<div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
									<div className="absolute inset-0 flex items-center justify-center z-20">
										<div>
											<label htmlFor="button-avatar" className="flex p-8 cursor-pointer">
												<input
													accept="image/*"
													className="hidden"
													id="button-avatar"
													type="file"
													onChange={async (e) => {
														function readFileAsync() {
															return new Promise((resolve, reject) => {
																const file = e.target.files[0]
																if (!file) {
																	return
																}
																const reader = new FileReader()

																reader.onload = () => {
																	resolve(`data:${file.type};base64,${btoa(reader.result)}`)
																}

																reader.onerror = reject

																reader.readAsBinaryString(file)
															})
														}

														const newImage = await readFileAsync()

														onChange(newImage)
													}}
												/>
												<FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
											</label>
										</div>
										<div>
											<IconButton
												onClick={() => {
													onChange("")
												}}>
												<FuseSvgIcon className="text-white">heroicons-solid:trash</FuseSvgIcon>
											</IconButton>
										</div>
									</div>
									<Avatar
										sx={{
											backgroundColor: "background.default",
											color: "text.secondary",
										}}
										className="object-cover w-full h-full text-64 font-bold"
										src={value}
										alt={ticket.name}>
										{ticket.name.charAt(0)}
									</Avatar>
								</Box>
							)}
						/>
					</div>
				</div>

				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Name"
							placeholder="Name"
							id="name"
							error={!!errors.name}
							helperText={errors?.name?.message}
							variant="outlined"
							required
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="category"
					defaultValue=""
					render={({ field }) => (
						<FormControl fullWidth className="mt-32" error={!!errors.category}>
							<InputLabel id="category-label">Category</InputLabel>
							<Select {...field} labelId="category-label" id="category" label="category">
								<MenuItem value="">
									<em> -none- </em>
								</MenuItem>
								{options.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
							{errors.category && <FormHelperText>{errors.category.message}</FormHelperText>}
						</FormControl>
					)}
				/>

				<Controller
					control={control}
					name="age"
					render={({ field: { onChange, value } }) => (
						<FormControl fullWidth className="mt-32">
							<InputLabel id="demo-simple-select-label">Aging</InputLabel>
							<Select labelId="demo-simple-select-label" id="age" value={age} label="Age" onChange={handleChange}>
								<MenuItem value={10}>Ten</MenuItem>
								<MenuItem value={20}>Twenty</MenuItem>
								<MenuItem value={30}>Thirty</MenuItem>
							</Select>
						</FormControl>
					)}
				/>

				<Controller
					control={control}
					name="tags"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple
							id="tags"
							className="mt-32"
							options={tags}
							disableCloseOnSelect
							getOptionLabel={(option) => option.title}
							renderOption={(_props, option, { selected }) => (
								<li {..._props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.title}
								</li>
							)}
							value={value ? value.map((id) => _.find(tags, { id })) : []}
							onChange={(event, newValue) => {
								newValue.map((item) => {
									console.log(item.id)
								})
								onChange(newValue.map((item) => item.id))
							}}
							fullWidth
							renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
						/>
					)}
				/>
				<Controller
					control={control}
					name="title"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Title"
							placeholder="Job title"
							id="title"
							error={!!errors.title}
							helperText={errors?.title?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:briefcase</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				<Controller
					control={control}
					name="company"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Company"
							placeholder="Company"
							id="company"
							error={!!errors.company}
							helperText={errors?.company?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:office-building</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				<Controller control={control} name="emails" render={({ field }) => <TicketEmailSelector className="mt-32" {...field} />} />
				<Controller control={control} name="phoneNumbers" render={({ field }) => <PhoneNumberSelector className="mt-32" {...field} />} />
				<Controller
					control={control}
					name="address"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Address"
							placeholder="Address"
							id="address"
							error={!!errors.address}
							helperText={errors?.address?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:location-marker</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				<Controller
					control={control}
					name="birthday"
					render={({ field: { value, onChange } }) => (
						<DateTimePicker
							value={new Date(value)}
							onChange={onChange}
							className="mt-32 mb-16 w-full"
							clearable
							slotProps={{
								textField: {
									id: "birthday",
									label: "Birthday",
									InputLabelProps: {
										shrink: true,
									},
									fullWidth: true,
									variant: "outlined",
								},
								actionBar: {
									actions: ["clear", "today"],
								},
							}}
							slots={{
								openPickerIcon: () => <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>,
							}}
						/>
					)}
				/>
				<Controller
					control={control}
					name="notes"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Notes"
							placeholder="Notes"
							id="notes"
							error={!!errors.notes}
							helperText={errors?.notes?.message}
							variant="outlined"
							fullWidth
							multiline
							minRows={5}
							maxRows={10}
							InputProps={{
								className: "max-h-min h-min items-start",
								startAdornment: (
									<InputAdornment className="mt-16" position="start">
										<FuseSvgIcon size={20}>heroicons-solid:menu-alt-2</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
				<TicketAttachments className="mt-32" />

				<a href={Pdf} rel="noopener noreferrer" target="_blank">
					<button variant="contained" color="secondary" label="Resume">
						PDF
					</button>
				</a>
			</div>

			<Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t" sx={{ backgroundColor: "background.default" }}>
				{routeParams.id !== "new" && (
					<Button color="error" onClick={handleRemoveTicket}>
						Delete
					</Button>
				)}
				<Button className="ml-auto" component={NavLinkAdapter} to={-1}>
					Cancel
				</Button>
				<Button className="ml-8" variant="contained" color="secondary" disabled={_.isEmpty(dirtyFields) || !isValid} onClick={handleSubmit(onSubmit)}>
					Save
				</Button>
			</Box>
		</>
	)
}

export default TicketForm
