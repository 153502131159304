import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import FuseUtils from "@fuse/utils"
import { addTicket, removeTicket, updateTicket } from "./ticketSlice"
const debug = process.env.NODE_ENV === "development" && false

export const getTickets = createAsyncThunk("ticketsApp/tickets/getTickets", async (params, { getState }) => {
	debug && console.log("getTickets(ticketsApp/tickets/getTickets) -params: ", params)
	const response = await axios.get("/api/tickets")

	const data = await response.data
	//debug && console.log(data)
	return { data }
})

const ticketsAdapter = createEntityAdapter({})

export const selectSearchText = ({ ticketsApp }) => ticketsApp.tickets.searchText

export const { selectAll: selectTickets, selectById: selectTicketsById } = ticketsAdapter.getSelectors((state) => state.ticketsApp.tickets)

export const selectFilteredTickets = createSelector([selectTickets, selectSearchText], (tickets, searchText) => {
	if (searchText.length === 0) {
		return tickets
	}
	return FuseUtils.filterArrayByString(tickets, searchText)
})

export const selectGroupedFilteredTickets = createSelector([selectFilteredTickets], (tickets) => {
	return tickets
		.sort((a, b) => a.name.localeCompare(b.name, "es", { sensitivity: "base" }))
		.reduce((r, e) => {
			// get first letter of name of current element
			const group = e.name[0]
			// if there is no property in accumulator with this letter create it
			if (!r[group]) r[group] = { group, children: [e] }
			// if there is push current element to children array for that letter
			else r[group].children.push(e)
			// return accumulator
			return r
		}, {})
})

const ticketsSlice = createSlice({
	name: "ticketsApp/tickets",
	initialState: ticketsAdapter.getInitialState({
		searchText: "",
	}),
	reducers: {
		setTicketsSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateTicket.fulfilled, ticketsAdapter.upsertOne)
			.addCase(addTicket.fulfilled, ticketsAdapter.addOne)
			.addCase(removeTicket.fulfilled, (state, action) => ticketsAdapter.removeOne(state, action.payload))
			.addCase(getTickets.fulfilled, (state, action) => {
				const { data, routeParams } = action.payload
				ticketsAdapter.setAll(state, data)
				state.searchText = ""
			})
	},
	/*extraReducers: {
		[updateTicket.fulfilled]: ticketsAdapter.upsertOne,
		[addTicket.fulfilled]: ticketsAdapter.addOne,
		[removeTicket.fulfilled]: (state, action) => ticketsAdapter.removeOne(state, action.payload),
		[getTickets.fulfilled]: (state, action) => {
			const { data, routeParams } = action.payload
			ticketsAdapter.setAll(state, data)
			state.searchText = ""
		},
	},*/
})

export const { setTicketsSearchText } = ticketsSlice.actions

export default ticketsSlice.reducer
