import { memo, useState, useContext, useLayoutEffect } from "react"
import { useSilentSelector } from "@fuse/hooks"
import { useNavigate, useLocation, matchRoutes } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectTabs, updateUserTabs, updateUserTabIndex, deleteUserTabs } from "app/store/fuse/tabsSlice"
import { selectNavigation } from "app/store/fuse/navigationSlice"
import AppContext from "app/AppContext"
import FuseUtils from "@fuse/utils"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import _ from "@lodash"

const debug = false

const TabsLayout5 = () => {
	debug && console.log("<TabsLayout5>".padEnd(14) + "%cConstructor()", "color: magenta")
	const [hoveredTab, setHoveredTab] = useState(null)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { currentTabIndex, list } = useSelector(selectTabs)
	debug && console.log("<TabsLayout5>".padEnd(14) + "%ccurrentTabIndex: " + currentTabIndex + " list:", "color: magenta", list)
	const navigation = useSilentSelector(selectNavigation) // instead of useSelector(selectNavigation)
	const appContext = useContext(AppContext)
	const { pathname } = useLocation()

	const { routes } = appContext
	const matchingRoutes = matchRoutes(routes, pathname)
	const matchedRoute = matchingRoutes ? matchingRoutes[0] : false

	const currentRoute = FuseUtils.findMatchRoute(pathname, matchedRoute)
	debug && console.log("<TabsLayout5>".padEnd(14) + "%ccurrentRoute -baspath: " + currentRoute.basepath + " -url: " + currentRoute.url, "color: magenta", "-match?", currentRoute.basepath === currentRoute.url)

	/* debug 
	const previousCurrentTabIndex = usePrevious(currentTabIndex)
	const previousList = usePrevious(list)
	const previousNavigation = usePrevious(navigation)
	debug && previousCurrentTabIndex !== currentTabIndex && console.debug("\t%ccurrentTabIndex has changed from", "color: grey", previousCurrentTabIndex, "to", currentTabIndex)
	debug && !_.isEqual(previousList, list) && console.debug("\t%clist has changed from", "color: grey", previousList, "to", list)
	debug && !_.isEqual(previousNavigation, navigation) && console.debug("\t%cnavigation has changed from", "color: grey", previousNavigation, "to", navigation)
	*/ //debug

	// This runs before the browser paints (useLayoutEffect)
	useLayoutEffect(() => {
		const matchTabIndex = list.findIndex((tab) => tab.basepath === currentRoute.basepath)
		debug && console.log("<TabsLayout5>".padEnd(14) + "%cuseLayoutEffect -matchTabIndex:", "color: magenta", matchTabIndex)
		const matchTab = list[matchTabIndex]
		debug && console.log("<TabsLayout5>".padEnd(14) + "%cuseLayoutEffect -matchTab:", "color: magenta", matchTab ? true : false)

		if (matchTab) {
			// If the current tab is different, update the tab index
			if (matchTabIndex !== currentTabIndex) {
				debug && console.log("<TabsLayout5> useLayoutEffect() -updating currentTabIndex:", currentTabIndex, "->", matchTabIndex)
				dispatch(updateUserTabIndex(matchTabIndex))
			}
		} else {
			// If no matching tab is found, attempt to add a new tab based on the current route
			const link = FuseUtils.findObjecByPropertyValue(navigation, "url", currentRoute.basepath)
			if (link) {
				debug && console.log("<TabsLayout5>".padEnd(14) + "%cuseLayoutEffect() -link:", "color: magenta", link)
				const newTab = {
					id: link.id,
					title: link.title,
					icon: link.icon,
					basepath: currentRoute.basepath,
					url: currentRoute.url,
				}
				debug && console.log("<TabsLayout5>".padEnd(14) + "%cuseLayoutEffect -add new tab:", "color: magenta", true)
				dispatch(updateUserTabs(newTab))
			} else {
				debug && console.log("<TabsLayout5>".padEnd(14) + "%cuseLayoutEffect() -fallback to initialState", "color: magenta")
			}
		}
	}, [currentRoute, list, currentTabIndex, dispatch])

	const handleMouseHover = (index) => {
		debug && console.log("<TabsLayout5> handleMouseHover() -hoveredTab:", hoveredTab, " -count:", list.length)
		setHoveredTab(index)
	}

	const handleMouseOut = () => {
		debug && console.log("<TabsLayout5>: handleMouseOut() -hoveredTab: ", hoveredTab)
		setHoveredTab(null)
	}

	const handleTabChange = (SyntheticEvent, index) => {
		dispatch(updateUserTabIndex(index))

		debug && console.log(`<TabsLayout5> %chandleTabChange(${currentTabIndex} -> ${index}) -navigate to: ${list[index].url}`, "color: grey")
		navigate(list[index].url)
	}

	const handleDeleteTab = (event, index) => {
		debug && console.log(`<TabsLayout5> handleDeleteTab(${index}) -indexToDelete:`, index)
		event.stopPropagation()

		setHoveredTab(null)

		if (list.length === 1) return
		dispatch(deleteUserTabs(index))

		if (currentTabIndex === index) {
			// Ensure we navigate to a valid tab
			const nextIndex = index === list.length - 1 ? index - 1 : index + 1
			if (list[nextIndex]) {
				navigate(list[nextIndex].basepath)
			}
		}
	}

	const renderTabIcon = (tab, index) => (
		<div onMouseEnter={() => handleMouseHover(index)} onMouseLeave={handleMouseOut}>
			{hoveredTab === index && list.length > 1 ? (
				<FuseSvgIcon size={16} color="error" onClick={(event) => handleDeleteTab(event, index)}>
					heroicons-outline:x-circle
				</FuseSvgIcon>
			) : (
				<FuseSvgIcon size={16} color="inherit">
					{tab.icon}
				</FuseSvgIcon>
			)}
		</div>
	)

	return (
		<Tabs
			indicatorColor="primary"
			value={currentTabIndex}
			onChange={handleTabChange}
			variant="scrollable"
			scrollButtons="auto"
			allowScrollButtonsMobile
			aria-label="Portal tabs"
			aria-controls={`tabpanel-${currentTabIndex}`}
			aria-selected={true}
			textColor="secondary"
			classes={{ root: "w-full border-b-1 min-h-32" }}
			//
		>
			{list.map((tab, index) => (
				<Tab
					classes={{ root: "min-h-32 md:min-h-32" }}
					key={index}
					label={tab.title}
					iconPosition="start"
					icon={renderTabIcon(tab, index)}
					aria-controls={`tabpanel-${index}`}
					aria-selected={currentTabIndex === index}
					//
				/>
			))}
		</Tabs>
	)
}

export default memo(TabsLayout5)
