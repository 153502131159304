import "@mock-api" //FIXME REMOVE
import BrowserRouter from "@fuse/core/BrowserRouter"
import FuseLayout from "@fuse/core/FuseLayout"
import FuseTheme from "@fuse/core/FuseTheme"
import createCache from "@emotion/cache"
import themeLayouts from "app/theme-layouts/themeLayouts"
import FuseAuthorization from "@fuse/core/FuseAuthorization"
import withAppProviders from "./withAppProviders"
import { SnackbarProvider } from "notistack"
import { SnackbarUtilsConfigurator } from "../snackbarUtils"
import { useSelector } from "react-redux"
import { CacheProvider } from "@emotion/react"
import { selectUser } from "app/store/userSlice"
import { selectMainTheme } from "app/store/fuse/settingsSlice"
import { AuthProvider } from "./auth/AuthProvider"

const debug = process.env.NODE_ENV === "development" && false

const emotionCacheOptions = {
	key: "muiltr",
	stylisPlugins: [],
	insertionPoint: document.getElementById("emotion-insertion-point"),
}

/**
 * Starting app
 * @returns
 */
function App() {
	{
		debug && console.log("%c+++++ <App> +++++ Load dev pages: " + process.env.REACT_APP_ENABLE_DEV_PAGES, "color: green")
	}
	const user = useSelector(selectUser)
	const mainTheme = useSelector(selectMainTheme)

	return (
		<CacheProvider value={createCache(emotionCacheOptions)}>
			<FuseTheme theme={mainTheme}>
				<AuthProvider>
					<BrowserRouter>
						{debug && console.log("<App>", { user })}
						<FuseAuthorization userActive={user.active} userRole={user.role} loginRedirectUrl={user.loginRedirectUrl}>
							<SnackbarProvider
								maxSnack={5}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								classes={{
									containerRoot: "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
								}}>
								<SnackbarUtilsConfigurator />
								<FuseLayout layouts={themeLayouts} />
							</SnackbarProvider>
						</FuseAuthorization>
					</BrowserRouter>
				</AuthProvider>
			</FuseTheme>
		</CacheProvider>
	)
}

export default withAppProviders(App)()
