import AppBar from "@mui/material/AppBar"
import { ThemeProvider } from "@mui/material/styles"
import Toolbar from "@mui/material/Toolbar"
import { memo } from "react"
import { useSelector } from "react-redux"
import { selectFooterTheme } from "app/store/fuse/settingsSlice"
import clsx from "clsx"
import PoweredByLinks from "../../shared-components/PoweredByLinks"
import Typography from "@mui/material/Typography"
import DocumentationButton from "../../shared-components/DocumentationButton"

function FooterLayout5(props) {
	const footerTheme = useSelector(selectFooterTheme)

	return (
		<ThemeProvider theme={footerTheme}>
			<AppBar
				id="fuse-footer"
				className={clsx("relative z-20 shadow-md", props.className)}
				color="default"
				sx={{
					backgroundColor: (theme) => (theme.palette.mode === "light" ? footerTheme.palette.background.paper : footerTheme.palette.background.default),
				}}>
				<Toolbar className="min-h-48 md:min-h-64 px-8 sm:px-12 py-0 flex items-center overflow-x-auto">
					<div className="flex grow shrink-0">
						<Typography variant="caption">Copyright © 2024 | Crowd Service Scarl</Typography>
					</div>

					<div className="flex grow shrink-0 px-12 justify-end"></div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	)
}

export default memo(FooterLayout5)
