import "@mock-api" //FIXME REMOVE
import BrowserRouter from "@fuse/core/BrowserRouter"
import FuseLayout from "@fuse/core/FuseLayout"
import FuseTheme from "@fuse/core/FuseTheme"
import { SnackbarProvider } from "notistack"
import { useSelector } from "react-redux"
//import rtlPlugin from "stylis-plugin-rtl"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
//import { selectCurrentLanguageDirection } from "app/store/i18nSlice"
import { selectUser } from "app/store/userSlice"
import themeLayouts from "app/theme-layouts/themeLayouts"
import { selectMainTheme } from "app/store/fuse/settingsSlice"
import FuseAuthorization from "@fuse/core/FuseAuthorization"
import settingsConfig from "app/configs/settingsConfig"
import withAppProviders from "./withAppProviders"
import { AuthProvider } from "./auth/AuthProvider"
const debug = true
/*const emotionCacheOptions = {
	rtl: {
		key: "muirtl",
		stylisPlugins: [rtlPlugin],
		insertionPoint: document.getElementById("emotion-insertion-point"),
	},
	ltr: {
		key: "muiltr",
		stylisPlugins: [],
		insertionPoint: document.getElementById("emotion-insertion-point"),
	},
}*/

const emotionCacheOptions = {
	key: "muiltr",
	stylisPlugins: [],
	insertionPoint: document.getElementById("emotion-insertion-point"),
}

/**
 * Starting app
 * @returns
 */
function App() {
	{
		debug && console.log("%c+++++ <App> +++++", "color: green")
		//debug && console.log(process.env)
	}
	const user = useSelector(selectUser)
	//const langDirection = useSelector(selectCurrentLanguageDirection)
	//console.log("langDirection", langDirection)
	const mainTheme = useSelector(selectMainTheme)

	return (
		<CacheProvider value={createCache(emotionCacheOptions)}>
			<FuseTheme theme={mainTheme}>
				<AuthProvider>
					<BrowserRouter>
						{/*debug && console.log("<App> -loginRedirectUrl: " + settingsConfig.loginRedirectUrl)*/}
						<FuseAuthorization userActive={user.active} userRole={user.role} loginRedirectUrl={settingsConfig.loginRedirectUrl}>
							<SnackbarProvider
								maxSnack={5}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								classes={{
									containerRoot: "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
								}}>
								<FuseLayout layouts={themeLayouts} />
							</SnackbarProvider>
						</FuseAuthorization>
					</BrowserRouter>
				</AuthProvider>
			</FuseTheme>
		</CacheProvider>
	)
}

export default withAppProviders(App)()
