import Button from "@mui/material/Button"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect } from "react"
import FuseLoading from "@fuse/core/FuseLoading"
import _ from "@lodash"
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import Box from "@mui/system/Box"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import Autocomplete from "@mui/material/Autocomplete/Autocomplete"
import Checkbox from "@mui/material/Checkbox/Checkbox"
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker"
import { addContact, getContact, newContact, removeContact, selectContact, updateContact } from "../store/contactSlice"
import { selectCountries } from "../store/countriesSlice"
import { selectTags } from "../store/tagsSlice"
//import ContactEmailSelector from "./email-selector/ContactEmailSelector"
import PhoneNumberSelector from "./phone-number-selector/PhoneNumberSelector"
const debug = process.env.NODE_ENV === "development" && true

const schema = yup.object().shape({
	data: yup.object().shape({
		displayName: yup.string().required("You must enter a name"),
		email: yup.string().email("Invalid email").required("Email is required"),
		company: yup.string().nullable(),
		title: yup.string().nullable(),
		address: yup.string().nullable(),
		birthday: yup.date().nullable(),
		notes: yup.string(), //.nullable(),
	}),
})

/*const schema = yup.object().shape({
	name: yup.string().required("You must enter a name"),
})*/

function ContactForm(props) {
	const contact = useSelector(selectContact)
	const countries = useSelector(selectCountries)
	const tags = useSelector(selectTags)
	const routeParams = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { control, watch, reset, handleSubmit, formState, getValues } = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
	})

	const { isValid, dirtyFields, errors } = formState
	const form = watch()

	console.log({ isValid, dirtyFields, form })

	useEffect(() => {
		debug && console.log("1.useEffect()", contact)
		if (routeParams.id === "new") {
			dispatch(newContact())
		} else {
			dispatch(getContact(routeParams.id))
		}
	}, [dispatch, routeParams])

	useEffect(() => {
		debug && console.log("2.useEffect()", { contact })
		if (contact) {
			reset({
				role: contact.role || "",
				active: contact.active ?? true,
				loginRedirectUrl: contact.loginRedirectUrl || "",
				data: {
					photoURL: contact.data?.photoURL || "", // qui serve!
					displayName: contact.data?.displayName || "",
					email: contact.data?.email || "",
					company: contact.data?.company || "",
					title: contact.data?.title || "",
					address: contact.data?.address || "",
					birthday: contact.data?.birthday || null,
					notes: contact.data?.notes || "",
				},
			})
		}
		//reset({ ...contact })
	}, [contact, reset])

	console.log({ contact })

	function getCountryByIso(iso) {
		return countries.find((country) => country.iso === iso)
	}

	function onSubmit(data) {
		debug && console.log("onSubmit:", { data })
		if (routeParams.id === "new") {
			dispatch(addContact(data)).then(({ payload }) => {
				navigate(`/apps/contacts/${payload.id}`)
			})
		} else {
			dispatch(updateContact(data))
		}
	}

	function handleRemoveContact() {
		dispatch(removeContact(contact.id)).then(() => {
			navigate("/apps/contacts")
		})
	}

	if (_.isEmpty(form) || !contact) {
		return <FuseLoading />
	}

	return (
		<>
			<Box
				className="relative w-full h-160 sm:h-192 px-32 sm:px-48"
				sx={{
					backgroundColor: "background.default",
				}}>
				{contact.background && <img className="absolute inset-0 object-cover w-full h-full" src={contact.background} alt="user background" />}
			</Box>

			<div className="relative flex flex-col flex-auto items-center px-24 sm:px-48">
				<div className="w-full">
					<div className="flex flex-auto items-end -mt-64">
						<Controller
							control={control}
							name="data.photoURL"
							render={({ field: { onChange, value } }) => (
								<Box
									sx={{
										borderWidth: 4,
										borderStyle: "solid",
										borderColor: "background.paper",
									}}
									className="relative flex items-center justify-center w-128 h-128 rounded-full overflow-hidden">
									<div className="absolute inset-0 bg-black bg-opacity-50 z-10" />
									<div className="absolute inset-0 flex items-center justify-center z-20">
										<div>
											<label htmlFor="button-avatar" className="flex p-8 cursor-pointer">
												<input
													accept="image/*"
													className="hidden"
													id="button-avatar"
													type="file"
													onChange={async (e) => {
														function readFileAsync() {
															return new Promise((resolve, reject) => {
																const file = e.target.files[0]
																if (!file) {
																	return
																}
																const reader = new FileReader()

																reader.onload = () => {
																	resolve(`data:${file.type};base64,${btoa(reader.result)}`)
																}

																reader.onerror = reject

																reader.readAsBinaryString(file)
															})
														}

														const newImage = await readFileAsync()

														onChange(newImage)
													}}
												/>
												<FuseSvgIcon className="text-white">heroicons-outline:camera</FuseSvgIcon>
											</label>
										</div>
										<div>
											<IconButton
												onClick={() => {
													onChange("")
												}}>
												<FuseSvgIcon className="text-white">heroicons-solid:trash</FuseSvgIcon>
											</IconButton>
										</div>
									</div>
									<Avatar
										sx={{
											backgroundColor: "background.default",
											color: "text.secondary",
										}}
										className="object-cover w-full h-full text-64 font-bold"
										src={value || undefined}
										alt={contact.data.displayName}>
										{contact.data.displayName.charAt(0)}
									</Avatar>
								</Box>
							)}
						/>
					</div>
				</div>

				<Controller
					control={control}
					name="data.displayName"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Name"
							placeholder="Name"
							id="name"
							error={!!errors?.data?.displayName}
							helperText={errors?.data?.displayName?.message}
							//error={!!errors.name}
							//helperText={errors?.name?.message}
							variant="outlined"
							required
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:user-circle</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="data.tags"
					render={({ field: { onChange, value } }) => (
						<Autocomplete
							multiple
							id="tags"
							className="mt-32"
							options={tags}
							disableCloseOnSelect
							getOptionLabel={(option) => option.title}
							renderOption={(_props, option, { selected }) => (
								<li {..._props}>
									<Checkbox style={{ marginRight: 8 }} checked={selected} />
									{option.title}
								</li>
							)}
							value={value ? value.map((id) => _.find(tags, { id })) : []}
							onChange={(event, newValue) => {
								onChange(newValue.map((item) => item.id))
							}}
							fullWidth
							renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
						/>
					)}
				/>

				<Controller
					control={control}
					name="data.title"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Title"
							placeholder="Job title"
							id="title"
							error={!!errors?.data?.title}
							helperText={errors?.data?.title?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:briefcase</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="data.company"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Company"
							placeholder="Company"
							id="company"
							error={!!errors?.data?.company}
							helperText={errors?.data?.company?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:office-building</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				<Controller
					control={control}
					name="data.email"
					render={({ field }) => (
						<TextField
							{...field}
							className="mt-32"
							label="Email"
							variant="outlined"
							fullWidth
							error={!!errors?.data?.email}
							helperText={errors?.data?.email?.message} //
						/>
					)}
				/>

				{/*<Controller control={control} name="emails" render={({ field }) => <ContactEmailSelector className="mt-32" {...field} />} />*/}

				{/*<Controller control={control} name="phoneNumbers" render={({ field }) => <PhoneNumberSelector className="mt-32" {...field} />} />*/}

				<Controller
					control={control}
					name="data.address"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Address"
							placeholder="Address"
							id="address"
							error={!!errors?.data?.address}
							helperText={errors?.data?.address?.message}
							variant="outlined"
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FuseSvgIcon size={20}>heroicons-solid:location-marker</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>

				{/*<Controller
					control={control}
					name="data.birthday"
					render={({ field: { value, onChange } }) => (
						<DateTimePicker
							value={new Date(value)}
							onChange={onChange}
							className="mt-32 mb-16 w-full"
							clearable
							slotProps={{
								textField: {
									id: "birthday",
									label: "Birthday",
									InputLabelProps: {
										shrink: true,
									},
									fullWidth: true,
									variant: "outlined",
								},
								actionBar: {
									actions: ["clear", "today"],
								},
							}}
							slots={{
								openPickerIcon: () => <FuseSvgIcon size={20}>heroicons-solid:cake</FuseSvgIcon>,
							}}
						/>
					)}
				/>*/}

				<Controller
					control={control}
					name="data.notes"
					render={({ field }) => (
						<TextField
							className="mt-32"
							{...field}
							label="Notes"
							placeholder="Notes"
							id="notes"
							error={!!errors?.data?.notes}
							helperText={errors?.data?.notes?.message}
							variant="outlined"
							fullWidth
							multiline
							minRows={5}
							maxRows={10}
							InputProps={{
								className: "max-h-min h-min items-start",
								startAdornment: (
									<InputAdornment className="mt-16" position="start">
										<FuseSvgIcon size={20}>heroicons-solid:menu-alt-2</FuseSvgIcon>
									</InputAdornment>
								),
							}}
						/>
					)}
				/>
			</div>

			<Box className="flex items-center mt-40 py-14 pr-16 pl-4 sm:pr-48 sm:pl-36 border-t" sx={{ backgroundColor: "background.default" }}>
				{routeParams.id !== "new" && (
					<Button color="error" onClick={handleRemoveContact}>
						Delete
					</Button>
				)}
				<Button className="ml-auto" component={NavLinkAdapter} to={-1}>
					Cancel
				</Button>
				<Button className="ml-8" variant="contained" color="secondary" disabled={_.isEmpty(dirtyFields) || !isValid} onClick={handleSubmit(onSubmit)}>
					Save
				</Button>
			</Box>
		</>
	)
}

export default ContactForm
