import _ from '@lodash'

const ContainerModel = (data) =>
	_.defaults(data || {}, {
		type: 'container',
		title: '',
		notes: '',
		completed: false,
		//dueDate: null,
		//priority: 0,
		tags: [],
		roles: null,
		//assignedTo: null,
		subItems: [],
		order: 0,
	})

export default ContainerModel
