export const en = {
	APPLICATIONS: "Applications",
	DASHBOARDS: "Dashboards",
	CALENDAR: "Calendar",
	ECOMMERCE: "E-Commerce",
	ELOGISTIC: "Canon Logistics",
	GENERAL_DASHBOARD: "Dashboard",
	ACADEMY: "Academy",
	MAIL: "Mail",
	TASKS: "Tasks",
	DMS: "Document Manager",
	FILE_MANAGER: "File Manager",
	CONTACTS: "Users",
	TICKETS: "Tickets",
	CHAT: "Chat",
	SCRUMBOARD: "Scrumboard",
	NOTES: "Notes",
}

export const it = {
	APPLICATIONS: "Applicazioni",
	DASHBOARDS: "Pannelli",
	CALENDAR: "Calendario",
	ECOMMERCE: "Commerce",
	ELOGISTIC: "Logistica Canon",
	GENERAL_DASHBOARD: "Dashboard",
	Manifests: "Manifesti",
	Projects: "Progetti",
	Deliveries: "Consegne",
	ACADEMY: "Accademia",
	MAIL: "Posta",
	TASKS: "Attività",
	DMS: "Gestione Documenti",
	FILE_MANAGER: "Gestione File",
	CONTACTS: "Utenti",
	TICKETS: "Chiamate",
	CHAT: "Conversazioni",
	SCRUMBOARD: "Scrum Board",
	NOTES: "Note",
}
