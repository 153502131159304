import FuseUtils from "@fuse/utils/FuseUtils"
import axios from "@fuse/axios"
import jwtDecode from "jwt-decode"
import jwtServiceConfig from "./jwtServiceConfig"
import { getSessionDeviceId, setSessionDeviceId, getSessionCookie, setSessionCookie, resetSessionCookie } from "./sessionCookie"
import FingerprintJS from "@fingerprintjs/fingerprintjs"
import { AES, enc } from "crypto-js"

const debug = process.env.NODE_ENV === "development" && false

class JwtService extends FuseUtils.EventEmitter {
	deviceId = null

	init() {
		//debug && console.log("<JwtService> -init()")
		this.setInterceptors()
		this.handleAuthentication()
	}

	setInterceptors = () => {
		//debug && console.log("<JwtService> -setInterceptors()")
		axios.interceptors.response.use(
			(response) => {
				return response
			},
			(err) => {
				//debug && console.log(`<JwtService> -setInterceptors.err(${err.response.status})`)
				return new Promise((resolve, reject) => {
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						debug && console.log("<JwtService> -setInterceptors.Promise.err(emit -> onAutoLogout)", err)

						resetSessionCookie()
						this.emit("onAutoLogout", "Invalid accessToken")
					} else if (err.response.status === 504 && err.config && !err.config.__isRetryRequest) {
						debug && console.log("<JwtService> -setInterceptors.Promise.err(Timeout)")
						return Promise.resolve("Timeout")
					}
					return Promise.reject(err) // Assicura che l'errore venga propagato
				})
			}
		)
	}

	async getDeviceId() {
		let deviceId = getSessionDeviceId()
		if (!deviceId) {
			// Inizializza FingerprintJS (la prima chiamata è asincrona)
			const fpPromise = FingerprintJS.load()
			const fp = await fpPromise
			const fingerprintResult = await fp.get()
			deviceId = fingerprintResult.visitorId
			setSessionDeviceId(deviceId)
		}
		//debug && console.log("<JwtService> -getDeviceId():", deviceId)
		return deviceId
	}

	handleAuthentication = async () => {
		debug && console.log("<JwtService> -handleAuthentication() - " + (FuseUtils.isWPA() ? "WPA/" : "Browser/") + (FuseUtils.isMobile() ? "Mobile" : "Desktop"))
		// REQUEST TOKEN (Sign-up)
		const request_token = this.getRequestToken()
		if (request_token) {
			const { isValid, data } = this.isAuthTokenValid(request_token)

			if (isValid) {
				debug && console.log("<JwtService> -handleAuthentication() %c- Pass - request_token is valid", "color: green;")
				this.setSessionTokens({
					deviceId: await this.getDeviceId(),
					accessToken: request_token,
					refreshToken: null,
					info: data.info,
				})
			} else {
				debug && console.log("<JwtService> -handleAuthentication() %c- Fail - request_token invalid", "color: orange;")
				resetSessionCookie()
				this.emit("onAutoLogout", "request_token invalid")
				/*if (FuseUtils.isWPA() && FuseUtils.isMobile()) {
					this.signout()
				}*/
				return
			}
		}

		// Get Auth Tokens from localStorage
		const { deviceId, accessToken, refreshToken } = this.getAuthTokens()

		// ACCESS TOKEN (Sign-in with token)
		if (!accessToken) {
			// User has logged out or never logged in. No access at all. Redirect to Sign-in again
			debug && console.log("<JwtService> -handleAuthentication() %caccessToken is null (emit -> onNoAccessToken)", "color: yellow;")
			this.emit("onNoAccessToken", "accessToken is null")
			return
		}
		let token = this.isAuthTokenValid(accessToken)
		if (token.isValid) {
			if (token.data.info) {
				//FIXME Serve?
				debug && console.log("%c<JwtService> -token.data.info", "color: grey;", token.data.info)
				const error = []
				error.push({
					type: "email",
					message: "Warning. " + token.data.info,
				})
				//this.emit("onAutoLogin", false)
			}
			debug && console.log("<JwtService> %c-accessToken is valid (emit -> onAutoLogin)", "color: green;")
			this.emit("onAutoLogin", true)
			return
		} else {
			debug && console.log("<JwtService> %c-accessToken is not valid (try with refreshToken)", "color: cyan;")
		}

		// REFRESH TOKEN (Sign-in with refresh token)
		if (!refreshToken) {
			// No refresh token usefull to generate access token...  Redirect to Sign-in again
			debug && console.log("%c<JwtService> -refreshToken is null (emit -> onNoRefreshToken)", "color: green;")
			this.emit("onNoRefreshToken", "refreshToken is null")
			return
		}
		token = this.isAuthTokenValid(refreshToken)
		if (token.isValid) {
			debug && console.log("<JwtService>%c -refreshToken is valid (try -> signInWithRefreshToken)", "color: cyan;")
			this.signInWithRefreshToken({ deviceId, refreshToken })
				.then((res) => {
					debug && console.log("<JwtService> -signInWithRefreshToken()%c.then(onAutoLogin -> signInWithToken)", "color: cyan;")
					this.emit("onAutoLogin", true) // => call signInWithToken() in jwtService using new Token
				})
				.catch((error) => {
					debug && console.error("<JwtService> -signInWithRefreshToken().catch(-> emit onAutoLogout)", error)

					resetSessionCookie()
					this.emit("onAutoLogout", "accessToken expired")
				})
		} else {
			debug && console.log("%c<JwtService> -refreshToken expired (emit -> onAutoLogout -> signInWithEmailAndPassword)", "color: red;")

			resetSessionCookie()
			this.emit("onAutoLogout", "accessToken expired")
		}
	}

	getLocalStorage = () => {
		const cipherContent = getSessionCookie() || null
		if (!cipherContent) {
			debug && console.log("<JwtService> -getLocalStorage()%c - Fail - No cookie found", "color: yellow;")
			return null
		}
		const bytes = AES.decrypt(cipherContent, process.env.REACT_APP_COOKIE_SECRET)
		const content = bytes.toString(enc.Utf8)

		const result = {}
		const elements = content.split(",")
		elements.forEach((element) => {
			const v = element.split("=")
			result[v[0]] = v[1]
		})

		return result
	}

	setLocalStorage = (data) => {
		debug && console.log(`<JwtService> -setLocalStorage( %cdeviceId: ${data.deviceId}`, "color: cyan;", `)`, { data })
		if (data && typeof data === "object") {
			const content = `deviceId=${data.deviceId},accessToken=${data.accessToken},refreshToken=${data.refreshToken}`
			if (data.info) content += `,warning=${data.info}`
			//debug && console.log("<JwtService> -setLocalStorage()", { deviceId: data.deviceId })
			const cipherContent = AES.encrypt(content || data, process.env.REACT_APP_COOKIE_SECRET).toString()
			setSessionCookie(cipherContent)
		}
	}

	setSessionTokens = (data) => {
		debug && console.log("<JwtService> -setSessionTokens()", { data })
		if (!data || typeof data !== "object") {
			// something went wrong...
			debug && console.log("<JwtService> -setSessionTokens()%c - Fail - Invalid tokens received. Set as expired.", "color: grey;", data)

			resetSessionCookie()
			return
		}
		const { accessToken, refreshToken, deviceId, info } = data

		//if (accessToken && refreshToken && deviceId) {
		if (accessToken && deviceId) {
			axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
			debug && console.log("<JwtService> -setSessionTokens()%c - Pass - Axios Authorization set to Bearer", "color: green;")

			this.setLocalStorage(data)
		} else {
			delete axios.defaults.headers.common.Authorization
			debug && console.log("<JwtService> -setSessionTokens()%c - Fail - Tokens removed", "color: grey;")

			resetSessionCookie()
		}
	}

	signInWithRefreshToken = (data) => {
		debug &&
			console.log(`%c<JwtService> -signInWithRefreshToken().Promise.post(${jwtServiceConfig.refreshToken})`, "color: orange;", {
				data,
			})
		return new Promise((resolve, reject) => {
			axios
				.post(jwtServiceConfig.refreshToken, {
					data,
				})
				.then((response) => {
					debug && console.log("<JwtService> -signInWithRefreshToken().then(data):", { data: response.data })
					// Verifica se response.data è definito e contiene i token
					if (!response.data || typeof response.data !== "object") {
						debug && console.log("<JwtService>%c Invalid response data format", "color: orange;")

						resetSessionCookie()
						return reject(new Error("Invalid response format from refresh token API."))
					}
					const accessToken = response.data?.accessToken
					const new_refreshToken = response.data?.refreshToken

					if (accessToken && new_refreshToken) {
						debug && console.log("<JwtService> -signInWithRefreshToken%c Tokens found...", "color: green;")
						this.setSessionTokens(response.data)
						resolve(response.data.user || null)
					} else {
						debug && console.log("<JwtService>%c Failed to refresh token: Missing refresh token", "color: orange;")

						resetSessionCookie()
						reject(new Error("Failed to refresh token: Missing refresh token."))
					}
				})
				.catch((error) => {
					debug && console.log("%c<JwtService> -signInWithRefreshToken().catch():", "color: red;", error)
					// Log dettagliato dell'errore
					if (error.response) {
						debug && console.error("<JwtService>%c API Error:", error.response.status, "color: orange;", error.response.data)
					} else if (error.request) {
						debug && console.error("<JwtService>%c No response from server:", "color: orange;", error.request)
					} else {
						debug && console.error("<JwtService>%c Request setup error:", "color: orange;", error.message)
					}

					resetSessionCookie() // Se fallisce, svuota tutto
					reject(new Error("Failed to login with refreshToken." + (error.response?.data?.message || "")))
				})
		})
	}

	signInWithToken = () => {
		debug &&
			console.log(`%c<JwtService> -signInWithToken().Promise(${jwtServiceConfig.accessToken})`, "color: orange;", {
				data: this.getAuthTokens(),
			})
		return new Promise((resolve, reject) => {
			axios //.get(jwtServiceConfig.accessTokenMock, {
				.post(jwtServiceConfig.accessToken, {
					data: this.getAuthTokens(),
				})
				.then((response) => {
					debug && console.log("<JwtService> -signInWithToken().then(user)", { user: response.data.user })
					if (response.data.user) {
						this.setSessionTokens(response.data)
						resolve(response.data.user)
					} else {
						this.signoff()
						reject(new Error("Failed to login with token."))
					}
				})
				.catch((error) => {
					this.signoff()
					reject(new Error("Failed to login with token. " + error))
				})
		})
	}

	signInWithEmailAndPassword = async (email, password) => {
		const deviceId = await this.getDeviceId()
		debug &&
			console.log(`%c<JwtService> -signInWithEmailAndPassword(${email}) Promise.post(${jwtServiceConfig.signIn})`, "color: orange;", {
				data: {
					email,
					password,
					deviceId,
				},
			})
		const error = []
		return new Promise((resolve, reject) => {
			axios
				.post(jwtServiceConfig.signIn, {
					data: {
						email,
						password,
						deviceId,
					},
				})
				.then((response) => {
					if (response.data?.user) {
						debug && console.log(`<JwtService> -signInWithEmailAndPassword(user).then().resolve(emit -> onLogin)`)
						this.setSessionTokens(response.data)
						resolve(response.data.user)
						this.emit("onLogin", response.data.user)
					} else {
						//debug &&console.log(response.data)
						debug && console.log(`<JwtService> -signInWithEmailAndPassword().then().reject()`, response.data)
						error.push(response.data)
						reject(error)
					}
				})
				.catch((err) => {
					debug && console.log(`<JwtService> -signInWithEmailAndPassword().catch().reject(err)`, err)
					error.push({
						type: "email",
						message: "Something went wrong. Please try later.",
					})
					reject(error)
				})
		})
	}

	signUpWithToken = (accessToken) => {
		debug && console.log(`%c<JwtService> -signUpWithToken(accessToken) Promise( -> ${jwtServiceConfig.signUp})`, "color: orange;")
		return new Promise((resolve, reject) => {
			axios
				.post(jwtServiceConfig.signUp, {
					data: {
						accessToken,
					},
				})
				.then((response) => {
					debug && console.log("<JwtService> -signUpWithToken() -> then()", response.data)
					if (response.data.user) {
						debug && console.log(response.data.user)
						//this.setSessionTokens(response.data)
						resolve(response.data.user)
						//this.emit("onLogin", response.data.user)
					} else {
						reject(response.data.error)
					}
				})
				.catch((err) => {
					debug && console.log("<JwtService> -signUpWithToken() -> catch()", err.message)
					const error = []
					error.push({
						type: "social",
						message: err.message + ".Test signUpWithToken.",
					})
					reject(error)
				})
		})
	}

	createUser = (data) => {
		return new Promise((resolve, reject) => {
			axios.post(jwtServiceConfig.signUp, data).then((response) => {
				if (response.data.user) {
					this.setSessionTokens(response.data)
					resolve(response.data.user)
					this.emit("onLogin", response.data.user)
				} else {
					reject(response.data.error)
				}
			})
		})
	}

	getErrorCode = (code) => {
		//debug && console.log("<JwtService> -getErrorCode() Promise()")
		return new Promise((resolve, reject) => {
			try {
				const bytes = AES.decrypt(code, process.env.REACT_APP_COOKIE_SECRET)
				const decripted = bytes.toString(enc.Utf8)
				resolve(decripted)
			} catch (err) {
				debug && console.log("<JwtService> -getErrorCode() -> catch: ", err.message)
				const error = []
				error.push({
					type: "social",
					message: "Something went wrong. Please try later.",
					//message: err.message, //do not show real error to the user
				})
				reject(error)
			}
		})
	}

	signoff = () => {
		debug && console.log(`<JwtService> -signoff(${getSessionDeviceId()})`)
		return new Promise((resolve, reject) => {
			axios.post(jwtServiceConfig.signOff, { deviceId: getSessionDeviceId() }).then((response) => {
				if (response.data) {
					resetSessionCookie()
					resolve(response.data)
					this.emit("onLogout", "Logged out")
				} else {
					reject(response.data.error)
				}
			})
		})
	}

	signout = () => {
		debug && console.log(`<JwtService> -signout()`)
		let iframe
		try {
			// Crea un iframe invisibile
			iframe = document.createElement("iframe")
			iframe.style.display = "none"
			iframe.src = "https://accounts.google.com/logout"
			document.body.appendChild(iframe)
		} catch (err) {
			debug && console.log(`%c<JwtService> -signout().catch(err)`)
		} finally {
			// Rimuovi l'iframe dopo qualche secondo
			setTimeout(() => {
				document.body.removeChild(iframe)
			}, 3000)
		}
	}

	updateUserData = (user) => {
		debug && console.log("<JwtService> updateUserData(user) Promise()", { user })
		return new Promise((resolve, reject) => {
			axios
				.patch(
					jwtServiceConfig.updateUserData, // updateUserMock
					{ data: user.data },
					{ bind: { id: user.id } }
				)
				.then((response) => {
					debug && console.log("<JwtService> -updateUserData() -> then()", response)
					resolve()
				})
				.catch((err) => {
					debug && console.log("<JwtService> -updateUserData() -> catch()", err)
					reject(new Error(err))
				})
		})
	}

	isAuthTokenValid = (token) => {
		//debug && console.log("<JwtService> -isAuthTokenValid()")
		if (!token) {
			debug && console.error(`<JwtService> -isAuthTokenValid() -token provided is null`)
			return { isValid: false, data: null }
		}
		try {
			const data = jwtDecode(token)

			const currentTime = Date.now() / 1000
			if (data.exp < currentTime) {
				debug && console.log(`<JwtService> -isAuthTokenValid(?) %cNo, expired ${Number.parseFloat(data.exp - currentTime).toFixed(0)}(s) ago`, "color: orange;")
				return { isValid: false, data }
			} else {
				debug && console.log(`<JwtService> -isAuthTokenValid(?) %cYes, will expire in ${Number.parseFloat(data.exp - currentTime).toFixed(0)}(s)`, "color: green;")
			}

			return { isValid: true, data }
		} catch (err) {
			debug && console.log(`%c<JwtService> -isAuthTokenValid().catch(err)`, "color:orange;", err.message)
			return { isValid: false, data: null }
		}
	}

	getRequestToken = () => {
		const query = new URLSearchParams(window.location.search)
		const request_token = query.get("code")
		debug && console.log("<JwtService> -getRequestToken()", { request_token })
		return request_token || null
	}

	getAuthTokens = () => {
		const auth_tokens = this.getLocalStorage()
		debug && console.log("<JwtService> -getAuthTokens()", { auth_tokens }) //, { deviceId, accessToken })
		return auth_tokens || { deviceId: null, accessToken: null, refreshToken: null }
	}

	expireSessionCookie = () => {
		resetSessionCookie()
	}
}

const instance = new JwtService()

export default instance
