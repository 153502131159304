import layout1 from "./layout1/Layout1"
import layout2 from "./layout2/Layout2"
import layout3 from "./layout3/Layout3"
import layout4 from "./layout4/Layout4"
import layout5 from "./layout5/Layout5"

const themeLayouts = {
	layout1,
	layout2,
	layout3,
	layout4,
	layout5,
}

export default themeLayouts
