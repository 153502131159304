const locale = {
	SIGNIN_MESSAGE: "Please sign in with your email and password or Google corporate account",
	EMAIL_OR_SOCIAL: "OR",
	WELCOME: "Welcome",
	SIGNON_MESSAGE: "Sorry...",
	DATE_TIME: "{{value, dateTime}}",
	SIGNON_1: "You have successfully authenticated.",
	SIGNON_2: "However, your account is not active in our system.",
	SIGNON_3: "If this is your first time logging in or you think you are eligible, please contact your technical/sales representative at Crowd Services to be authorized.",
	SIGNON_4: "Your attempt has been recorded and an employee may soon step in to grant you access.",
	SIGNON_5: "Try again in a few minutes.",
	SIGNON_6: "Thank you!",
}

export default locale
