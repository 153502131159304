import _ from "@lodash"
import FuseUtils from "@fuse/utils"
import mockApi from "../mock-api.json"
import mock from "../mock"

const ticketsDB = mockApi.components.examples.tickets.value
const tagsDB = mockApi.components.examples.tickets_tags.value
const debug = process.env.NODE_ENV === "development" && true

mock.onGet("/api/tickets").reply((config) => {
	debug && console.log(ticketsDB)
	return [200, ticketsDB]
})

mock.onPost("/api/tickets").reply(({ data }) => {
	const newTicket = { id: FuseUtils.generateGUID(), ...JSON.parse(data) }

	ticketsDB.push(newTicket)

	return [200, newTicket]
})

mock.onGet(/\/api\/tickets\/(?!tags)[^/]+/).reply((config) => {
	const { id } = config.url.match(/\/api\/tickets\/(?<id>[^/]+)/).groups
	const ticket = _.find(ticketsDB, { id })

	if (ticket) {
		return [200, ticket]
	}

	return [404, "Requested task do not exist."]
})

mock.onPut(/\/api\/tickets\/[^/]+/).reply(({ url, data }) => {
	const { id } = url.match(/\/api\/tickets\/(?<id>[^/]+)/).groups

	debug && console.log("PUT [tickets-api] id:", id)

	const ticket = _.find(ticketsDB, { id })
	debug && console.log("PUT [tickets-api] ticket:", ticket)
	debug && console.log("PUT [tickets-api] data:", JSON.parse(data))

	_.assign(_.find(ticketsDB, { id }), JSON.parse(data))

	debug && console.log("PUT [tickets-api] find:", _.find(ticketsDB, { id }))
	return [200, _.find(ticketsDB, { id })]
})

mock.onDelete(/\/api\/tickets\/[^/]+/).reply((config) => {
	const { id } = config.url.match(/\/api\/tickets\/(?<id>[^/]+)/).groups

	_.remove(ticketsDB, { id })

	return [200, id]
})

mock.onGet("/api/tickets/tags").reply((config) => {
	return [200, tagsDB]
})
