import FuseUtils from "@fuse/utils"
import AppContext from "app/AppContext"
import withRouter from "@fuse/core/withRouter"
import history from "@history"
import { Component } from "react"
import { matchRoutes } from "react-router-dom"
import { getSessionRedirectUrl, setSessionRedirectUrl, resetSessionRedirectUrl } from "@fuse/core/FuseAuthorization/sessionRedirectUrl"
import { ignoredPaths } from "app/configs/ignoredPaths"

const debug = process.env.NODE_ENV === "development" && false

let ConstructorCount = 0,
	getDerivedStateFromPropsCount = 0,
	renderCount = 0,
	componentDidMountCount = 0,
	componentDidUpdateCount = 0,
	shouldComponentUpdateCount = 0,
	redirectRouteCount = 0

class FuseAuthorization extends Component {
	constructor(props, context) {
		const count = ++ConstructorCount
		//debug && console.log(`<FuseAuthorization> Constructor(${count})`, props)
		super(props)
		const { routes } = context

		this.state = {
			accessGranted: true,
			error401: false,
			routes,
		}
	}

	/**
	 * The componentDidMount() method is called after the component is rendered (Mounted).
	 */
	componentDidMount() {
		const count = ++componentDidMountCount
		//debug && console.log(`<FuseAuthorization> componentDidMount(${count}) => redirectRoute ?`, !this.state.accessGranted)
		if (!this.state.accessGranted || this.state.error401) {
			debug && console.log(`<FuseAuthorization> componentDidMount(${count}) will call redirectRoute because accessGranted is`, this.state.accessGranted && !this.state.error401)
			this.redirectRoute()
		}
	}

	/**
	 * The componentDidUpdate method is called after the component is updated in the DOM.
	 */
	componentDidUpdate() {
		const count = ++componentDidUpdateCount
		//debug && console.log(`<FuseAuthorization> componentDidMount(${count}) => redirectRoute ? ${!this.state.accessGranted}`)
		if (!this.state.accessGranted || this.state.error401) {
			debug && console.log(`<FuseAuthorization> componentDidMount(${count}) will call redirectRoute because accessGranted is`, this.state.accessGranted && !this.state.error401)
			this.redirectRoute()
		}
	}

	/**
	 * In the shouldComponentUpdate() method you can return a Boolean value
	 * that specifies whether React should continue with the rendering or not.
	 * The default value is true.
	 * @param {*} nextProps
	 * @param {*} nextState
	 * @returns
	 */
	shouldComponentUpdate(nextProps, nextState) {
		const count = ++shouldComponentUpdateCount
		if (nextState.accessGranted !== this.state.accessGranted || nextState.error401 !== this.state.error401) {
			debug && console.log(`<FuseAuthorization> shouldComponentUpdate(${count}) will fire because accessGranted is changed:`, nextState.accessGranted !== this.state.accessGranted || nextState.error401 !== this.state.error401)
		} else {
			debug && console.log(`<FuseAuthorization> shouldComponentUpdate(${count}) won't fire because accessGranted is not changed:`, nextState.accessGranted !== this.state.accessGranted || nextState.error401 !== this.state.error401)
		}

		return nextState.accessGranted !== this.state.accessGranted || nextState.error401 !== this.state.error401
	}

	/**
	 * getDerivedStateFromProps(props, state) is a static method that is called
	 * just before render() method in both mounting and updating phase
	 * @param {*} props
	 * @param {*} state
	 * @returns
	 */
	static getDerivedStateFromProps(props, state) {
		const count = ++getDerivedStateFromPropsCount
		//debug && console.log(`%c<FuseAuthorization> getDerivedStateFromProps(${count})`, "color:orange;", { routes: state.routes })
		const { location, userRole } = props
		const { pathname } = location
		//debug && console.log(`%c<FuseAuthorization> getDerivedStateFromProps(${count})`, "color:orange;", { pathname }, { state }, { userRole }, { userActive })

		const matchedRoutes = matchRoutes(state.routes, pathname)
		const matched = matchedRoutes[0]
		if (typeof matched.route?.auth === "undefined") {
			debug && console.warn(`<FuseAuthorization> getDerivedStateFromProps(${count}) warning: Did you import component routeConfig file?`, { matchedRouteAuth: matched.route.auth })
		}
		const isIgnored = ignoredPaths.includes(pathname)
		const userHasPermission = FuseUtils.hasPermission(matched.route.auth, userRole)
		const error401 = matched && !userHasPermission && !isIgnored
		debug && console.log(`%c<FuseAuthorization> getDerivedStateFromProps(${count})`, "color:orange;", { pathname }, { userRole }, { matchedRouteAuth: matched.route.auth }, { userHasPermission }, { error401 }, { isIgnored }, { accessGranted: matched ? userHasPermission : true })
		return {
			accessGranted: matched ? userHasPermission : true,
			error401,
		}
	}

	redirectRoute() {
		const count = ++redirectRouteCount
		const { location, userRole, userActive, loginRedirectUrl } = this.props
		const { pathname } = location
		let redirectUrl = getSessionRedirectUrl() || loginRedirectUrl
		debug && console.log(`<FuseAuthorization> redirectRoute(${count})`, { pathname }, { userRole }, { redirectUrl })

		if (!userRole || userRole.length === 0) {
			/* User is guest. 
			=> Redirect to Login Page */
			setSessionRedirectUrl(pathname)
			debug && console.log("<FuseAuthorization> redirectRoute(guest) => /sign-in then...", pathname)
			setTimeout(() => history.push("/sign-in"), 0)
		} else if (userRole && userRole === "prospect" && !userActive) {
			/* User is prospect. 
			=> Redirect to Sign-up Page */
			debug && console.log("<FuseAuthorization> redirectRoute(prospect not active) => /sign-on")
			setTimeout(() => history.push("/sign-on"), 0)
		} else if (userRole && userRole === "prospect" && userActive) {
			/* User is prospect and active. 
			=> Redirect to Sign-up Page */
			debug && console.log("<FuseAuthorization> redirectRoute(prospect active) => /sign-up")
			setTimeout(() => history.push("/sign-up"), 0)
		} else {
			/* User is member. 
			=> Redirect to dashboard or loginRedirectUrl */
			if (this.state.error401) {
				debug && console.log("<FuseAuthorization> redirectRoute(member) => 401")
				setTimeout(() => history.push("/error/401"), 0)
			} else {
				debug && console.log("<FuseAuthorization> redirectRoute(member) => ", redirectUrl)
				setTimeout(() => history.push(redirectUrl), 0)
			}
			resetSessionRedirectUrl()
		}
	}

	render() {
		const count = ++renderCount
		debug && console.log(`<FuseAuthorization> render(${count}) ?`, this.state.accessGranted)
		return this.state.accessGranted ? this.props.children : null
	}
}

FuseAuthorization.contextType = AppContext

export default withRouter(FuseAuthorization)
