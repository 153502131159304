import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { showMessage } from "app/store/fuse/messageSlice"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { styled } from "@mui/material/styles"
import axios from "axios"
import _ from "@lodash"
import CircularProgressWithLabel from "@fuse/core/CircularProgressWithLabel"

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})

// Allowed file types
const allowedFileTypes = ["image/jpeg", "image/png", "application/pdf", "application/zip"]
const allowedFileSize = 100 * 1024 * 1024 // Limit file size to  first number in MB

const FileUpload = () => {
	const [files, setFiles] = useState([])
	const [uploadProgress, setUploadProgress] = useState({})
	//const [isValid, setIsValid] = useState(false)
	const dispatch = useDispatch()

	const handleFileChange = (event) => {
		Array.from(event.target.files).forEach((file, index) => {
			if (!allowedFileTypes.includes(file.type)) {
				return dispatch(showMessage({ message: file.type + " not allowd" }))
			}
		})
		//FIXME
		setFiles(event.target.files)
	}

	const handleUpload = () => {
		const uploadPromises = []
		const newUploadProgress = {}

		Array.from(files).forEach((file, index) => {
			const formData = new FormData()
			formData.append("file", file)

			const uploadPromise = axios.post("/api/v1/driveupload", formData, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					console.log(percentCompleted)
					newUploadProgress[file.name] = percentCompleted
					setUploadProgress({ ...newUploadProgress })
				},
			})

			uploadPromises.push(uploadPromise)
		})

		axios
			.all(uploadPromises)
			.then(() => {
				console.log("All files uploaded successfully")
			})
			.catch((err) => {
				setUploadProgress({})
				//console.error("Error uploading files:", err.response.data.message)
				dispatch(showMessage({ message: err.response.data.message }))
			})
	}
	//disabled={_.isEmpty(files)}
	return (
		<div>
			{/*<input type="file" multiple onChange={handleFileChange} />*/}
			<Button color="secondary" component="label" role={undefined} onChange={handleFileChange} tabIndex={-1} startIcon={<CloudUploadIcon />}>
				Select files
				<VisuallyHiddenInput type="file" multiple />
			</Button>

			<Button variant="contained" color="secondary" onClick={handleUpload}>
				Upload
			</Button>

			{Array.from(files).map((file, index) => (
				<div key={index}>
					<span>
						{file.name} ({file.size / 1000})
					</span>
					{<CircularProgressWithLabel value={uploadProgress[file.name] || 0} />}
					{uploadProgress[file.name] || 0} %{/*<progress value={uploadProgress[file.name] || 0} max="100" />*/}
				</div>
			))}
		</div>
	)
}

export default FileUpload
