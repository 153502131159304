import _ from "@lodash"

const TicketModel = (data) =>
	_.defaults(data || {}, {
		avatar: null,
		background: null,
		name: "",
		emails: [{ email: "", label: "" }],
		phoneNumbers: [{ country: "us", phoneNumber: "", label: "" }],
		title: "",
		company: "",
		birthday: null,
		partner: "",
		address: "",
		notes: "",
		tags: [],
		age: "",
	})

export default TicketModel
