import i18next from "i18next"
import { lazy } from "react"
import authRoles from "src/app/auth/authRoles"
import { en, it } from "src/app/configs/i18n/dmsApp"
import DocumentView from "./document/DocumentView"
import DocumentForm from "./document/DocumentForm"
import DocumentViewFull from "./document/DocumentViewFull"
import DocumentFormFull from "./document/DocumentFormFull"

const DmsApp = lazy(() => import("./DmsApp"))

i18next.addResourceBundle("en", "dmsApp", en)
i18next.addResourceBundle("it", "dmsApp", it)

const dmsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.admin,
	routes: [
		{
			path: "apps/dms",
			element: <DmsApp />,
			children: [
				{
					path: ":id",
					element: <DocumentView />,
				},
				{
					path: ":id/edit",
					element: <DocumentForm />,
				},
			],
		},
	],
}

const dmsAppIcons = {
	home: "heroicons-outline:home",
	documentView: "heroicons-outline:document",
	deleteTab: "heroicons-outline:x-circle",
}

export default dmsAppConfig
