const sessionStorageKey = "portalRedirectUrl"
const debug = process.env.NODE_ENV === "development" && false

export const getSessionRedirectUrl = () => {
	debug && console.log("%csessionRedirectUrl -getSessionRedirectUrl:", "color:grey;", window.sessionStorage.getItem(sessionStorageKey))
	return window.sessionStorage.getItem(sessionStorageKey)
}

export const setSessionRedirectUrl = (url) => {
	window.sessionStorage.setItem(sessionStorageKey, url)
	debug && console.log("%csessionRedirectUrl -setSessionRedirectUrl:", "color:grey;", window.sessionStorage.getItem(sessionStorageKey))
}

export const resetSessionRedirectUrl = () => {
	window.sessionStorage.removeItem(sessionStorageKey)
	debug && console.log("%csessionRedirectUrl -resetSessionRedirectUrl:", "color:grey;", window.sessionStorage.getItem(sessionStorageKey))
}
