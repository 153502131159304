import * as React from "react"
import FuseSplashScreen from "@fuse/core/FuseSplashScreen"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { showMessage } from "app/store/fuse/messageSlice"
import { logoutUser, setUser } from "app/store/userSlice"
import { getNavigation } from "app/store/fuse/navigationSlice"
import jwtService from "./services/jwtService"
const debug = false

const AuthContext = React.createContext()

function AuthProvider({ children }) {
	debug && console.log("<AuthProvider> Constructor()")
	const [isAuthenticated, setIsAuthenticated] = useState(undefined)
	const [waitAuthCheck, setWaitAuthCheck] = useState(true)
	const dispatch = useDispatch()

	useEffect(() => {
		debug && console.log("<AuthProvider> useEffect()")

		jwtService.on("onAutoLogin", () => {
			//dispatch(showMessage({ message: "Signing in with JWT" }))

			/**
			 * Sign in and retrieve user data with stored token */
			jwtService
				.signInWithToken()
				.then((user) => {
					success(user, "Signed in with JWT")
				})
				.catch((error) => {
					pass(error.message)
				})
		})

		jwtService.on("onLogin", (user) => {
			success(user, "Signed in")
		})

		jwtService.on("onLogout", () => {
			pass("Signed out")

			dispatch(logoutUser())
		})

		jwtService.on("onAutoLogout", (message) => {
			pass(message)

			dispatch(logoutUser())
		})

		jwtService.on("onNoAccessToken", () => {
			dispatch(showMessage({ message: "Not logged in" }))

			pass()
		})

		jwtService.on("onNoRefreshToken", () => {
			dispatch(showMessage({ message: "Not signed in" }))

			pass()
		})

		jwtService.init()

		function success(user, message) {
			debug && console.log("<AuthProvider> success(): ", message)
			//console.warn("success", user, message)
			/*if (message) {
				dispatch(showMessage({ message }))s
			}*/
			dispatch(getNavigation())
			Promise.all([
				//dispatch(getNavigation()), // added *FG*
				dispatch(setUser(user)),
				// You can receive data in here before app initialization
			]).then((values) => {
				setWaitAuthCheck(false)
				setIsAuthenticated(true)
			})
		}

		function pass(message) {
			if (message) {
				dispatch(showMessage({ message }))
			}

			//dispatch(getNavigation()) // added *FG*
			setWaitAuthCheck(false)
			setIsAuthenticated(false)
		}
	}, [dispatch])

	return waitAuthCheck ? <FuseSplashScreen /> : <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider")
	}
	return context
}

export { AuthProvider, useAuth }
