import i18n from 'i18next'
import format from './i18n-format'
import { initReactI18next } from 'react-i18next'
//import Backend from 'i18next-http-backend'
//import LanguageDetector from 'i18next-browser-languagedetector'

function getBrowserLocales(options = {}) {
	//console.dir(process.env)
	const defaultOptions = {
		languageCodeOnly: true,
	}
	const opt = {
		...defaultOptions,
		...options,
	}
	const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages
	if (!browserLocales) {
		return 'en'
	}
	return browserLocales.map((locale) => {
		const trimmedLocale = locale.trim()
		return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale
	})
}

//console.log(getBrowserLocales())

const LanguageDetector = {
	type: 'languageDetector',
	detect: () => {
		return getBrowserLocales()
	},
	init: () => {},
	cacheUserLanguage: () => {},
}

const resources = {
	en: {
		translation: {
			'Welcome to React': 'Welcome to React and react-i18next',
		},
	},
	it: {
		translation: {
			'Benvenuti in React': 'Welcome to React and react-i18next',
		},
	},
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	//.use(Backend)
	.use(LanguageDetector)
	.init({
		detection: { order: ['localStorage', 'path', 'navigator'] },
		supportedLngs: ['en', 'it', 'tr'],
		load: 'languageOnly',
		fallbackLng: (code) => {
			return code.substring(0, 2)
		},
		//debug: true,
		resources,
		//	lng: 'en', // FG240822 rem over subt by default language property in i18n LanguageDetector

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
			format, // formstting based o language and argument
		},
	})

export default i18n
