import axios from "axios"

// Add a config interceptor for all axios instances
axios.interceptors.request.use(
	(config) => {
		//console.log("AXIOS: ", config.method, config)
		//config.headers["x-idempotency-key"] = `thisisatest`

		if (config.bind) {
			let url = config.url
			const path = config.url.split("/")
			path.forEach((token) => {
				if (token.startsWith(":")) url = url.replace(token, config.bind[token.replace(":", "")])
			})
			config.url = url
			//console.log("AXIOS: -url:", config.url)
			delete config.bind
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

export default axios
