import i18next from "i18next"
import { lazy } from "react"
import it from "./i18n/it"
import en from "./i18n/en"
import authRoles from "../../../auth/authRoles"
const ProjectDashboardApp = lazy(() => import("./ProjectDashboardApp"))

i18next.addResourceBundle("en", "projectDashboardApp", en)
i18next.addResourceBundle("it", "projectDashboardApp", it)

const ProjectDashboardAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	auth: authRoles.admin, // ['admin','staff']
	routes: [
		{
			path: "dashboards/project",
			element: <ProjectDashboardApp />,
		},
	],
}

export default ProjectDashboardAppConfig
