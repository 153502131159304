/**
 * Custom useSelector tha does not cause re-render if selector changes.
 * @author	Guglielmo Farina
 */
import { useStore } from "react-redux"
import { useRef, useEffect } from "react"

function useSilentSelector(selector) {
	const store = useStore() // access the Redux store
	const resultRef = useRef() // use useRef to hold the result
	// get the initial value for the selector and assign it to the ref
	resultRef.current = selector(store.getState())

	useEffect(() => {
		// function to check the latest state and update the ref
		const checkForUpdates = () => {
			const newResult = selector(store.getState())
			if (newResult !== resultRef.current) {
				resultRef.current = newResult
			}
		}

		// subscribe to store and keep updating the ref without causing a re-render
		const unsubscribe = store.subscribe(checkForUpdates)

		// cleanup subscriptio on component unmount
		return unsubscribe
	}, [store, selector]) // dependencies are store and selector, should be stable

	// return the current value inside the ref (does not cause re-renders)
	return resultRef.current
}

export default useSilentSelector
