import { lazy } from 'react'
import ItemForm from './item/ItemForm'

const ItemsApp = lazy(() => import('./ItemsApp'))

const ItemsAppConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'apps/items',
			element: <ItemsApp />,
			children: [
				{
					path: ':id',
					element: <ItemForm />,
				},
				{
					path: ':id/:type',
					element: <ItemForm />,
				},
			],
		},
	],
}

export default ItemsAppConfig
