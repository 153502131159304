import Button from "@mui/material/Button"
import NavLinkAdapter from "@fuse/core/NavLinkAdapter"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import FuseLoading from "@fuse/core/FuseLoading"
import Typography from "@mui/material/Typography"
import Chip from "@mui/material/Chip"
import Divider from "@mui/material/Divider"
import FuseSvgIcon from "@fuse/core/FuseSvgIcon"
import Box from "@mui/system/Box"
import format from "date-fns/format"
import { getDocument, selectDocument } from "../store/documentSlice" //selectDocumentById,
import { selectTags } from "../store/tagsSlice"
import { selectCategories } from "../store/categoriesSlice"
import { quickedit } from "../DmsAppButtons"

const debug = process.env.NODE_ENV === "development" && false

const DocumentView = () => {
	debug && console.log("<DocumentView>".padEnd(21) + "%cConstructor()", "color: yellow")
	const document = useSelector(selectDocument)
	const tags = useSelector(selectTags)
	const categories = useSelector(selectCategories)
	const routeParams = useParams()
	//const { routeParams, documentId } = useParams() // Assuming you get documentId from URL params
	const dispatch = useDispatch()

	// Check if the document is already in the store
	//const document = useSelector((state) => selectDocumentById(state, documentId))

	useEffect(() => {
		if (!document) {
			// Dispatch getDocument action if the document is not found in the store
			dispatch(getDocument(routeParams.id))
		}
	}, [dispatch, document, routeParams])

	/*useEffect(() => {
		dispatch(getDocument(routeParams.id))
	}, [dispatch, routeParams])*/

	if (!document) {
		return <FuseLoading />
	}

	// Find the category name from categories based on the document's CategoryId
	//const categoryName = categories.find((category) => category.id === document.CategoryId)?.name || ""
	//const categoryName = _.find(categories, { id: document.CategoryId })?.name || ""

	return (
		<>
			<Box
				className="relative w-full h-64 px-32 sm:px-48 flex items-center justify-between"
				sx={{
					backgroundColor: "background.default",
				}}>
				<Chip color="primary" label={_.find(categories, { id: document.CategoryId }).name || ""} />
			</Box>

			<div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0">
				<div className="w-full max-w-3xl">
					<div className="flex flex-auto items-end -mt-64">
						{/*<Chip color="primary" label={_.find(categories, { id: document.CategoryId }).name || ""} />*/}
						<div className="flex items-center ml-auto mr-24 mt-12 mb-4 space-x-12">
							<Button variant="contained" color="secondary" component={NavLinkAdapter} relative="path" to={quickedit()}>
								<FuseSvgIcon size={20}>heroicons-outline:pencil-alt</FuseSvgIcon>
								<span className="mx-8">Edit</span>
							</Button>
						</div>
					</div>

					<Typography className="mt-24 text-24 md:text-24 font-bold truncate">{document.name}</Typography>

					<div className="flex flex-wrap items-center mt-8">
						{document.tags.map((id) => (
							<Chip key={id} label={_.find(tags, { id }).name} className="mr-12 mb-12" size="small" />
						))}
					</div>

					<Divider className="mt-16 mb-24" />

					<div className="flex flex-col space-y-32">
						{document.title && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:briefcase</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.title}</div>
							</div>
						)}

						{document.account && document.account.company && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:office-building</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.account.company}</div>
							</div>
						)}

						{document.account && document.account.userName && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.account.userName}</div>
							</div>
						)}

						<div className="flex items-center">
							<FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
							<div className="ml-24 leading-6">{document.account && document.account.email ? document.account.email : ""}</div>
						</div>

						{document.classification && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.classification.name}</div>
							</div>
						)}

						{document.expiryDate && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
								<div className="ml-24 leading-6">{format(new Date(document.expiryDate), "MMMM d, y")}</div>
							</div>
						)}

						{document.description && (
							<div className="flex">
								<FuseSvgIcon>heroicons-outline:menu-alt-2</FuseSvgIcon>
								<div className="max-w-none ml-24 prose dark:prose-invert" dangerouslySetInnerHTML={{ __html: document.description }} />
							</div>
						)}

						{document.filePath && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.filePath}</div>
							</div>
						)}

						{document.direction && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.direction.name}</div>
							</div>
						)}

						{document.frequency && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.frequency.name}</div>
							</div>
						)}

						{document.mandatory && (
							<div className="flex items-center">
								<FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
								<div className="ml-24 leading-6">{document.mandatory.name}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default DocumentView
